import React, { useEffect, useState, useRef } from "react";

import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import { Document, Page, pdfjs } from "react-pdf";
import {
  catalogueData,
  checkListData,
  notesData,
  notesError,
  notesLoading,
} from "../../redux/selectors";
import {
  checkListAction,
  getCatalogue,
  getNotes,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import Input from "../../components/Input/InputComponent";
import * as Yup from "yup";
import FullLoader from "../../components/Loader/FullLoader";
import { setKey, slugToText } from "../../helpers";
import { Field, Form, Formik } from "formik";
import { Modal } from "react-responsive-modal";
import { useHistory, useParams, Link } from "react-router-dom";
import { saveAs } from "file-saver";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BsZoomOut, BsZoomIn } from "react-icons/bs";
import { RiFullscreenFill } from "react-icons/ri";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import { createErrorAlert } from "../../redux/actions";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import { getApiKey } from "../../helpers";
import { Helmet } from "react-helmet";
import $ from "jquery";
import apiRequest from "../../helpers/apiRequest";
import { postMailChimpAction } from "../../redux/actions/postMailChimpAction";
import fetchEssays from "../../helpers/fetchEssays";
import convertTextToSlug from "../../helpers/textToSlug";
import Loader from "../../components/PDFLoader/Loader";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { IoMdArrowRoundForward, IoMdArrowRoundBack } from "react-icons/io";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PaidUsers = (props) => {
  const {
    getNotes,
    loading,
    error,
    relevant,
    data,
    type,
    slugModule,
    getCheckList,
    checkListData,
    postMailChimp,
    userSignUp,
    userLogin,
    dataCatalogue,
    getCatalogue,
  } = props;
  const dispatch = useDispatch();
  const [document, setDocument] = useState(false);
  const [firstTimeCase, setFirstTimeCase] = useState("");
  const [firstTimeTitle, setFirstTimeTitle] = useState("");
  const [ogDescription, setOgDescription] = useState("");
  const [audioState, setAudioState] = useState("");
  const [ogUrl, setOgUrl] = useState("");
  const [description, setDescription] = useState("");
  const [canonical, setCanonical] = useState("");
  const [keywords, setKeywords] = useState("");
  const [robots, setRobots] = useState(false);
  const [pdf_file, setPDFFile] = useState();
  const [emailError, setEmailError] = useState(false);
  const [emailCheckList, setEmailCheckList] = useState("");
  const [contents, setContent] = useState("");
  const [checkListDisplay, setCheckListDisplay] = useState(false);
  const [studyModuleState, setStudyModuleState] = useState(
    props?.location?.state?.name
      ? props?.location?.state?.name
      : props?.studymodule
      ? slugToText(props?.studymodule)
      : ""
  );

  const [state, setState] = useState({
    pageNumber: 1,
    pagePreviews: [],
    pageScale:
      window.innerWidth >= 992 && window.innerWidth <= 1200
        ? 0.7
        : window.innerWidth >= 768 && window.innerWidth <= 991
        ? 0.4
        : window.innerWidth >= 400 && window.innerWidth <= 767
        ? 0.2
        : 1.5,
    loadingPage: true,
    totalPages: 0,
    isFullScreen: false,
  });

  const [openModule, setOpenModule] = useState(null);
  const [pdfs, setPdfs] = useState("");
  const [loginModal, setLoginModal] = useState(false);
  const [signupModal, setSignupModal] = useState(false);
  const [loadingState, setLoadingState] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [checkBox, setCheckBox] = useState(false);
  const [loader, setLoader] = useState(false);
  const [paidModules, setPaidModules] = useState([]);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });

  const paidData = data;
  const viewerRef = useRef(null);

  const history = useHistory();

  useEffect(() => {
    getCheckList();
    if (window.location.pathname.includes("model-essays")) {
      setDocument(true);
    } else if (
      window.location.pathname.includes("/notes/") &&
      data?.content === undefined
    ) {
      setDocument(false);
    }
  }, []);

  useEffect(() => {
    getCatalogue(null);
  }, []);

  useEffect(() => {
    fetchPagePreviews();
  }, [state.totalPages]);

  useEffect(() => {
    for (let i = 0; i < dataCatalogue?.data?.length; i++) {
      if (dataCatalogue?.data[i]?.is_paid === true) {
        if (!paidModules.includes(dataCatalogue?.data[i]?.study_module?.slug)) {
          paidModules.push(dataCatalogue?.data[i]?.study_module?.slug);
        }
      }
    }
  }, [dataCatalogue]);

  let user = JSON.parse(localStorage.getItem("app_user"));

  const handleUserLogin = async (body) => {
    setLoader(true);
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    setLoader(true);
    if (checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };

  const handleZoom = (zoomType) => {
    const { pageScale } = state;
    const newScale =
      zoomType === "in"
        ? Math.min(pageScale + 0.2, 3)
        : Math.max(pageScale - 0.2, 0.3);
    setState((prevData) => ({
      ...prevData,
      pageScale: newScale,
    }));
  };

  const handleThumbnailClick = (pageIndex) => {
    setState((prevData) => ({
      ...prevData,
      pageNumber: pageIndex + 1,
    }));
  };

  const fetchPagePreviews = async () => {
    try {
      setState((prevData) => ({
        ...prevData,
        loadingPage: true,
      }));
      const { totalPages } = state;
      if (totalPages > 0) {
        const previews = [];
        for (let i = 1; i <= totalPages; i++) {
          const page = await pdfjs
            .getDocument(
              paidData?.item?.document
                ? paidData?.item?.document
                : paidData?.document
                ? paidData?.document
                : paidData?.value?.document
            )
            .promise.then((doc) => doc.getPage(i));
          const scale = 0.5;
          const viewport = page.getViewport({ scale });
          const canvas = window.document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.height = viewport.height;
          canvas.width = viewport.width;
          const renderContext = {
            canvasContext: context,
            viewport: viewport,
            renderTextLayer: false,
          };
          const renderTask = page.render(renderContext);
          await renderTask.promise;
          previews.push(canvas.toDataURL("image/png"));
          page.cleanup();
        }
        setState((prevData) => ({
          ...prevData,
          pagePreviews: previews,
        }));
      }
    } catch (error) {
      console.error("Error fetching page previews:", error);
    } finally {
      setState((prevData) => ({
        ...prevData,
        loadingPage: false,
      }));
    }
  };

  const handleFullScreen = () => {
    if (!window.document.fullscreenElement) {
      viewerRef.current.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    } else {
      if (window.document.exitFullscreen) {
        window.document.exitFullscreen();
      }
    }
    setState((prev) => ({
      ...prev,
      isFullScreen: !prev.isFullScreen,
    }));
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setState((prev) => ({
      ...prev,
      totalPages: numPages,
    }));
  };

  const checked = (e) => {
    if (e === true) {
      setCheckBox(true);
    } else {
      setCheckBox(false);
    }
  };

  const handleSuccess = () => {
    dispatch(createErrorAlert(400, "", "You have successfully signed up", 400));
  };

  const handleDownloadCheckList = () => {
    let conditionSatisfied = false;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (emailCheckList.length === 0) {
      setEmailError(true);
      return;
    }
    if (emailRegex.test(emailCheckList)) {
      for (let i = 0; i < checkListData.length; i++) {
        if (
          checkListData[i].title
            .toLowerCase()
            .includes(studyModuleState.toLowerCase())
        ) {
          if (!conditionSatisfied) {
            conditionSatisfied = true;
            window.open(checkListData[i].pdf, "_blank");
            saveAs(
              checkListData[i].pdf,
              `${studyModuleState.toLowerCase().replace(" ", "-")}.pdf`
            );
          }
        }
      }
      postMailChimp(emailCheckList, studyModuleState).then((res) => {
        dispatch(createErrorAlert(200, "", res.data.message, 200));
      });
      setEmailError(false);
      setEmailCheckList("");
      if (moduleCheck() === "secondCheck") {
        handleSuccess();
      }
    } else {
      setEmailError(true);
    }
  };

  $("#worker").bind("contextmenu", function (e) {
    return false;
  });

  useEffect(() => {
    getNotes(null, null, null);
    if (
      user?.subscription_name !== "Basic Subscription" &&
      getApiKey() &&
      !window.location.pathname.includes("summary")
    ) {
      setDocument(false);
    }
    if (
      user?.subscription_name === "Basic Subscription" &&
      user.user_type === "TEACHER"
    ) {
      setDocument(true);
    }
    if (data?.content !== undefined) {
      setLoadingState(false);
    }
  }, []);

  const forgetPass = () => {
    setLoginModal(false);
    history.push("/forget-password");
  };

  const studyModuleData = async () => {
    await apiRequest({
      method: "get",
      url: "studies/studymodules",
    }).then((res) => {
      siteSearch(res?.data);
    });
  };
  useEffect(() => {
    let isModuleInList;
    if (!getApiKey()) {
      if (Array.isArray(checkListData)) {
        isModuleInList = checkListData?.some((item) =>
          item.title.toLowerCase().includes(studyModuleState.toLowerCase())
        );
      }
      if (isModuleInList) {
        setCheckListDisplay(isModuleInList);
      } else if (
        studyModuleState === "Principles of Operations Management" ||
        studyModuleState === "Principles Of Operations Management" ||
        studyModuleState === "Organisational Behaviour" ||
        studyModuleState === "Business Economics" ||
        studyModuleState === "Business Tax" ||
        studyModuleState === "Internation financial accounting" ||
        studyModuleState === "Applied Management Accounting" ||
        studyModuleState === "International Financial Reporting" ||
        studyModuleState === "Financial Management" ||
        studyModuleState === "Macroeconomics" ||
        studyModuleState === "Marketing" ||
        studyModuleState === "Business"
      ) {
        setCheckListDisplay(true);
      } else {
        setCheckListDisplay(false);
      }
    }
  }, [checkListData, studyModuleState]);

  const siteSearch = async (studyModuleRes) => {
    let finalSlug = window.location.pathname.split("/")[3];
    if (window.location.pathname.includes("model-essays")) {
      fetchEssays(
        finalSlug,
        studyModuleRes,
        setLoadingState,
        setDocument,
        setStudyModuleState,
        setOpenModule
      ).then(async (res) => {
        if (
          !getApiKey() ||
          ((user?.subscription_name === "Basic Subscription" ||
            user?.subscription_name.includes("STARTER")) &&
            user?.free_trial === false &&
            user?.user_type === "STUDENT" &&
            !paidModules.includes(slugModule || props?.slugModule))
        ) {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "To view Model Essays please Login. No SimpleStudy account? Sign Up",
              211
            )
          );
          history.push("/");
        } else {
          if (res.data[0].author !== 1) {
            if (paidModules.includes(slugModule)) {
              let pdf = res?.data[0]?.document
                ? res?.data[0]?.document
                : res?.document;
              let content = res?.data[0]?.description
                ? res?.data[0]?.description
                : res?.description;
              let firstTimeCases = res?.data[0]?.description
                ? res?.data[0]?.description
                : res?.description;
              let firstTimeTitle = res?.data[0]?.title
                ? res?.data[0]?.title
                : res?.title;
              let description = res?.data[0]?.seo_fields?.seo_description
                ? res?.data[0]?.seo_fields?.seo_description
                : res?.seo_fields?.seo_description;
              let ogDescription = res?.data[0]?.seo_fields?.og_description
                ? res?.data[0]?.seo_fields?.og_description
                : res?.seo_fields?.og_description;
              let ogUrl = res?.data[0]?.seo_fields?.og_url
                ? res?.data[0]?.seo_fields?.og_url
                : res?.seo_fields?.og_url;
              let canonical = res?.data[0]?.seo_fields?.seo_canonical_data
                ? res?.data[0]?.seo_fields?.seo_canonical_data
                : res?.seo_fields?.seo_canonical_data;
              let keyword = res?.data[0]?.seo_fields?.keywords
                ? res?.data[0]?.seo_fields?.keywords
                : res?.seo_fields?.keywords;
              let robots =
                res?.data[0]?.seo_fields?.robots === true
                  ? "index, follow"
                  : "noindex, nofollow";
              setFirstTimeCase(firstTimeCases);
              setFirstTimeTitle(firstTimeTitle);
              setDescription(description);
              setDescription(description);
              setCanonical(canonical);
              setOgUrl(ogUrl);
              setOgDescription(ogDescription);
              setKeywords(keyword);
              setRobots(robots);
              setPDFFile(
                res?.data[0]?.description
                  ? res?.data[0]?.description
                  : res?.description
              );
              setPdfs(
                res?.data[0]?.document ? res?.data[0]?.document : res?.document
              );
              setContent(content);
              if (
                !res?.data[0]?.document?.includes(".pdf") ||
                !res?.document?.includes(".pdf")
              ) {
                setAudioState(
                  res?.data[0]?.document
                    ? res?.data[0]?.document
                    : res?.document
                );
              }
            } else {
              dispatch(
                createErrorAlert(
                  211,
                  "Info",
                  "You have to buy this module to access this Essay",
                  211
                )
              );
              setTimeout(() => {
                history.push("/");
              }, 4000);
            }
          }
        }
      });
    }
  };

  useEffect(() => {}, [audioState, data?.pdf_file]);

  useEffect(() => {
    if (data?.content === undefined) {
      studyModuleData();
    }
  }, []);

  let blur = window.document.querySelectorAll('[id="blur"]');
  let summary = window.document.querySelectorAll('[id="summary"]');
  if (!getApiKey()) {
    for (let i = 0; i < blur.length; i++) {
      if (blur.length === 5) {
        if (i === 2) {
          blur[i].style.display = "none";
        } else {
          blur[i].style.display = "block";
        }
      } else if (blur.length === 4) {
        if (i === 1) {
          blur[i].style.display = "none";
        }
      }
    }
  } else if (getApiKey()) {
    for (let j = 0; j < summary.length; j++) {
      if (summary.length === 3) {
        if (j === 1) {
          summary[j].style.display = "none";
        }
      }
    }
  }

  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  const moduleCheck = () => {
    if (
      studyModuleState === "International Criminal Law" ||
      studyModuleState === "Media Law" ||
      studyModuleState === "Aviation Law" ||
      studyModuleState === "Criminology" ||
      studyModuleState === "Money Laundering" ||
      studyModuleState === "International Banking Law"
    ) {
      return "firstCheck";
    } else if (
      studyModuleState === "Principles Of Operations Management" ||
      studyModuleState === "Principles of Operations Management" ||
      studyModuleState === "Organisational Behaviour" ||
      studyModuleState === "Business Economics" ||
      studyModuleState === "Business Tax" ||
      studyModuleState === "Internation financial accounting" ||
      studyModuleState === "Applied Management Accounting" ||
      studyModuleState === "International Financial Reporting" ||
      studyModuleState === "Financial Management" ||
      studyModuleState === "Macroeconomics" ||
      studyModuleState === "Marketing" ||
      studyModuleState === "Business"
    ) {
      return "secondCheck";
    }
  };

  const handleRightPage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        pageNumber: prevState.pageNumber + 1,
      };
    });
  };

  const handleLeftPage = () => {
    setState((prevState) => {
      return {
        ...prevState,
        pageNumber: prevState.pageNumber - 1,
      };
    });
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (window.document.fullscreenElement === viewerRef.current) {
        if (event.key === "ArrowLeft") {
          event.preventDefault();
          setState((prevState) => {
            return {
              ...prevState,
              pageNumber: prevState.pageNumber - 1,
            };
          });
        } else if (event.key === "ArrowRight") {
          event.preventDefault();
          setState((prevState) => {
            return {
              ...prevState,
              pageNumber: prevState.pageNumber + 1,
            };
          });
        }
      }
    };

    window.document.addEventListener("keydown", handleKeyPress);

    return () => {
      window.document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const { totalPages, pageNumber, pagePreviews, pageScale, loadingPage } =
    state;

  return (
    <div>
      {loadingState ? <FullLoader /> : null}
      <div className="bg-white bg-primary-bottom-bg bg-no-repeat bg-bottom">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            {data?.value
              ? data?.value?.seo_fields?.og_title
              : data?.seo_fields?.og_title
              ? data?.seo_fields?.og_title
              : firstTimeTitle}
          </title>
          <meta
            name="description"
            content={
              data?.value
                ? data?.value?.seo_fields?.seo_description
                : data?.seo_fields?.seo_description
                ? data?.seo_fields?.seo_description
                : description
            }
          />
          {data?.value && data?.value?.seo_fields?.seo_canonical_data ? (
            <link
              rel="canonical"
              href={
                data?.value
                  ? data?.value?.seo_fields?.seo_canonical_data
                  : data?.seo_fields?.seo_canonical_data
                  ? data?.seo_fields?.seo_canonical_data
                  : canonical
              }
            />
          ) : null}
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta
            name="title"
            content={
              data?.value
                ? data?.value?.seo_fields?.og_title
                : data?.seo_fields?.og_title
                ? data?.seo_fields?.og_title
                : firstTimeTitle
            }
          />
          <meta
            name="keywords"
            content={
              data?.value
                ? data?.value?.seo_fields?.keywords
                : data?.seo_fields?.keywords
                ? data?.seo_fields?.keywords
                : keywords
            }
          />
          <meta
            property="og:title"
            content={
              data?.value
                ? data?.value?.seo_fields?.og_title
                : data?.seo_fields?.og_title
                ? data?.seo_fields?.og_title
                : firstTimeTitle
            }
          />
          <meta
            property="og:description"
            content={
              data?.value
                ? data?.value?.seo_fields?.og_description
                : data?.seo_fields?.og_description
                ? data?.seo_fields?.og_description
                : ogDescription
            }
          />
          <meta
            property="og:url"
            content={
              data?.value
                ? data?.value?.seo_fields?.og_url
                : data?.seo_fields?.og_url
                ? data?.seo_fields?.og_url
                : ogUrl
            }
          />
        </Helmet>
        {/* <Container> */}
        <div className="flex md:flex-row  flex-col gap-6 bg-white px-2">
          {checkListDisplay === true && (
            <div
              className="flex w-full md:w-1/4 w-full justify-center pt-16 flex-wrap "
              style={{ userSelect: "none" }}
            >
              <div className="w-full  md:block   checkListNowSec">
                <div className="w-full checkListNowSubSec">
                  <h2 className="mb-3.5 text-center">
                    <span>
                      {moduleCheck() === "firstCheck" ? (
                        <strong>
                          Aiming for a First Class?
                          <br />
                          <br /> We have created a well-tested method to help
                          students maximise their chances of getting high
                          grades. Our study technique guide contains important
                          information about how to prepare for your assessments.
                        </strong>
                      ) : moduleCheck() === "secondCheck" ? (
                        <strong>
                          Do you want to improve your grades?
                          <br />
                          <br /> Our study materials are designed specifically
                          for maximising your chances of getting a First Class.
                          Our materials are  created by top graduates from UK
                          universities.{" "}
                        </strong>
                      ) : (
                        <strong>
                          Want to ensure you get First Class or at least 2.1
                          honours in {studyModuleState}
                        </strong>
                      )}
                    </span>
                  </h2>
                  <p className="mb-3.5 text-center">
                    {moduleCheck() === "firstCheck" ||
                    moduleCheck() === "secondCheck" ? (
                      ""
                    ) : (
                      <span>
                        We’ve created a FREE checklist for you to maximise your
                        chances of getting high grades!
                      </span>
                    )}
                  </p>
                </div>

                <form>
                  <div class="relative z-0 w-full mb-6 group">
                    <input
                      type="email"
                      name="emailTwo"
                      style={
                        emailError === true
                          ? { border: "1px solid red" }
                          : { border: "1px solid green" }
                      }
                      class="emailTwo block py-2.5 px-0 w-full text-sm "
                      placeholder="Enter your email here "
                      required
                      value={emailCheckList}
                      onChange={(e) => setEmailCheckList(e.target.value)}
                    />
                  </div>

                  <div className="mt-5 mb-7 w-full xl:mt-9 xl:mb-14 mx-auto">
                    <Button
                      type="submit"
                      className="buttonClass"
                      onClick={handleDownloadCheckList}
                    >
                      <p className="font-normal text-15 md:text-small-text xl:text-body-large">
                        {moduleCheck() !== "secondCheck"
                          ? "Download The Checklist Now"
                          : "Submit"}
                      </p>
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          )}

          <div
            className="flex lg:justify-center w-full sm:gap-4 pt-16 lg:flex-row sm:flex-col"
            style={{
              userSelect: "none",
              minWidth: "100%",
            }}
          >
            <div
              className={!getApiKey() && "w-full"}
              style={{
                minWidth: "100%",
              }}
              id="blurContent"
            >
              {document === true ? (
                <div
                  className="bg-white pdfBoxOne  pl-[100px] pr-[100px] pt-12"
                  style={{ paddingLeft: "100px", paddingRight: "100px" }}
                >
                  <p className="text-h3 md:text-large-small custom:text-36 font-bold"></p>
                  {paidData ? (
                    <div className="pdfClass2">
                      <p className="text-h3 md:text-large-small custom:text-36 font-bold"></p>

                      {type === "essay" &&
                      !window.location.pathname.includes("summary") ? (
                        <div className="relative-div">
                          {state.hide === true ? (
                            <button
                              onClick={() =>
                                setState((prev) => ({
                                  ...prev,
                                  hide: false,
                                }))
                              }
                              className="show-btn"
                            >
                              <FaEye />
                            </button>
                          ) : (
                            <button
                              onClick={() =>
                                setState((prev) => ({
                                  ...prev,
                                  hide: true,
                                }))
                              }
                              className="show-btn"
                            >
                              <FaEyeSlash />
                            </button>
                          )}
                          <div className="mainDiv" ref={viewerRef}>
                            {state.hide === false && (
                              <div className="left-side">
                                <div className="leftSideHeader">
                                  <h3>Table of Contents</h3>
                                </div>
                                <div className="leftSideContent">
                                  {loadingPage ? (
                                    <Loader />
                                  ) : (
                                    pagePreviews.map((previewUrl, index) => {
                                      console.log(previewUrl, "previewUrl");
                                      return (
                                        <div
                                          key={index}
                                          className="boxOne"
                                          // onClick={() =>
                                          //   handleThumbnailClick(index)
                                          // }
                                        >
                                          <img
                                            src={previewUrl}
                                            alt={`Page ${index + 1}`}
                                            style={{ width: "100%" }}
                                          />
                                        </div>
                                      );
                                    })
                                  )}
                                </div>
                              </div>
                            )}

                            <div className="right-side">
                              <div className="button-container">
                                {/* <button
                                  className="button-back"
                                  onClick={() => handleLeftPage()}
                                  disabled={pageNumber === 1}
                                >
                                  <IoMdArrowRoundBack
                                    style={{
                                      heigh: "20px",
                                      width: "20px",
                                      marginTop: "-4px",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                                <button
                                  className="button-back"
                                  onClick={() => handleRightPage()}
                                  disabled={pageNumber === totalPages}
                                >
                                  <IoMdArrowRoundForward
                                    style={{
                                      heigh: "20px",
                                      width: "20px",
                                      marginTop: "-4px",
                                      color: "#fff",
                                    }}
                                  />
                                </button> */}
                                <button
                                  className="button-back"
                                  onClick={() => handleZoom("out")}
                                  disabled={pageScale <= 0.3}
                                >
                                  <BsZoomOut
                                    onClick={() => handleZoom("out")}
                                    style={{
                                      heigh: "20px",
                                      width: "20px",
                                      marginTop: "-4px",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                                <button
                                  className="button-back"
                                  onClick={() => handleZoom("in")}
                                  disabled={pageScale >= 3}
                                >
                                  <BsZoomIn
                                    onClick={() => handleZoom("in")}
                                    style={{
                                      heigh: "20px",
                                      width: "20px",
                                      marginTop: "-4px",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                                <button
                                  onClick={handleFullScreen}
                                  className="button-back"
                                >
                                  <RiFullscreenFill
                                    className="button-icon"
                                    style={{
                                      heigh: "20px",
                                      width: "20px",
                                      marginTop: "-4px",
                                      color: "#fff",
                                    }}
                                  />
                                </button>
                              </div>
                              <Document
                                file={
                                  paidData?.item?.document
                                    ? paidData?.item?.document
                                    : paidData?.document
                                    ? paidData?.document
                                    : paidData?.value?.document
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                                renderTextLayer={true}
                                // loading={<Loader />}
                              >
                                {Array.from(
                                  new Array(totalPages),
                                  (el, index) => (
                                    <Page
                                      pageNumber={index + 1}
                                      scale={pageScale}
                                    />
                                  )
                                )}
                              </Document>
                            </div>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    pdfs !== "" && (
                      <div className="relative-div">
                        {state.hide === true ? (
                          <button
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                hide: false,
                              }))
                            }
                            className="show-btn"
                          >
                            <FaEye />
                          </button>
                        ) : (
                          <button
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                hide: true,
                              }))
                            }
                            className="show-btn"
                          >
                            <FaEyeSlash />
                          </button>
                        )}
                        <div className="mainDiv">
                          {state.hide === false && (
                            <div className="left-side">
                              <div className="leftSideHeader">
                                <h3>Table of Contents</h3>
                              </div>
                              <div className="leftSideContent">
                                {loadingPage ? (
                                  <Loader />
                                ) : (
                                  pagePreviews.map((previewUrl, index) => (
                                    <div
                                      key={index}
                                      className="boxOne"
                                      onClick={() =>
                                        handleThumbnailClick(index)
                                      }
                                    >
                                      <img
                                        src={previewUrl}
                                        alt={`Page ${index + 1}`}
                                        style={{ width: "100%" }}
                                      />
                                    </div>
                                  ))
                                )}
                              </div>
                            </div>
                          )}

                          <div className="right-side">
                            <div className="button-container">
                              {/* <input
                        type={"text"}
                        onChange={(e) => handleSearch(e.target.value)}
                        className="inputField"
                        placeholder="Search"
                      /> */}
                              <button
                                className="button-back"
                                onClick={() => handleZoom("out")}
                                disabled={pageScale <= 0.3}
                              >
                                <BsZoomOut
                                  onClick={() => handleZoom("out")}
                                  style={{
                                    heigh: "20px",
                                    width: "20px",
                                    marginTop: "-4px",
                                    color: "#fff",
                                  }}
                                />
                              </button>
                              <button
                                className="button-back"
                                onClick={() => handleZoom("in")}
                                disabled={pageScale >= 3}
                              >
                                <BsZoomIn
                                  onClick={() => handleZoom("in")}
                                  style={{
                                    heigh: "20px",
                                    width: "20px",
                                    marginTop: "-4px",
                                    color: "#fff",
                                  }}
                                />
                              </button>
                              <button
                                onClick={handleFullScreen}
                                className="button-back"
                              >
                                <RiFullscreenFill
                                  className="button-icon"
                                  style={{
                                    heigh: "20px",
                                    width: "20px",
                                    marginTop: "-4px",
                                    color: "#fff",
                                  }}
                                />
                              </button>
                            </div>
                            <Document
                              file={
                                paidData?.item?.document
                                  ? paidData?.item?.document
                                  : paidData?.document
                                  ? paidData?.document
                                  : paidData?.value?.document
                              }
                              onLoadSuccess={onDocumentLoadSuccess}
                              renderTextLayer={true}
                              // loading={<Loader />}
                            >
                              <Page pageNumber={pageNumber} scale={pageScale} />
                            </Document>
                          </div>
                        </div>
                      </div>
                    )
                  )}
                </div>
              ) : null}

              {/* eslint-disable-next-line no-mixed-operators */}
            </div>
          </div>
        </div>
        <Modal
          open={loginModal}
          onClose={() => setLoginModal(false)}
          focusTrapped={false}
          center
        >
          <Formik
            initialValues={{
              password: "",
              username: "",
            }}
            validationSchema={validateSchema}
            onSubmit={(values) => handleUserLogin(values)}
          >
            {({ isValid, handleSubmit, values }) => (
              <Form>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <div className="loginInputMain flex flex-col text-darkest items-center">
                    <div className="w-full mb-3 custom2::mb-9 ">
                      <p className="mb-8">Username or Email</p>
                      <Field
                        name="username"
                        as={Input}
                        autoComplete="off"
                        placeholder="Enter your username or email"
                        type="text"
                      />
                    </div>
                    <div className="w-full  mb-3 custom2:mb-9">
                      <p className="mb-8 ">Password</p>
                      <Field
                        name="password"
                        as={Input}
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                        inputType="password"
                        autoComplete="off"
                      />
                    </div>
                    <Link className="mb-8 forgetText " onClick={forgetPass}>
                      Forget Password?
                    </Link>
                  </div>
                  <div className="pt-4 flex justify-center mb-10">
                    <div className="w-full h:34 md:h-42 lg:h-51">
                      <Button
                        onClick={handleSubmit}
                        text="Log in"
                        filled
                        className="buttonClass"
                        disabled={
                          values.username === "" ||
                          values.password === "" ||
                          !isValid
                        }
                        loading={loader}
                      />
                    </div>
                  </div>
                </form>
              </Form>
            )}
          </Formik>
          <p className="loginBottomText font-normal text-darkest font-body-large text-center">
            You don't have an account yet?{" "}
            <a
              className="font-bold text-primary cursor-pointer"
              onClick={() => {
                setSignupModal(true);
                setLoginModal(false);
              }}
            >
              {"  "}Sign up
            </a>
          </p>
        </Modal>

        {/* Sign Up Modal */}
        <Modal
          open={signupModal}
          onClose={() => setSignupModal(false)}
          focusTrapped={false}
          center
        >
          <Formik
            initialValues={{
              password1: "",
              username: "",
              email: "",
              password2: "",
            }}
            validationSchema={validateSchemaSignup}
            onSubmit={(values) => onSignUp(values)}
          >
            {({ isValid, handleSubmit, values }) => (
              <Form>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="flex flex-col text-darkest items-center">
                    <div className="w-full mb-3 md:mb-9 ">
                      <p className="mb-8">Email</p>
                      <Field
                        name="email"
                        as={Input}
                        placeholder="Enter your email"
                        type="text"
                      />
                    </div>

                    <div className="w-full mb-3 md:mb-9 ">
                      <p className="mb-8">Username</p>
                      <Field
                        name="username"
                        as={Input}
                        placeholder="Enter your username"
                        type="text"
                      />
                    </div>

                    <div className="w-full ">
                      <p className="mb-8">Password</p>
                      <Field
                        name="password1"
                        as={Input}
                        placeholder="Enter your password"
                        type={showPassword ? "text" : "password"}
                        setShowPassword={setShowPassword}
                        showPassword={showPassword}
                        inputType="password"
                      />
                      <p className="text-small text-darkGrey2 my-8">
                        6+ characters, 1 capital letter{" "}
                      </p>
                    </div>
                    <div className="w-full mb-10 md:mb-14">
                      <p className="mb-8">Confirm password</p>
                      <Field
                        name="password2"
                        as={Input}
                        placeholder="Enter your confirm password"
                        type={showConfirmPassword ? "text" : "password"}
                        setShowPassword={setShowConfirmPassword}
                        showPassword={showConfirmPassword}
                        inputType="password"
                      />
                    </div>
                    <div
                      className="w-full mb-10 md:mb-14"
                      style={{ display: "flex" }}
                    >
                      <label className="condContainer">
                        {" "}
                        I agree to{" "}
                        <a
                          style={{ color: "#cc6828", cursor: "pointer" }}
                          onClick={() => {
                            history.push("/terms-conditions");
                          }}
                        >
                          Terms and Conditions
                        </a>
                        ,{" "}
                        <a
                          style={{ color: "#cc6828", cursor: "pointer" }}
                          onClick={() => {
                            history.push("/privacy-policy");
                          }}
                        >
                          Privacy Policy
                        </a>{" "}
                        and{" "}
                        <a
                          style={{ color: "#cc6828", cursor: "pointer" }}
                          onClick={() => {
                            history.push("/cookies-policy");
                          }}
                        >
                          Cookies Policy
                        </a>{" "}
                        of Simple Studying{" "}
                        <input
                          id="condBox"
                          onClick={(e) => checked(e.target.checked)}
                          type="checkbox"
                          className="default:ring-2 text-primary mr-3 checkBoxOne"
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                  <div className="pt-4 flex justify-center mb-10">
                    <div className="w-full h-51">
                      <Button
                        onClick={handleSubmit}
                        text="Sign up for free"
                        filled
                        className="buttonClass"
                        disabled={
                          values.username === "" ||
                          values.password1 === "" ||
                          values.password2 === "" ||
                          values.email === "" ||
                          !isValid ||
                          values.checked === "false"
                        }
                        loading={loader}
                      />
                    </div>
                  </div>
                </form>
              </Form>
            )}
          </Formik>
          <p className="font-normal text-darkest font-body-large text-center">
            Already have an account?{" "}
            <a
              className="font-bold text-primary cursor-pointer"
              onClick={() => {
                setLoginModal(true);
                setSignupModal(false);
              }}
            >
              {"  "}Log in
            </a>
          </p>
        </Modal>
        {/* </Container> */}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: notesLoading(state),
  error: notesError(state),
  relevant: notesData(state),
  checkListData: checkListData(state),
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getNotes: (page, title, slug) => dispatch(getNotes()),
  getCheckList: () => dispatch(checkListAction()),
  postMailChimp: (email, study) => dispatch(postMailChimpAction(email, study)),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PaidUsers);
