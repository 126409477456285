import * as t from '../types';
import {GroupAppointment} from '../../services'
import {createErrorAlert} from "./errorAlertAction";


export const groupAppointment = (body) => async (dispatch) => {
    dispatch({
        type: t.GROUP_APPOINTMENT_REQUEST
    });

    return await GroupAppointment(body)
        .then((data) => {
            dispatch({
                type: t.GROUP_APPOINTMENT_SUCCESS,
                payload: data
            });
            dispatch(createErrorAlert(700, 'Congratulations', 'You have successfully signed up for the class.', 700));

        })
        .catch((err) => {
            dispatch({
                type: t.GROUP_APPOINTMENT_FAILED,
                payload: err.response
            });
            if(err.response.data[0].includes('Zoom')){
                    dispatch(createErrorAlert(400, 'Error', 'Zoom link is not Generated', 400));
            } else{
                dispatch(createErrorAlert(400, 'Error', 'Appointment already created for this session', 400));

            }
        });
};
