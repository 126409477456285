import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { createErrorAlert, userSignUp } from '../../redux/actions';
import { signupError, signupData, signupLoader } from '../../redux/selectors';
import logo from '../../assets/brand/small_logo.png';
import Input from '../../components/Input/InputComponent';
import Button from '../../components/EmptyButton';
import GoogleAuthentication from '../../components/SocialAuthentication/GoogleSocialAuth';
import FacebookAuthentication from '../../components/SocialAuthentication/FacebookSocialAuth';
import CookieConsent from "react-cookie-consent";
import img1 from "../../assets/newImages/Group 1399.png"
import img3 from "../../assets/newImages/Group 1403.png"
import img2 from "../../assets/newImages/Illustration.png"
import fb from "../../assets/icons/Facebook.png"
import google from "../../assets/icons/Google.png"


const SignUpMain = (props) => {
    const { userSignUp, loader } = props;
    const history = useHistory();
    const dispatch = useDispatch()
    const [checkBox, setCheckBox] = useState(false)
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const validateSchema = Yup.object({
        email: Yup.string().email('Invalid email').required('Email is required'),
        username: Yup.string().required('Username is required').min(5, 'Minimum 5 characters')
            .max(26, 'Maximum 26 characters'),
        password2: Yup.string()
            .oneOf([Yup.ref('password1'), null], 'Passwords must match'),
        password1: Yup.string()
            .required('Password is required.')
            .min(8, 'Password is too short - should be 8+ chars minimum.')
            .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Password should have at least one capital letter & one digit.'),
    });
    const onSignUp = (values) => {
        if (checkBox === true) {
            userSignUp(values, history);
        } else {
            dispatch(createErrorAlert(201, 'Error', 'Please agree to the terms and Conditions', 201));

        }
    };

    const checked = (e) => {
        if (e === true) {
            setCheckBox(true)
        } else {
            setCheckBox(false)
        }

    }
    return (
        <div className="bg-white bg-main-page-back  bg-no-repeat bg-contain">
            <div className="signup-info ">
                <div className="flex flex-col md:flex-row   h-screen">
                    <div className="w-2/4 hidden md:block custom2:w-2/4 custom2:pl-220 pl-6 pt-9 ">
                        <div className="mb-7 custom2:mb-14">
                            <div className="w-44 h-10 custom2:w-auto custom2:h-auto block"
                                onClick={() => history.push('/')}>
                                <img
                                    src={logo}
                                    className="img-fluid bigscreen"
                                    alt="logo"
                                />
                            </div>
                        </div>
                        {/* <img
                            className="hidden md:block custom2:w-auto custom2:h-auto"
                            src={'https://simplestudying.com/static/media/signupImage.e2b35106.webp'}
                            alt="logo"
                        /> */}

                        <div >

                            <p className='text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1'>
                                Join the movement of <span className="text-yellowish">20K savvy students</span> already empowered - and you're next
                            </p>

                            <p className="allSpecLText text-body-small text-left  md:text-h5 xl:text-36 py-8"
                                >
                                Discover the secret to success as you become part of our growing community.
                            </p>
                            <div className='flex justify-around mt-10 items-center flex-col lg:flex-row'>
                                <div className=' text-center'>
                                    <div className='w-150 h-150'>
                                        <img src={img1} className='m-auto mt-3 w-full h-full' />
                                    </div>
                                    <p className='  fw-bold text-42 text-yellowish '>35+</p>
                                    <p>Study Modules<br />All main areas of law</p>
                                </div>
                                <div className=' text-center'>
                                    <div className='w-150 h-150'>
                                        <img src={img2} className='m-auto mt-3 w-full h-full' />
                                    </div>
                                    <p className='fw-bold text-42  text-yellowish'>500K+</p>
                                    <p>Users<br />Every Year</p>
                                </div>
                                <div className=' text-center'>
                                    <div className='w-150 h-150'>
                                        <img src={img3} className='m-auto mt-3 w-full h-full' />
                                    </div>
                                    <p className='fw-bold text-42  text-yellowish'>1430+</p>
                                    <p>Hours Tutoring <br />Delivered</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div
                        className="md:bg-white m-auto flex justify-center flex-col w-full md:w-2/4 custom2:w-2/4 py-4 md:py-14 lg:py-20 px-4 md:px-7 custom2:pr-40 mac:pr-80 custom2:pl-40 ">
                            <div className="w-fit h-10 custom2:w-auto my-14 m-auto md:hidden custom2:h-auto block"
                                onClick={() => history.push('/')}>
                                <img
                                    src={logo}
                                    className="img-fluid bigscreen"
                                    alt="logo"
                                />
                            </div>
                        <p className="tutorialVidText text-darkest font-bold text-large-medium custom2:text-36 text-center">
                            Sign up to SimpleStudying
                        </p>

                        <div className="flex w-80% rounded-lg p-1 border-slate-300 m-auto mb-1">
                        <div className="flex m-auto border-2 border-ligthBorderGrey rounded-xl p-1 w-full justify-center">
                                <img src={google} className="mr-2" />
                                <button className="border-1">Continue with Google</button>
                            </div>


                        </div>
                        <div className="flex  w-80% rounded-lg p-1 border-slate-300 m-auto mt-0">
                        <div className="flex m-auto border-2 border-ligthBorderGrey rounded-xl p-1 w-full justify-center">

                                <img src={fb} className="mr-2" />
                                <button className="border-1">Continue with Facebook</button>
                            </div>

                        </div>
                        {/* <GoogleAuthentication/>
                        <FacebookAuthentication/> */}
                        <div className="flex flex-row justify-center items-center px:2 md:px-4 lg:px-6 my-2 md:my-4 lg:my-7">
                            {/* <div className="border border-ligthBorderGrey w-2/5">

                            </div>
                            <p className="px-3.5 text-darkest font-body-large ">or</p>
                            <div className="border border-ligthBorderGrey w-2/5">

                            </div> */}
                        </div>
                        <Formik
                            initialValues={{
                                password1: '',
                                username: '',
                                email: '',
                                password2: '',
                            }}
                            validationSchema={validateSchema}
                            onSubmit={(values) => onSignUp(values)}
                        >
                            {({ isValid, handleSubmit, values }) => (
                                <Form>
                                    <form
                                        onSubmit={(e) =>
                                            e.preventDefault()
                                        }
                                    >
                                        <div className="flex flex-col text-darkest items-center">
                                            <div className="w-full mb-3 md:mb-9 ">
                                                <p className="mb-8">
                                                    Email
                                                </p>
                                                <Field
                                                    name="email"
                                                    as={Input}
                                                    placeholder="Enter your email"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full mb-3 md:mb-9 ">
                                                <p className="mb-8">
                                                    Username
                                                </p>
                                                <Field
                                                    name="username"
                                                    as={Input}
                                                    placeholder="Enter your username"
                                                    type="text"
                                                />
                                            </div>

                                            <div className="w-full ">
                                                <p className="mb-8">
                                                    Password
                                                </p>
                                                <Field
                                                    name="password1"
                                                    as={Input}
                                                    placeholder="Enter your password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    setShowPassword={setShowPassword}
                                                    showPassword={showPassword}
                                                    inputType="password"
                                                />
                                                <p className="text-small text-darkGrey2 my-8">8+ characters, 1 capital
                                                    letter </p>
                                            </div>
                                            <div className="w-full mb-6 ">
                                                <p className="mb-8">
                                                    Confirm password
                                                </p>
                                                <Field
                                                    name="password2"
                                                    as={Input}
                                                    placeholder="Enter your confirm password"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    setShowPassword={setShowConfirmPassword}
                                                    showPassword={showConfirmPassword}
                                                    inputType="password"
                                                />
                                            </div>
                                            <div className="w-full mb-6 " style={{ display: 'flex' }}>
                                                <label className='condContainer'> I agree to <a
                                                    style={{ color: '#cc6828', cursor: 'pointer' }}
                                                    onClick={() => {
                                                        history.push('/terms-conditions')
                                                    }}>Terms and Conditions</a>, <a
                                                        style={{ color: '#cc6828', cursor: 'pointer' }} onClick={() => {
                                                            history.push('/privacy-policy')
                                                        }}>Privacy Policy</a> and <a
                                                            style={{ color: '#cc6828', cursor: 'pointer' }} onClick={() => {
                                                                history.push('/cookies-policy')
                                                            }}>Cookies Policy</a> of Simple Studying  <input id="condBox" onClick={(e) => checked(e.target.checked)} type="checkbox" className="default:ring-2 text-primary mr-3 checkBoxOne" />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                        <div className="pt-2 flex justify-center mb-10">
                                            <div className="w-full h-51">
                                                <Button onClick={handleSubmit} text="Sign up for free" filled
                                                    className="buttonClass"
                                                    disabled={values.username === '' || values.password1 === '' || values.password2 === '' || values.email === '' || !isValid || values.checked === 'false'}
                                                    loading={loader} /></div>
                                        </div>
                                    </form>
                                </Form>)}
                        </Formik>

                        <p className="font-normal text-darkest font-body-large text-center">
                            Already have an account?{' '} <a className="font-bold text-primary cursor-pointer"
                                onClick={() => history.push('/login')}>{'  '}Log
                                in</a>
                        </p>

                        <CookieConsent
                            enableDeclineButton
                            location="bottom"
                            declineButtonText="Reject All"
                            declineButtonStyle={{ color: "red", fontSize: "18px", marginBottom: '20px', backgroundColor: '#fff' }}
                            buttonText="Accept All"
                            cookieName="CookiesPolicy"
                            style={{ background: "#2B373B" }}
                            buttonStyle={{ color: "#fff", fontSize: "18px", marginBottom: '-20px', backgroundColor: '#cc6828' }}
                            expires={150}
                        >
                            <span style={{ fontWeight: 'bold', fontSize: '20px' }}>We value your privacy</span> <br />
                            We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept All", you consent to our use of cookies.{" "}
                        </CookieConsent>
                    </div>

                </div>
            </div>
        </div>

    );
};
const mapStateToProps = state => (
    {
        loader: signupLoader(state),
        error: signupError(state),
        data: signupData(state),
    }
);
const mapDispatchToProps = dispatch => (
    {
        userSignUp: (body, history) => dispatch(userSignUp(body, history)),
    });
export default connect(mapStateToProps, mapDispatchToProps)(SignUpMain);
