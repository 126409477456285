import React, {useEffect, useState} from 'react';
import {connect, useDispatch} from 'react-redux';
import Button from '../../components/EmptyButton';
import Input from '../../components/Input/InputComponent';
import * as Yup from 'yup';
import {Field, Form, Formik} from 'formik';
import {confirmPasswordError, confirmPasswordLoading, confirmPasswordMessage} from '../../redux/selectors';
import {confirmResetPassword, createErrorAlert} from '../../redux/actions';
import ErrorMsg from '../../components/ErrorMsg';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";

function ConfirmPassword(props) {
    const history = useHistory();
    const {message, loading, error, confirmResetPassword, match} = props;
    const dispatch = useDispatch();
    const [showNewPassword, setNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const validateSchema = Yup.object({
        confirmPassword: Yup.string()
            .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
        newPassword: Yup.string()
            .required('Password is required.')
            .min(7, 'Password is too short - should be 6+ chars minimum.')
            .matches(/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/, 'Password should have at least one capital letter & one digit.'),
    });
    useEffect(() => {
        if (error?.new_password1?.length > 0) {
            dispatch(createErrorAlert(400, 'Error', error?.new_password1[0], 400));
        } else if (error?.new_password2?.length > 0) {
            dispatch(createErrorAlert(400, 'Error', error?.new_password2[0], 400));
        }
        if (message) {
            setTimeout(() => {
                history.push('/login');
            }, 6000);

        }
    }, [error, message]);
    const resetPassword = (values) => {
        let obj = {
            uid: match.params.uid,
            token: match.params.token,
            new_password1: values.newPassword,
            new_password2: values.confirmPassword,
        };
        confirmResetPassword(obj);
    };
    return (
        <div className="bg-white bg-main-page-back w-full bg-no-repeat bg-cover">
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Confirm Password</title>
                <meta name="description" content="Forget Passowrd Description"/>
                <link rel="canonical" href="https://simplestudying.com/reset-password"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | Forget Passowrd"/>
                <meta property="og:description" content="Forget Passowrd Description"/>
                <meta property="og:url" content="https://simplestudying.com/reset-password"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="px-4 md:px-36 custom2:px-0">
                <p className="mt-10 md:mt-28 custom2:mt-36 mb-14 font-bold text-h4 md:text-large-medium custom2:text-36 text-center text-darkest">
                    Reset Password
                </p>
                <Formik
                    initialValues={{
                        newPassword: '',
                        confirmPassword: '',
                    }}
                    validationSchema={validateSchema}
                    onSubmit={(values) => resetPassword(values)}
                >
                    {({isValid, handleSubmit, values}) => (
                        <Form>
                            <form
                                onSubmit={(e) =>
                                    e.preventDefault()
                                }
                                onPaste={(e) => e.preventDefault()}
                            >
                                <div className="pb-11 flex flex-col justify-center items-center">
                                    <div className="w-full md:w-472 ">
                                        <p className="mb-8">
                                            New Password
                                        </p>
                                        <Field
                                            name="newPassword"
                                            as={Input}
                                            placeholder="Enter new password"
                                            type={showNewPassword ? 'text' : 'password'}
                                            setShowPassword={setNewPassword}
                                            showPassword={showNewPassword}
                                            inputType="password"
                                        />
                                    </div>
                                    <div className="w-full md:w-472 ">
                                        <p className="mb-8">
                                            Confirm New Password
                                        </p>
                                        <Field
                                            name="confirmPassword"
                                            as={Input}
                                            placeholder="Confirm your password"
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            setShowPassword={setShowConfirmPassword}
                                            showPassword={showConfirmPassword}
                                            inputType="password"
                                        />
                                    </div>
                                </div>
                                {error.detail && <div>
                                    <div className="mb-8 text-center">
                                        <ErrorMsg error={error?.detail} color={'red'}
                                                  background={'transparent'}/>
                                    </div>
                                </div>}

                                {message ? <div className="w-full md:w-472">
                                    <div className="mb-8 text-center">
                                        <ErrorMsg error={message?.detail} color={'black'}
                                                  background={'lightgreen'}/>
                                    </div>
                                </div> : null}
                                <div className="w-full pt-4 flex justify-center mb-20 mt-12">
                                    <div className="w-full md:px-0 md:w-40 h-51">
                                        <Button onClick={handleSubmit} text="Update" filled
                                                disabled={values.oldPassword === '' || values.newPassword === '' || values.confirmPassword === '' || !isValid}
                                                loading={loading}/>
                                    </div>
                                </div>
                            </form>
                        </Form>)}
                </Formik>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    message: confirmPasswordMessage(state),
    loading: confirmPasswordLoading(state),
    error: confirmPasswordError(state),
});
const mapDispatchToProps = dispatch => ({
    confirmResetPassword: body => dispatch(confirmResetPassword(body)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword);