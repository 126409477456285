import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Container from "../../components/container";
import BreadCrumb from "../../components/BreadCrumb";
import InfoBox from "../../components/InfoBox";
import Button from "../../components/EmptyButton";
import LazyImage from "../../components/LazyImage";
import InfoImg from "../../assets/icons/information.svg";
import Essays from "../../assets/images/main-page-materials/essays.webp";
import Notes from "../../assets/images/main-page-materials/notes.webp";
import Platform from "../../assets/images/main-page-materials/case-summaries.webp";
import Quiz from "../../assets/images/main-page-materials/quiz.webp";
import Tips from "../../assets/images/main-page-materials/fashcard.webp";
import Tutorials from "../../assets/images/main-page-materials/tutorials.webp";
import ArrowLeft from "../../assets/icons/arrowLeft.webp";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { apiRequest, getApiKey, getUser, slugToText } from "../../helpers";
import {
  createErrorAlert,
  getCatalogue,
  getSpecificStudyModuleDetail,
  setValue,
} from "../../redux/actions";
import {
  catalogueData,
  specificModuleData,
  specificModuleError,
  specificModuleLoading,
} from "../../redux/selectors";
import { Helmet } from "react-helmet";
import bgIMG from "../../assets/newImages/Group 1391.png";
import books from "../../assets/newImages/Group 1393.png";

const StudyModulePage = ({
  getSpecificStudyModuleDetail,
  data,
  dataCatalogue,
  getCatalogue,
}) => {
  const dispatch = useDispatch();
  const param = useParams();
  const history = useHistory();
  const location = useLocation();

  const [state, setState] = useState({
    moduleDetail: data,
    showInfo: false,
    paidModules: [],
    user: JSON.parse(getUser()),
  });

  const breadCrumbItem = [{ title: "Get study modules", link: "/" }];

  useEffect(() => {
    dispatch(setValue(param.slug));
    getSpecificStudyModuleDetail(param.slug);
    getCatalogue(null);
  }, []);
  useEffect(() => {
    if (data) {
      data.map((item) => {
        let custom = {
          name: item.title,
          short_description: item.short_description,
          price: item.price,
          author: item.author,
          slug: item.slug,
        };
        setState((prev) => ({
          ...prev,
          moduleDetail: custom,
        }));
      });
    }
  }, [data]);
  useEffect(() => {
    for (let i = 0; i < dataCatalogue?.data?.length; i++) {
      if (dataCatalogue?.data[i]?.is_paid === true) {
        if (
          !state.paidModules?.includes(
            dataCatalogue?.data[i]?.study_module?.slug
          )
        ) {
          state.paidModules?.push(dataCatalogue?.data[i]?.study_module.slug);
        }
      }
    }
  }, [dataCatalogue]);

  console.log(state.paidModules, "module");

  const essays = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({ pathname: `/${param.slug}/model-essays` });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/model-essays` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Model Essays",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/model-essays` });
      }
    }
    if (getApiKey() && state.user?.user_type === "TEACHER") {
      history.push({ pathname: `/${param.slug}/model-essays` });
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Model Essays please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/model-essays` });
    }
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      dispatch(
        createErrorAlert(
          203,
          "Info",
          "To view Model Essays you must have a premium subscription",
          203
        )
      );
    }
  };

  const tutorials = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({
        pathname: `/${param.slug}/tutorial-videos`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/tutorial-videos` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Tutorial Videos",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/tutorial-videos` });
      }
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          211,
          "Info",
          "To view Tutorial Videos please Login. No SimpleStudy account? Sign Up",
          211
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT"
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/tutorial-videos` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${param.slug}/tutorial-videos` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Tutorial Videos you must have a Premium subscription.",
          203
        )
      );
    }
  };
  const flashCards = () => {
    if (
      (state.user?.subscription_name === "Basic Subscription" ||
        state.user?.subscription_name.includes("STARTER")) &&
      state.user?.free_trial === false &&
      state.user?.user_type === "STUDENT" &&
      state.paidModules.includes(param.slug)
    ) {
      history.push({
        pathname: `/${param.slug}/flashcards`,
        state: { paidModules: state.paidModules },
      });
      return;
    }
    if (
      getApiKey() &&
      state.user?.subscription_name !== "Basic Subscription" &&
      !state.user?.subscription_name.includes("STARTER") &&
      state.user?.user_type === "STUDENT"
    ) {
      if (state.moduleDetail.author !== 1) {
        if (state.paidModules.includes(state.moduleDetail.slug)) {
          history.push({ pathname: `/${param.slug}/flashcards` });
        } else {
          dispatch(
            createErrorAlert(
              211,
              "Info",
              "You have to buy this module to access Flash Cards",
              211
            )
          );
        }
      } else {
        history.push({ pathname: `/${param.slug}/flashcards` });
      }
    }
    if (!getApiKey()) {
      dispatch(
        createErrorAlert(
          200,
          "",
          `To view Flash Cards you must be logged in. No SimpleStudy account?`,
          200
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === false
    ) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.user?.free_trial === true
    ) {
      history.push({ pathname: `/${param.slug}/flashcards` });
    }
    if (state.user?.user_type !== "STUDENT") {
      history.push({ pathname: `/${param.slug}/flashcards` });
    }
    if (state.user?.subscription_name.includes("STARTER")) {
      dispatch(
        createErrorAlert(
          203,
          "",
          "To view Flash Cards you must have a Premium subscription.",
          203
        )
      );
    }
  };

  const quizClick = () => {
    if(getApiKey()){
      if(state.user?.subscription_name === "Basic Subscription" && state.user?.free_trial === false){
        dispatch(
          createErrorAlert(
            800,
            "You discovered a Premium feature!",
            "Upgrading to SimpleStudying Premium will unlock it. With Premium, you can maximise your chances of getting a First Class law degree.",
            800
          )
        );
      } else if(state.user?.subscription_name === "Basic Subscription" && state.user?.free_trial === true){
        history.push(`/${param.slug}/quizzes`)
      } else if(state.user?.subscription_name !== "Basic Subscription"){
        history.push(`/${param.slug}/quizzes`)
      }
    } else{
      dispatch(
        createErrorAlert(
          211,
          "",
          "To view Quiz please Login",
          211
        )
      );
    }
  }

  const handleBasket = async () => {
    if (getApiKey()) {
      let obj = {
        study_module: location?.state?.selected?.value?.id,
      };
      await apiRequest({
        method: "post",
        url: "content/basket/",
        data: obj,
      })
        .then((res) => {
          if (res.status === 201) {
            history.push("/shopping-basket");
          }
        })
        .catch((err) => {
          dispatch(createErrorAlert(400, "", err.response.data[0], 400));
        });
    } else {
      history.push("/susbcription");
    }
  };

  const handleRouteTutoring = () => {
    history.push("/boost-your-grades");
  };
  return (
    <div className="bg-white">
      <div className="bg-offWhite">
        <Helmet>
          <meta charSet="utf-8" />
          <title>
            Simple Studying | Study Module | {slugToText(param.slug)}
          </title>
          <meta name="description" content="Study Modules Description" />
          <link
            rel="canonical"
            href={`https://simplestudying.com/study-module/${param.slug}`}
          />
          <meta name="robots" content="index, follow" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:locale:alternate" content="en_gb" />
          <meta property="og:type" content="website" />
          <meta property="og:title" content={`Simple Studying | Study Module | ${slugToText(param.slug)}`} />
          <meta property="og:description" content="Study Modules Description" />
          <meta
            property="og:url"
            content={`https://simplestudying.com/study-module/${param.slug}`}
          />
          <meta property="og:site_name" content="Simple Studying" />
        </Helmet>
        <Container>
          {getApiKey() ? (
            <div className="pt-10 custom:pt-14">
              <BreadCrumb
                items={breadCrumbItem}
                current={slugToText(param.slug)}
              />
            </div>
          ) : null}

          <div className="flex justify-between pt-10 custom:pt-14">
            <div className="flex gap-2 flex-col">
              <div className=" flex items-center relative">
                <img
                  src={InfoImg}
                  alt="info-img"
                  className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                  onMouseEnter={() =>
                    setState((prev) => ({
                      ...prev,
                      showInfo: true,
                    }))
                  }
                  onMouseLeave={() =>
                    setState((prev) => ({
                      ...prev,
                      showInfo: false,
                    }))
                  }
                />
                {state.showInfo && (
                  <div className="tooltipSSec  absolute z-10 top-14 -left-6">
                    <InfoBox text={state.moduleDetail?.short_description} />
                  </div>
                )}
                <p className="text-body-large font-bold text-14 sm:text-14 md:text-h4 custom:text-h3">
                  {slugToText(param.slug) === "It Law"
                    ? "IT Law"
                    : slugToText(param.slug)}
                </p>
              </div>
              {!state.paidModules.includes(param.slug) && getApiKey() && (
                <p className="mb-0 text-body-small md:text-body-large font-normal">
                  As a premium subscriber, you have access to all study
                  materials, but for a limited time! If you want to access
                  certain study modules indefinitely, you can buy them.
                </p>
              )}
            </div>
            <div className="custom:flex items-center">
              <div className="custom:w-64 xl:w-340">
                {!state.paidModules.includes(param.slug) && getApiKey() && (
                  <Button filled onClick={handleBasket}>
                    <p className="custom:text-13 xl:text-body-large text-center px-5">
                      Get All{" "}
                      {slugToText(param.slug) === "It Law"
                        ? "IT Law"
                        : slugToText(param.slug)}{" "}
                      <br className="custom:hidden xl:block" /> Materials FOR
                      ONLY £{state.moduleDetail.price}
                    </p>
                  </Button>
                )}
              </div>
              {!state.paidModules?.includes(param.slug) &&
              (state.user?.subscription_name === "Basic Subscription" ||
                state.user?.subscription_name?.includes("STARTER")) ? (
                <div className="mx-7">
                  <p className="font-bold text-h5">OR</p>
                </div>
              ) : null}
              <div className="w-12/12 xl:w-96">
                {state.user?.subscription_name === "Basic Subscription" ||
                state.user?.subscription_name?.includes("STARTER") ? (
                  <Button filled onClick={() => history.push("/subscription")}>
                    <p className="custom:text-13 xl:text-body-large text-center px-5">
                      Get All the Study Modules{" "}
                      <br className="custom:hidden xl:block" /> for £7.5/MONTH
                    </p>
                  </Button>
                ) : null}
              </div>
              {!getApiKey() && (
                <Button filled onClick={() => history.push("/subscription")}>
                  <p className="custom:text-13 xl:text-body-large text-center px-5">
                    Get All the Study Modules{" "}
                    <br className="custom:hidden xl:block" /> for £7.5/MONTH
                  </p>
                </Button>
              )}
            </div>
          </div>
        </Container>
      </div>
      <div className="bg-study-module-back bg-no-repeat bg-top bg-contain">
        <Container>
          <div className="flex flex-col md:flex-row justify-between gap-3">
            <div
              className="pt-16 pb-16 grid grid-cols-2 md:grid-cols-3 gap-y-14 lg:gap-y-16 custom:gap-y-20 justify-items-center custom:px-1 flex-1"
              style={{ flex: "70%", flexBasis: "70%" }}
            >
              {data[0]?.notes !== 0 ? (
                <div
                  className="w-32 h-32 
                          lg:w-11rem lg:h-11rem
                           xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() =>
                    history.push({ pathname: `/${param.slug}/notes` })
                  }
                >
                  <LazyImage alt={""} src={Notes} className="h-full w-full" />
                  <p className="text-center font-bold text-14 lg:text-body-large">
                    Revision Notes
                  </p>
                </div>
              ) : null}

              {data[0]?.summaries !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-56 lg:h-56 custom:w-60 custom:h-60 xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() =>
                    history.push({
                      pathname: `/case-studies/${param.slug}`,
                      state: { paidModules: state.paidModules },
                    })
                  }
                >
                  <LazyImage alt={""} src={Platform} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Case Summaries
                  </p>
                </div>
              ) : null}

              {data[0]?.essays !== 0 ? (
                <div
                  className="w-32 h-32 lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={essays}
                >
                  <LazyImage alt={""} src={Essays} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Model Essays
                  </p>
                </div>
              ) : null}
              {/* custom:w-60 custom:h-60 */}
              {data[0]?.quiz ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={() => quizClick()}
                >
                  <LazyImage alt={""} src={Quiz} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Quiz
                  </p>
                </div>
              ) : null}

              {data[0]?.tutorial_videos !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem  xl:w-72 xl:h-72 cursor-pointer"
                  onClick={tutorials}
                >
                  <LazyImage alt={""} src={Tutorials} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4">
                    Tutorial Videos
                  </p>
                </div>
              ) : null}

              {data[0]?.flash_cards !== 0 ? (
                <div
                  className="w-32 h-32  lg:w-11rem lg:h-11rem xl:w-72 xl:h-72 cursor-pointer"
                  onClick={flashCards}
                >
                  <LazyImage alt={""} src={Tips} />
                  <p className="text-center font-bold text-14 lg:text-body-large xl:text-h4 mt-6 md:mt-5 lg:mt-9 custom:mt-12">
                    Flashcards
                  </p>
                </div>
              ) : null}
            </div>
            <div
              className="bg-white relative flex-1 p-5 flex flex-col text-center rounded-3xl h-max my-16 mt-20"
              style={{
                flex: "30%",
                flexBasis: "30%",
                boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
                background: " rgb(255,255,255)",
                background:
                  " linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 51%, rgba(231,231,231,1) 99%)",
              }}
            >
              <div className="m-auto">
                <img src={books} className="m-auto" />
              </div>
              <div className="absolute bottom-0 z-0">
                <img src={bgIMG} />
              </div>
              <p className="mb-5">
                Hey there, future legal eagles! While our study materials set
                you up well for that coveted First Class, let’s face it: many of
                us need that extra boost to nail that law degree.
              </p>
              <p className="mb-5">
                Level up your game with personalised tutoring. It’s the secret
                sauce to securing that top-grade status!
              </p>
              <button
                onClick={handleRouteTutoring}
                className="
                  flex items-center fw-semibold z-10
                  text-primary text-14 text-center m-auto p-2 mt-2 border-primary border-2 hover:text-white hover:bg-primary"
              >
                Get your tutoring now and soar to success! 🚀{" "}
              </button>
            </div>
          </div>
        </Container>
        <Container>
          <div className="pb-8 pt-8 md:pt-20 lg:pb-28 md:pb-18 flex justify-center">
            <div className="md:w-80 custom:w-96 backToSearchBtn">
              {getApiKey() ? (
                <Button onClick={() => history.push("/")}>
                  <p className=" text-darkest text-small-text px-2 md:font-body-large flex items-center md:text-15 xl:py-2">
                    <img src={ArrowLeft} alt="arrow" className="mr-2" />
                    Back to Searching Study Materials
                  </p>
                </Button>
              ) : null}
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  data: specificModuleData(state),
  loading: specificModuleLoading(state),
  error: specificModuleError(state),
  dataCatalogue: catalogueData(state),
});
const mapDispatchToProps = (dispatch) => ({
  getSpecificStudyModuleDetail: (slug) =>
    dispatch(getSpecificStudyModuleDetail(slug)),
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(StudyModulePage);
