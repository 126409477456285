/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'

import LazyImage from '../LazyImage'
import Button from '../EmptyButton'
import {useHistory} from "react-router-dom";
import {getApiKey} from "../../helpers";

const StudyMaterialCard = ({img, alt, color, text, data, description, imageClass}) => {

    const history = useHistory()

    const user = JSON.parse(localStorage.getItem('app_user'))

    const contentWelcome = () => {
        if (!getApiKey() && alt === 'Purple card image') {
            history.push('/contentwelcome')
        }
    }

    const signup = () => {
        history.push('/signup')
    }
    const subscription = () => {
        history.push('/subscription')
    }

    return (
        <div className={`lg:px-5 w-full p-3 flex flex-col items-center ${color}`}
             style={{borderRadius: '55px', zIndex: 2}}>
            <div className={imageClass || 'w-36 mt-7 xl:w-80 xl:h-80 xl:mt-12'}>
                <LazyImage src={img} alt={alt}/>
            </div>
            <div className='mt-4 xl:mt-12'>
                {text}
            </div>
            <div className='mt-3 xl:mt-7 font-normal'>
                {description}
            </div>
            <div className='mt-4 xl:mt-7'>
                {alt !== 'Purple card image' && !window.location.pathname.includes('-') ?
                    <p onClick={() => {
                        history.push('/signup')
                    }} className='text-13 xl:text-h5 text-primary underline'
                       style={{color: 'white', cursor: 'pointer'}}>Tutoring
                        for
                        personalised
                        support</p> : null}


            </div>
            <div className='mt-5 mb-7 w-52 xl:mt-9 xl:w-64 xl:mb-14 abcMain'>
                {alt === 'Purple card image' ? <Button filled onClick={contentWelcome}><p
                    className='font-normal text-15 md:text-small-text xl:text-body-large'>Find out More</p>
                </Button> : alt === 'Green card image' &&  !window.location.pathname.includes('/notes') && user?.subscription_name === 'Basic Subscription' ? <Button filled onClick={subscription}><p
                    className='font-normal text-15 md:text-small-text xl:text-body-large'>Upgrade your membership</p>
                </Button> : alt === 'Green card image' && window.location.pathname.includes('/notes') && !getApiKey() ? <Button filled onClick={signup}><p
                    className='font-normal text-15 md:text-small-text xl:text-body-large'>Sign Up Now!</p>
                </Button> : !getApiKey() && alt === 'Green card image' ? <Button filled onClick={signup}><p
                    className='font-normal text-15 md:text-small-text xl:text-body-large'>Sign Up Now!</p></Button> : null } 

            </div>
        </div>
    )
}

export default StudyMaterialCard
