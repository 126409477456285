import React from 'react'

import Container from '../container'
import LazyImage from '../LazyImage'

import Logo from '../../assets/brand/small_logo.png'

const ContentCreatorStepper = ({ step, exit }) => {
    return (
        <Container>
            <div className='py-5 flex items-center '>
                <div className='md:mr-10 xl:mr-14 hidden md:block'>
                    {window.location.pathname !== '/tutoring/tutor-select/one-2-one' && window.location.pathname !== '/book-tutorials/appointment/one-2-one' && window.location.pathname !== '/book-tutorials/one-to-one-payment' && <LazyImage src={Logo} alt='Logo' />}
                </div>
                {step === 1 ? (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>1 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'/>
                            <div className='w-full bg-lightGray h-1'/>
                            <div className='w-full bg-lightGray h-1 rounded-sm'/>
                        </div>
                    </div>
                ) : step === 2 ? (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>2 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'/>
                            <div className='w-full bg-lightGray h-1 rounded-sm'/>
                        </div>
                    </div>
                ) : (
                    <div className='flex flex-col w-full'>
                        <p className='mb-2 text-body-large'>Step <span className='font-bold'>3 of 3</span></p>
                        <div className='w-full flex flex-row'>
                            <div className='w-full linear-background h-1 rounded-sm'/>
                        </div>
                    </div>
                )}
                <div onClick={exit} className='ml-5 custom:ml-12 flex items-center'>
                    <p className='font-bold text-primary text-body-large cursor-pointer pt-6'>Exit</p>
                </div>
            </div>
        </Container>
    )
}

export default ContentCreatorStepper
