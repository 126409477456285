import React, {useState} from "react";

import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import {Document, Page, pdfjs} from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import {useHistory} from "react-router-dom";
import {getApiKey} from "../../helpers";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const FreeUsers = ({data, slug, user}, file, searchString) => {

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);
    const loggedinUser = JSON.parse(localStorage.getItem('app_user'))

    const history = useHistory()

    const upgradeMembership = (name) => {
        if (name === "GET COVERED FOR THE EXAM PERIOD" || name === "IDEAL IF YOU AIM FOR A FIRST CLASS" || name === "QUICK BOOST OF YOUR GRADES") {
            history.push({pathname: `/all-subscriptions`});
        } else if (!getApiKey()) {
            history.push('/subscription')
        }
    };

    function onDocumentLoadSuccess({numPages}) {
        setNumPages(numPages);
        setPageNumber(1);
    }


    return (
        <div className="relative bg-white">
            <Container>
                {data?.value?.content && !data?.value?.content.includes('pdfjs-viewer') ?
                    <div
                        dangerouslySetInnerHTML={{__html: data?.value?.content}}></div> : data?.value?.description && !data?.value?.description?.includes('pdfjs-viewer') ?
                        <div dangerouslySetInnerHTML={{__html: data?.value?.description}}></div> : null}
                {getApiKey() ?
                    <div
                        className="w-full flex flex-col md:flex-row justify-center items-center mt-14 rounded-3xl"
                        style={{
                            border: "1px solid #D8D8D8",
                            boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
                        }}
                    >
                        <p className="font-bold text-14 text-center px-4 md:px-0 pt-2 md:pt-0 md:text-16 md:mr-2 lg:mr-0 lg:text-h5">
                            Your membership allows you to access the full content
                        </p>
                        <div className="px-4 md:px-0 w-full md:w-48 py-4 lg:ml-10">
                            <Button filled onClick={() => {
                                upgradeMembership()
                            }}>
                                <p className="text-16 md:text-body-large font-normal">
                                    View Document
                                </p>
                            </Button>
                        </div>
                    </div>
                    : null}
                <div className="pt-12 custom:px-44 xl:pl-64">
                    <p className="text-h3 md:text-large-small custom:text-36 font-bold">
                        {slug}
                    </p>
                    <div className="pt-10 xl:w-9/12" id={'blurNote'}>
                        <p className="font-bold text-body-large md:text-h3"></p>
                        <div id={"blurNote"} className="pt-10  xl:w-9/12 mainPDF">
                            <p className="text-h3 md:text-large-small custom:text-36 font-bold"></p>
                            <Document
                                file={
                                    data?.item?.pdf_file
                                        ? data?.item?.pdf_file
                                        : data?.value?.pdf_file ? data?.value?.pdf_file : data?.pdf_file
                                }
                                onLoadSuccess={onDocumentLoadSuccess}
                            >
                                <Page scale={96 / 72} pageNumber={pageNumber}/>
                            </Document>
                            {/*blur box */}
                            <div className="blurBox">

                            </div>
                            {/* <Document
                file={data?.item ? data?.item?.pdf_file : data.pdf_file}
                onLoadSuccess={onDocumentLoadSuccess}
              >
                <Page
                  height="600"
                  pageNumber={pageNumber}
                  style={{ color: "#fff" }}
                  width={800}
                />
              </Document> */}
                            {/*<p> Page {pageNumber} of {numPages}</p>*/}
                            {/*{pageNumber > 1 &&*/}
                            {/*    <button*/}
                            {/*        className="bg-grey-500 hover:bg-gree-500 text-grey font-bold py-2 px-4 border border-blue-700 rounded" style={{cursor : "not-allowed"}} onClick={changePageBack}>Previous*/}
                            {/*        Page</button>*/}
                            {/*}*/}
                            {/*{*/}
                            {/*    pageNumber < numPages &&*/}
                            {/*    <button*/}
                            {/*        className="bg-grey-500 hover:bg-grey-500 text-grey font-bold py-2 px-4 border border-blue-700 rounded" style={{cursor : "not-allowed"}} disabled={true} onClick={changePageNext}>Next*/}
                            {/*        Page</button>*/}
                            {/*}*/}
                        </div>

                    </div>
                </div>
            </Container>
            <div
                className="h-776 absolute bottom-0 left-0 w-full bg-primary-bottom-bg bg-no-repeat bg-bottom"
                style={{backgroundColor: "background: rgba(255, 255, 255, 0.3)"}}
            >
                <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20 mt-550 md:mt-600">
                    {/*        <div className='w-10/12 custom:w-1/4 xl:w-80'>*/}
                    {/*            <Button filled>*/}
                    {/*                <p className='text-center text-14 xl:text-body-large'>Get All Contract Law <br /> Materials FOR ONLY £20</p>*/}
                    {/*            </Button>*/}
                    {/*        </div>*/}
                    {/*        <div>*/}
                    {/*            <p className='my-8 font-bold text-17 md:mx-5 xl:mx-7'>OR</p>*/}
                    {/*        </div>*/}
                    {!getApiKey() || loggedinUser?.subscription_name === 'FREE_PLAN' ?
                        <div className="w-10/12 custom:w-1/3 xl:w-96">
                            <Button filled onClick={() => upgradeMembership()}>
                                <p className="text-center text-14 xl:text-body-large">Get all the Study
                                    Materials <br/>for
                                    £7.5/month</p>
                            </Button>
                        </div> : null}
                </div>
            </div>
            <div className="bg-white bg-notes-bottom bg-no-repeat bg-bottom">
                <Container>
                    <div className="pt-28 pb-10 custom:pb-24">
                        <div className="flex flex-col items-center md:flex-row custom:justify-center md:px-20">
                            {/*<div className="w-10/12 custom:w-1/3 xl:w-96">*/}
                            {/*    <Button filled>*/}
                            {/*        <p className="text-center text-14 xl:text-body-large">Get All Contract*/}
                            {/*            Law <br/> Materials FOR ONLY £20</p>*/}
                            {/*    </Button>*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <p className="my-8 font-bold text-17 md:mx-5 xl:mx-7">OR</p>*/}
                            {/*</div>*/}
                            {!getApiKey() || loggedinUser?.subscription_name === 'FREE_PLAN' ?
                                <div className="w-10/12 custom:w-1/3 xl:w-96">
                                    <Button filled onClick={() => upgradeMembership()}>
                                        <p className="text-center text-14 xl:text-body-large">Get all the Study
                                            Materials <br/>for
                                            £7.5/month</p>
                                    </Button>
                                </div> : null}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default FreeUsers;
