import React from 'react'
import './index.css'
import PropTypes from "prop-types";

const DashboardCard = ({ image, altText, cardTitle, cardDescription, classNum, cardSub }) => {
    return (
        <div className='flex-1 bg-white study-card-container xl:h-80'>
            <div className='w-full md:h-44 xl:h-48'>
                <img src={image} alt={altText} className='w-full h-full' />
            </div>
            <div className="flex flex-row w-full justify-between p-5">
                <p className='text-h4 font-bold text-darkest title'>{cardTitle}</p>
                <p className='font-bold md:text-h5 xl:text-h5 text-primary'>{classNum}</p>
            </div>
            <div className='card-description-number px-5 pb-5'>
                <p className='text-body-large text-darkGrey desc'>{cardDescription}<span className="font-bold">{cardSub}</span></p>

            </div>

        </div>
    )
}

DashboardCard.propTypes = {
    altText: PropTypes.string,
    cardTitle: PropTypes.string,
    cardDescription: PropTypes.string,
    classNum: PropTypes.string
};

export default DashboardCard
