import React, {useState} from 'react';
import LazyImage from '../LazyImage';
import InfoBox from "../InfoBox";

const StudyMaterials = ({image, cardTitle, price, handleClick, slug, text, short_description}) => {
    const [showInfo, setShowInfo] = useState(false);

    return (

        <div className=" mx-auto bg-white w-11/12 md:w-11/12 lg:w-11/12 custom:w-11/12 xl:w-full xl:h-80"
        style={{
          boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.03)",
          borderRadius: "25px",
          cursor: "pointer",
          width: "100%",
        }} onClick={() => {
            if(slug?.value){
                handleClick(slug.value)
            }
            else{
                handleClick(slug)
            }
        }}
             onMouseEnter={() => setShowInfo(true)}
             onMouseLeave={() => setShowInfo(true)}>
            <div className="xl:h-60">
                <LazyImage src={image} alt="image"/>
            </div>
            <div className=" para-hid cardTitleSec text-center  py-2 px-2 flex items-center justify-between custom:px-6 custom:pt-6 md:pt-4 pb-4 md:px-4">
                <p className=" font-bold md:text-h5  text-center block w-3/5	">{cardTitle}</p>
                <p className="font-bold md:text-h5 xl:text-h5 text-primary w-3/12	" style={{flexShrink: 0}}>{price}</p>
            
            </div>
            {/* {showInfo && (
                <div className='absolute z-10 top-14 md:top-20'>
                    {slug?.value ? <InfoBox
                        text={slug?.value?.short_description}/> : <InfoBox
                        text={short_description}/>}

                </div>)} */}
        </div>
    );
};
export default StudyMaterials;
