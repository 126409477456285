import React, {memo} from 'react';
import AsyncSelect from 'react-select/async';
import ReactSelect, {components, createFilter} from 'react-select';
import ArrowDown from '../../assets/icons/select-arrow-down.svg';
import VirtualizedSelect from 'react-select-virtualized';
import './index.css';
import LoadingSpinner from '../Loader/LoadingSpinner';

const Select = ({placeholder, className = '', id, values, value, handleSelected, valueChange, noOptionMessage, loading}) => {

    const selectStyle = {
        control: (styles) => ({
            ...styles,
            backgroundColor: '#FFFFFF',
            borderRadius: '14px',
            height: '51px',
            paddingLeft: '10px',
            border: '1px solid #D8D8D8',
        }), placeholder: (styles) => ({
            ...styles, fontSize: '12px', color: '#B4B4B4',
        }),
    };

    const DropdownIndicator = props => {
        return (components.DropdownIndicator && (<components.DropdownIndicator {...props}>
            <img src={ArrowDown} alt="arrow-down" className="mr-7"/>
        </components.DropdownIndicator>));
    };

    return (<div className="">
            {values[0]?.value !== 'Any Tutor' ? <ReactSelect
                options={values}
                autoFocus={false}
                styles={selectStyle}
                value={id === undefined ? value : ''}
                isClearable={true}
                loadingMessage={() => <LoadingSpinner className={'loaderCenter'} color="#cc6828"/>}
                noOptionsMessage={() => noOptionMessage}
                isSearchable={true}
                isLoading={loading}
                placeholder={placeholder}
                onChange={handleSelected}
                onInputChange={valueChange}
                filterOption={createFilter({ignoreAccents: false})}
                components={{
                    DropdownIndicator, IndicatorSeparator: () => null,
                }}/> : <ReactSelect
                options={values}
                autoFocus={false}
                styles={selectStyle}
                isClearable={true}
                loadingMessage={() => <LoadingSpinner className={'loaderCenter'} color="#cc6828"/>}
                noOptionsMessage={() => 'Type to search'}
                isSearchable={true}
                isLoading={loading}
                placeholder={placeholder}
                onChange={handleSelected}
                onInputChange={valueChange}
                filterOption={createFilter({ignoreAccents: false})}
                components={{
                    DropdownIndicator, IndicatorSeparator: () => null,
                }}/>}

        </div>

    );
};

export default memo(Select);
