import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import LazyImage from "../../components/LazyImage";
import PlanCard from "../../components/SubscriptionPlanCard/index";
import Input from "../../components/SearchInput/index";
import Inputs from "../../components/Input/InputComponent";
import Button from "../../components/EmptyButton";
import SubsImage1 from "../../assets/images/subs-image-1.webp";
import SubsImage2 from "../../assets/images/subs-image-2.webp";
import playButton from "../../assets/icons/play-button.svg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "../../components/MainAdminSlider";
import SignupImage from "../../assets/images/signupImage.webp";
// import {FaStarHalfAlt} ".."
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { getUser, slugToText } from "../../helpers";
import { Link } from "react-router-dom";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import FacebookAuthentication from "../../components/SocialAuthentication/FacebookSocialAuth";
import { FaStar } from "react-icons/fa";
import InfoBox from "../../components/InfoBox/SmallInfobox";
import InfoImg from "../../assets/icons/information.svg";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";

import {
  couponVerifyAction,
  createErrorAlert,
  getSubscriptions,
  getUserInfo,
  subscribedPackageAction,
  updateUser,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import {
  couponVerifyData,
  couponVerifyError,
  subscribedPackageData,
  subscriptionData,
  subscriptionError,
  subscriptionLoading,
  updatedUserLoader,
  userData,
} from "../../redux/selectors";
import { connect, useDispatch } from "react-redux";
import VideoPlayer from "../../components/VideoPlayer";
import { Modal } from "react-responsive-modal";
import { getApiKey } from "../../helpers";
import { useHistory } from "react-router-dom";
import { Helmet } from "react-helmet";

const AnswerCard = ({ text, url }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div
      className="w-full h-24 bg-white mt-30 rounded-3xl flex items-center md:w-80 md:mr-5 lg:w-96 xl:w-1/3 cursor-pointer"
      style={styles.cardStyle}
      onClick={() => {
        setIsOpen(true);
      }}
    >
      <div
        className="
            min-w-44 
            min-h-44 
            max-w-44 
            max-h-44 
            ml-7 
            border 
            border-primary 
            rounded-full 
            flex 
            items-center 
            justify-center 
            cursor-pointer 
            md:min-w-48 
            md:min-h-48 
            md:max-w-48 
            md:max-h-48  
            xl:min-w-56 
            xl:min-h-56 
            xl:max-w-56 
            xl:max-h-56"
      >
        <LazyImage alt="play-button" src={playButton} />
      </div>
      <p className="ml-7 text-14 sm-text-16 font-bold lg:text-body-large">
        {text}
      </p>
      <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        focusTrapped={false}
        center
        classNames={{
          modal: "react-modal-class2",
        }}
      >
        <div
          className="w-full h-40 custom2:h-80 md:h-96 xl:h-96 relative z-20 xl:mt-1"
          style={{ borderRadius: "25px", zIndex: 3 }}
        >
          <div
            className="w-full h-40 md:h-96 xl:h-96  custom2:h-80 absolute top-2 md:top-5 overflow-hidden"
            style={{ borderRadius: "25px", zIndex: 1 }}
          >
            <VideoPlayer url={url} />
          </div>
        </div>
        {/*<div className='flex justify-center mb-10'>*/}
        {/*    /!*<img src={Success} alt='success'/>*!/*/}

        {/*    */}
        {/*</div>*/}
      </Modal>
    </div>
  );
};

const SubscriptionPage = (props) => {
  const {
    getSubscriptions,
    data,
    userInfo,
    getUserInfo,
    userSignUp,
    userLogin,
    subscribePackageData,
    subscribePackage,
    couponVerifyData,
    couponVerify,
    couponError,
  } = props;

  const dispatch = useDispatch();
  const history = useHistory();

  const [state, setState] = useState({
    subscriptions: [],
    slug: null,
    loading: false,
    activeTab: "panel-1",
    loginModal: false,
    code: localStorage.getItem("couponCode")
      ? localStorage.getItem("couponCode")
      : null,
    testimonials: [
      {
        text: "HIGHLY RECOMMEND!<br/> <b>I got a First Class and became a top achiever in EU law with SimpleStudying tutoring and study materials!</b> I used SimpleStudying materials to prepare for my assessments and got tutoring for personalised support. I had my first tutoring session with Neive and it was beyond my expectations! Not only does she teach in the most comprehensive manner but she is obviously a kind of a mind-reader - answering my questions even before I asked them. In addition, the lessons are very well organised and we had prompt and very effective communication with Neive and the office. They charge reasonable prices which are well worth every penny!  Thank you for your first-class service, Neive and SimpleStudying!",
        name: "Danni",
      },
      {
        text: "The service was efficient and professional. The general feedback in the one-on-one sessions and each tutorial was constructive, detailed, meaningful and generally effective in realising my goals. As a result, I successfully obtained a 1st and three 2.1 in my final assessments. Highly recommended.",
        name: "Ann",
      },
      {
        text: "Nino was very helpful with my studies. I got 1st because of her help! I couldn’t recommend SimpleStudying enough. Thank you so much!",
        name: "Amie",
      },
      {
        text: "I can confirm that since I discovered simple studying, life has almost become perfect for me. I find law module very easy to understand with the help of their tutoring sessions and study materials. I scored over 70 % in my Client Practice module last term.",
        name: "Akinyemi",
      },
    ],
    signupModal: false,
    showPassword: false,
    checkBox: false,
    couponID: null,
    couponPrice: null,
    showConfirmPassword: false,
    trialExpiredInfo: false,
    user: JSON.parse(getUser()),
  });

  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });

  let tab3 = document.getElementById("panel-3-btn");

  useEffect(() => {
    getSubscriptions();
    const items = document.querySelectorAll(".item ");

    for (let i = 0; i < items.length; i++) {
      items[i].addEventListener("click", function () {
        this.classList.toggle("open");
      });
    }
    if (tab3) {
      tab3.click();
    }
  }, [tab3]);
  const tab = document.querySelectorAll(".tab");
  const panel = document.querySelectorAll(".tab-content");

  function onTabClick(event) {
    for (let i = 0; i < tab.length; i++) {
      tab[i].classList.remove("active");
    }

    for (let i = 0; i < panel.length; i++) {
      panel[i].classList.remove("active");
    }

    event.target.classList.add("active");
    let classString = event.target.getAttribute("data-target");
    console.log(classString);
    document
      .getElementById("panels")
      .getElementsByClassName(classString)[0]
      .classList.add("active");
  }

  for (let i = 0; i < tab.length; i++) {
    tab[i].addEventListener("click", onTabClick, false);
  }
  //

  useEffect(() => {
    if (getApiKey()) {
      getUserInfo();
    }
  }, []);

  useEffect(() => {
    if (subscribePackageData?.data?.checkout_url) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      window.location.replace(subscribePackageData.data.checkout_url);
    }
  }, [subscribePackageData]);

  useEffect(() => {
    if (couponVerifyData?.data?.message) {
      dispatch(createErrorAlert(200, "Success", "Coupon is Valid", 200));
      setState((prevState) => ({
        ...prevState,
        couponID: couponVerifyData.data.id,
        couponPrice: couponVerifyData.data.coupon_dicount_price,
      }));
      localStorage.setItem("couponCode", state.code);
    }
  }, [couponVerifyData]);
  useEffect(() => {
    if (couponError?.data?.message) {
      dispatch(createErrorAlert(400, "Error", couponError.data.message, 400));
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      return;
    }
  }, [couponError]);

  const forgetPass = () => {
    setState((prevState) => ({
      ...prevState,
      loginModal: false,
    }));
    history.push("/forget-password");
  };

  const handleUserLogin = async (body) => {
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    if (state.checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };
  const checked = (e) => {
    if (e === true) {
      setState((prevState) => ({
        ...prevState,
        checkBox: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        checkBox: false,
      }));
    }
  };

  useEffect(() => {
    if (data?.length) {
      setState((prevState) => ({
        ...prevState,
        subscriptions: data,
      }));
      data.map((item) => (item.is_active = false));
    }
    if (userInfo) {
      if (data.length) {
        for (let i = 0; i <= data.length; i++) {
          if (data[i]?.id === userInfo?.subscription_ids) {
            data[i].is_active = true;
          }
        }
        setState((prevState) => ({
          ...prevState,
          subscriptions: data,
        }));
      }
    }
  }, [data, userInfo]);

  const upgrade = async () => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    if (
      state.user?.subscription_name === "Basic Subscription" &&
      state.slug === undefined &&
      getApiKey()
    ) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      dispatch(createErrorAlert(400, "Error", "Select any Package", 400));
    } else if (
      state.user?.subscription_name === slugToText(state.slug) &&
      getApiKey()
    ) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      dispatch(
        createErrorAlert(
          400,
          "Error",
          "You already have this subscription. Please select another one if you want to change your subscription",
          400
        )
      );
    } else {
      if (getApiKey()) {
        subscribePackage({ plan: state.slug, coupon_id: state.couponID });
      } else if (!getApiKey()) {
        setState((prevState) => ({
          ...prevState,
          loading: false,
        }));
        history.push("/signup");
      }
    }
  };

  const subClick = async (slug, stripe_price_id, item) => {
    let tes = [...state.subscriptions];
    tes.map((it) => {
      if (it.id === item.id) {
        it.is_active = true;
      } else {
        it.is_active = false;
      }
    });
    setState((prevState) => ({
      ...prevState,
      subscriptions: tes,
      slug: slug,
    }));
  };

  const codeVerify = async () => {
    couponVerify({ coupon_code: state.code });
  };

  const subcriptionConditionRenderMonthly = (sub) => {
    return (
      sub?.name !== "Basic Subscription" &&
      sub.name !== "FREE_PLAN" &&
      sub?.name !==
        "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" &&
      sub?.name !==
        "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)" &&
      sub?.name !== "GET COVERED FOR THE EXAM PERIOD" &&
      sub?.name !== "IDEAL IF YOU AIM FOR A FIRST CLASS"
    );
  };

  const subcriptionConditionRenderQuarter = (sub) => {
    return (
      sub?.name !== "Basic Subscription" &&
      sub.name !== "FREE_PLAN" &&
      sub?.name !== "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)" &&
      sub?.name !==
        "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)" &&
      sub?.name !== "QUICK BOOST OF YOUR GRADES" &&
      sub?.name !== "IDEAL IF YOU AIM FOR A FIRST CLASS"
    );
  };

  const subcriptionConditionRenderAnnual = (sub) => {
    return (
      sub?.name !== "Basic Subscription" &&
      sub.name !== "FREE_PLAN" &&
      sub?.name !== "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)" &&
      sub?.name !==
        "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)" &&
      sub?.name !== "QUICK BOOST OF YOUR GRADES" &&
      sub?.name !== "GET COVERED FOR THE EXAM PERIOD"
    );
  };

  const handleTabClick = (tab) => {
    if (state.activeTab !== tab) {
      setState((prevState) => ({
        ...prevState,
        activeTab: tab,
      }));
    }
  };

  const headingHandle = (subscription) => {
    if (subscription?.subscription_name !== "Basic Subscription") {
      if (subscription?.subscription_name === "QUICK BOOST OF YOUR GRADES") {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name === "GET COVERED FOR THE EXAM PERIOD"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name === "IDEAL IF YOU AIM FOR A FIRST CLASS"
      ) {
        return "PREMIUM ALL INCLUSIVE SUBSCRIPTION WITH ANNUAL BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (MONTHLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH MONTHLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (QUARTERLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH QUARTERLY BILLING";
      } else if (
        subscription?.subscription_name ===
        "STARTER PACK FOR IMPROVING YOUR GRADES (ANNUALLY BILLED)"
      ) {
        return "BASIC STARTER PACK SUBSCRIPTION WITH ANNUAL BILLING";
      }
    } else {
      return "Free Subscription";
    }
  };

  return (
    <div className="mt-10 bg-white">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Subscriptions</title>
        <meta name="description" content="Subscriptions Description" />
        <link rel="canonical" href="https://simplestudying.com/subscription" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Subscriptions" />
        <meta property="og:description" content="Subscriptions Description" />
        <meta
          property="og:url"
          content="https://simplestudying.com/subscription"
        />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <div className=" px-4 md:px-6 lg:px-8 bg-offWhite custom2:px-56 custom:px-56 ">
        {state.user?.subscription_name === "Basic Subscription" ? (
          <div>
            <p className="text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
              Upgrade your subscription
            </p>
            <div className="infoAboutMain">
              {state.user?.subscription_name === "Basic Subscription" ? (
                <p>You currently have a free subscription</p>
              ) : state.user?.subscription_name !==
                "Basic Subscription" ? null : state.user?.subscription_name.includes(
                  "STARTER"
                ) ? (
                <p>You currently have a basic starter pack subscription</p>
              ) : null}

              <div className="FreeTrailBtnSec">
                <div className="upgrade-toolTipMain ">
                  <img
                    src={InfoImg}
                    alt="info-img"
                    className="mr-1 w-16 md:w-24 custom:w-5 custom:mr-4"
                    onMouseEnter={() =>
                      setState((prevState) => ({
                        ...prevState,
                        trialExpiredInfo: true,
                      }))
                    }
                    onMouseLeave={() =>
                      setState((prevState) => ({
                        ...prevState,
                        trialExpiredInfo: false,
                      }))
                    }
                  />
                  {state.trialExpiredInfo && (
                    <div className=" tooltipSSec free-tool">
                      <InfoBox
                        text={
                          state.user?.subscription_name === "Basic Subscription"
                            ? "The free subscription lasts forever. However, it only allows you to see 60% of the case summaries and overview of the key legal principles. You can unlock it for just £1.99/month to improve your grades and save on average 738 hours of reading time per year compared to the textbooks <br/> <br/> Alternatively, get the whole package for maximising your chances of getting a First Class for just £10/month."
                            : state.user?.subscription_name.includes("STARTER")
                            ? "Starter pack for improving your grades gives you access to our short notes and case summaries only. You don’t have access to the full notes that contain the crucial information from First Class law graduates to maximise your chances of getting a First Class law degree. You also don’t have access to our example essays of 2.1 and First Class level, tutorial videos, quizzes and flashcards. If you want unlimited access to all the study materials, you should upgrade for just £10/month."
                            : null
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      {headingHandle(state.user) !== "Free Subscription" ? (
        <div className=" bg-subscription-upper bg-white bg-contain bg-no-repeat md:bg-offWhite pt-10 lg:pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
          <p className="text-h5 md:text-h4 xl:text-h3">
            {headingHandle(state.user)}
          </p>
        </div>
      ) : null}
      <div className=" bg-subscription-upper bg-white bg-contain bg-no-repeat md:bg-offWhite pt-10 lg:pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
        <p className="text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
          Choose a plan
        </p>
      </div>

      <div className=" md:bg-subscription-upper bg-contain bg-no-repeat xl:bg-contain px-4 md:px-6 lg:px-8 custom:px-56 custom2:px-56 xl:px-60">
        <div className="subTabsMain">
          <div class="SubPckgeBoxMain">
            <nav className="tabs flex flex-col sm:flex-row justify-center">
              <button
                data-target="panel-1"
                className={`tab monthTab ${
                  state.activeTab === "panel-1" ? "active" : ""
                } text-gray-600 py-2 px-3 md:py-4 md:px-6 block hover:text-blue-500 focus:outline-none`}
                onClick={() => handleTabClick("panel-1")}
              >
                <p
                  onClick={() => handleTabClick("panel-1")}
                  data-target="panel-1"
                >
                  Monthly
                </p>
                <span
                  onClick={() => handleTabClick("panel-1")}
                  data-target="panel-1"
                  className="font-bold"
                >
                  Save 0%
                </span>
              </button>
              <button
                data-target="panel-2"
                className={`tab quartrTab ${
                  state.activeTab === "panel-2" ? "active" : ""
                } text-gray-600 py-2 px-3 md:py-4 md:px-6 block hover:text-blue-500 focus:outline-none`}
                onClick={() => handleTabClick("panel-2")}
              >
                <p
                  onClick={() => handleTabClick("panel-2")}
                  data-target="panel-2"
                >
                  Quarterly
                </p>
                <span
                  onClick={() => handleTabClick("panel-2")}
                  data-target="panel-2"
                  className="font-bold"
                >
                  Save 16%
                </span>
              </button>
              <button
                data-target="panel-3"
                id="panel-3-btn"
                className={`tab annualTab ${
                  state.activeTab === "panel-3" ? "active" : ""
                } text-gray-600 py-2 px-3 md:py-4 md:px-6 block hover:text-blue-500 focus:outline-none`}
                onClick={() => handleTabClick("panel-3")}
              >
                <p
                  onClick={() => handleTabClick("panel-3")}
                  data-target="panel-3"
                >
                  Annual
                </p>
                <span
                  onClick={() => handleTabClick("panel-3")}
                  data-target="panel-3"
                  className="themeClr"
                >
                  (Best value)
                </span>
                <span
                  onClick={() => handleTabClick("panel-3")}
                  data-target="panel-3"
                  className="font-bold"
                >
                  {" "}
                  Save 25%
                </span>
              </button>
            </nav>
          </div>

          <div id="panels">
            <div className="panel-1 tab-content active py-5">
              <div className=" pt-4 md:pt-10  subpackgMain">
                {state.subscriptions.map((sub, index) => {
                  let pricePerMonth = (
                    sub?.membership_price / sub?.duration
                  ).toFixed(2);
                  let realPrice = (sub?.duration * 10).toFixed(2);
                  let realSort = sub?.sort;
                  if (index === 0) {
                    let selected = true;
                  }
                  if (sub?.sort === 0) {
                    realSort = 2;
                  } else if (sub?.sort === 3) {
                    realSort = 1;
                  }
                  let percentage = (100 - (pricePerMonth / 10) * 100).toFixed(
                    2
                  );
                  if (sub?.duration === 0) {
                    pricePerMonth = "0.00";
                    realPrice = "0.00";
                    percentage = "0.00";
                  }
                  if (state.user?.subscription_name === sub?.name) {
                    sub.is_active = true;
                  }

                  return (
                    <div
                      className={
                        subcriptionConditionRenderMonthly(sub)
                          ? "subBoxMain"
                          : "hidden"
                      }
                    >
                      {subcriptionConditionRenderMonthly(sub) ? (
                        <PlanCard
                          key={index}
                          price={sub?.membership_price}
                          id={sub?.id}
                          total={sub?.total_price}
                          description={sub?.description}
                          headline={
                            <p className="text-h5 lg:text-h4 font-bold custom2:text-body-large xl:text-h3">
                              {sub?.name}
                            </p>
                          }
                          image={sub?.sort === 0 ? SubsImage1 : SignupImage}
                          value={
                            sub?.name === "QUICK BOOST OF YOUR GRADES"
                              ? "Billed Monthly"
                              : sub?.name === "GET COVERED FOR THE EXAM PERIOD"
                              ? `Billed Quarterly `
                              : sub?.name ===
                                "IDEAL IF YOU AIM FOR A FIRST CLASS"
                              ? `Billed Annually`
                              : null
                          }
                          packageDetails={[]}
                          best={sub?.sort === 0}
                          index={index}
                          handleClick={() =>
                            subClick(sub?.slug, sub.stripe_price_id, sub)
                          }
                          selected={sub.is_active}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
              {/* monthly package cont sec end */}
            </div>
            <div className="panel-2 tab-content py-5">
              {/* Quartly package cont sec  */}
              <div className=" pt-10 md:grid  md:grid-cols-3 md:justify-items-center xl:flex xl:justify-between subpackgMain">
                {state.subscriptions.map((sub, index) => {
                  let pricePerMonth = (
                    sub?.membership_price / sub?.duration
                  ).toFixed(2);
                  let boost = 20;
                  let covered = 30;
                  let best = 160;
                  let realPrice = (sub?.duration * 10).toFixed(2);
                  let realSort = sub?.sort;
                  if (index === 0) {
                    let selected = true;
                  }
                  if (sub?.sort === 0) {
                    realSort = 2;
                  } else if (sub?.sort === 3) {
                    realSort = 1;
                  }
                  let percentage = (100 - (pricePerMonth / 10) * 100).toFixed(
                    2
                  );
                  if (sub?.duration === 0) {
                    pricePerMonth = "0.00";
                    realPrice = "0.00";
                    percentage = "0.00";
                  }

                  return (
                    <div
                      className={
                        subcriptionConditionRenderQuarter(sub)
                          ? "subBoxMain"
                          : "hidden"
                      }
                    >
                      {subcriptionConditionRenderQuarter(sub) ? (
                        <PlanCard
                          key={index}
                          price={sub?.membership_price}
                          id={sub?.id}
                          total={sub?.total_price}
                          description={sub?.description}
                          headline={
                            <p className="text-h5 lg:text-h4 font-bold custom2:text-body-large xl:text-h3">
                              {sub?.name}
                            </p>
                          }
                          image={sub?.sort === 0 ? SubsImage1 : SubsImage2}
                          value={
                            sub?.name === "QUICK BOOST OF YOUR GRADES"
                              ? "Billed Monthly"
                              : sub?.name === "GET COVERED FOR THE EXAM PERIOD"
                              ? `Billed Quarterly `
                              : sub?.name ===
                                "IDEAL IF YOU AIM FOR A FIRST CLASS"
                              ? `Billed Annually`
                              : null
                          }
                          packageDetails={[]}
                          best={sub?.sort === 0}
                          index={index}
                          handleClick={() =>
                            subClick(sub?.slug, sub.stripe_price_id, sub)
                          }
                          selected={sub.is_active}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
              {/* Quartly package cont sec end */}
            </div>
            <div className="panel-3 tab-content py-5">
              {/* Annual package cont sec  */}
              <div className=" pt-10 md:grid  md:grid-cols-3 md:justify-items-center xl:flex xl:justify-between subpackgMain">
                {state.subscriptions.map((sub, index) => {
                  let pricePerMonth = (
                    sub?.membership_price / sub?.duration
                  ).toFixed(2);
                  let boost = 20;
                  let covered = 30;
                  let best = 160;
                  let realPrice = (sub?.duration * 10).toFixed(2);
                  let realSort = sub?.sort;
                  if (index === 0) {
                    let selected = true;
                  }
                  if (sub?.sort === 0) {
                    realSort = 2;
                  } else if (sub?.sort === 3) {
                    realSort = 1;
                  }
                  let percentage = (100 - (pricePerMonth / 10) * 100).toFixed(
                    2
                  );
                  if (sub?.duration === 0) {
                    pricePerMonth = "0.00";
                    realPrice = "0.00";
                    percentage = "0.00";
                  }

                  return (
                    <div
                      className={
                        subcriptionConditionRenderAnnual(sub)
                          ? "subBoxMain"
                          : "hidden"
                      }
                    >
                      {subcriptionConditionRenderAnnual(sub) ? (
                        <PlanCard
                          key={index}
                          price={sub?.membership_price}
                          id={sub?.id}
                          total={sub?.total_price}
                          description={sub?.description}
                          headline={
                            <p className="text-h5 lg:text-h4 font-bold custom2:text-body-large xl:text-h3">
                              {sub?.name}
                            </p>
                          }
                          image={sub?.sort === 0 ? SubsImage1 : SubsImage2}
                          value={
                            sub?.name === "QUICK BOOST OF YOUR GRADES"
                              ? "Billed Monthly"
                              : sub?.name === "GET COVERED FOR THE EXAM PERIOD"
                              ? `Billed Quarterly `
                              : sub?.name ===
                                "IDEAL IF YOU AIM FOR A FIRST CLASS"
                              ? `Billed Annually`
                              : null
                          }
                          packageDetails={[]}
                          best={sub?.sort === 0}
                          index={index}
                          handleClick={() =>
                            subClick(sub?.slug, sub.stripe_price_id, sub)
                          }
                          selected={sub.is_active}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  );
                })}
              </div>
              {/* Annual package cont sec end */}
            </div>
          </div>
        </div>
        {/* sub tab sec end */}
      </div>
      <div className="px-4 md:px-6 lg:px-8 custom2:px-60  custom:px-56 xl:px-60 ">
        {/*<div className="w-full mt-20 text-center">*/}
        {/*    <p className="text-h5 font-bold">*/}
        {/*        We offer discount code for long term subscriptions. Please, contact*/}
        {/*        us if you are interested*/}
        {/*    </p>*/}
        {/*    <p className="mt-7 text-primary text-h5 font-bold underline cursor-pointer lg:text-h5"*/}
        {/*       onClick={() => {*/}
        {/*           history.push('/contact-us')*/}
        {/*       }}>*/}
        {/*        Contact us*/}
        {/*    </p>*/}
        {/*</div>*/}
        <div className="subPlanSubCont mt-4 md:mt-14 lg:mt-20 text-center text-16 md:text-left lg:text-body-large">
          <p>
            Before applying a discount code, please select a membership plan.
          </p>
          <p className="mt-2 lg:mt-6">
            If you have a discount code, please enter it here
          </p>
          <div className="applyInputSec mt-3.5 md:w-3/6 lg:w-2/5 custom:w-2/6 xl:w-3/12 rounded-2xl">
            <Input
              placeholder="Discount code"
              text="Apply"
              value={state.code}
              setValue={(value) => {
                setState((prevState) => ({
                  ...prevState,
                  code: value,
                }));
              }}
              handleClick={codeVerify}
            />
            {/* {code === 'Welcome10' || code === 'comeback20' ? <p className="text-primary">Token is Applied..!</p> : null} */}
          </div>
        </div>
      </div>
      <div className=" bottom-subscription  bg-subscription-bottom bg-no-repeat px-4 md:px-6 custom2:px-60 lg:px-24">
        <div className="mt-8 md:mt:50  pt-4 md:pt-8 lg:pt-16 w-full md:flex md:justify-center">
          {getApiKey() ? (
            <div className="upgrateBtnBox md:w-64 xl:w-64 ">
              <Button
                text="Upgrade subscription"
                filled
                loading={state.loading}
                disabled={
                  (state.user?.subscription_name === "Basic Subscription" &&
                    state.slug === undefined &&
                    getApiKey()) ||
                  state.slug === undefined
                }
                onClick={() => {
                  upgrade();
                }}
              />
            </div>
          ) : (
            <div className="upgrateBtnBox md:w-56 xl:w-64 ">
              <Button
                text="Login"
                filled
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    loginModal: true,
                  }));
                }}
              />
            </div>
          )}
        </div>
      </div>

      {/* testimonials slider section  */}
      <div className="  bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
        {/* <p className="mb-12 text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
          Testimonials
        </p> */}

        <div className="testiSliderMain">
          <Slider
            responsive={{
              superLargeDesktop: {
                // the naming can be any, depends on you.
                breakpoint: { max: 4000, min: 1919 },
                items: 1,
              },
              desktop: {
                breakpoint: { max: 1919, min: 1500 },
                items: 1,
              },
              ipadPro: {
                breakpoint: { max: 1500, min: 1024 },
                items: 1,
              },
              tablet: {
                breakpoint: { max: 1023, min: 768 },
                items: 1,
              },
              tabletBelow: {
                breakpoint: { max: 767, min: 465 },
                items: 1,
              },
              mobile: {
                breakpoint: { max: 464, min: 0 },
                items: 1,
              },
            }}
          >
            {state.testimonials?.map((item, index) => {
              return (
                <div
                  className="slide-content sm:w-3/4 md:w-1/2 mx-auto"
                  style={
                    item?.name === "Danni"
                      ? { height: "max-content" }
                      : { height: "250px" }
                  }
                >
                  <div class="rating-stars gap-3 flex justify-center">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    {/* <FaStarHalfAlt/> */}
                  </div>
                  <p
                    className="text-center my-2"
                    dangerouslySetInnerHTML={{ __html: item?.text }}
                  >
                    {/* “Nino was very helpful with my studies. I got 1st because of her help! I couldn’t recommend SimpleStudying enough. Thank you so much!” */}
                  </p>
                  <h5 className="text-center my-3">{item?.name}</h5>
                </div>
              );
            })}
          </Slider>
        </div>

        {/* <p className="text-center mt-12 testiFooterText">
          More testimonials you can find{" "}
          <a href="https://www.reviews.co.uk/company-reviews/store/www.simplestudying.com">
            here
          </a>
        </p> */}
      </div>

      <div className=" faqsMain bg-white bg-contain bg-no-repeat pt-10 lg:pt-20 px-4 md:px-6 lg:px-8 custom2:px-56 custom:px-56 xl:px-60">
        <p className="mb-12 text-h5 md:text-h4 font-bold text-darkest xl:text-h3">
          Frequently Asked Questions
        </p>

        <div className="accordion">
          <div className="item ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
            <p className="text" style={{ fontWeight: "bold" }}>
              1. What is the difference between the premium subscriptions?
            </p>

            <div className="hidden-box">
              <p>
                Premium subscriptions differ in total duration. One month
                subscription is ideal for a little boost of your grades quickly.
              </p>
              <p>
                The quarterly subscription is usually purchased by students who
                just want to study during exam period to get a 2.1.
              </p>
              <p className="font-700">
                <b>
                  Students who aim for a First Class are regular learners who
                  purchase the annual subscription to use the study materials
                  almost every day.
                </b>
              </p>
            </div>
          </div>

          <div className="item ">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
            <p class="text" style={{ fontWeight: "bold" }}>
              2. When can I cancel my subscription?
            </p>

            <div className="hidden-box">
              <p>
                Your subscription will be subject to automatic renewal. You can
                cancel anytime easily from your profile. You will get automatic
                renewal reminders by email so you don't forget to cancel if you
                want to. However, we suggest not to cancel and use our materials
                every day to prepare for your assessments.
              </p>
            </div>
          </div>

          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
            <p className="text" style={{ fontWeight: "bold" }}>
              3. Do I still need to read something else with SimpleStudying
              membership?
            </p>

            <div className="hidden-box">
              <p>
                Our study materials are designed specifically to give you the
                best chances of achieving a First Class in the simplest way
                possible. Please consider that this is the case for English law
                and universities in England and Wales. If you are studying law
                in another country, it is your responsibility to check the
                relevancy of the content. We've had students from the US and
                Europe who successfully used our materials to get high grades in
                international law and EU law.
              </p>
              <p>
                Most likely, you will not need to do any additional reading from
                textbooks or case law research unless you are doing
                comprehensive research on a niche area of law that we do not
                cover in detail.
              </p>
              <p style={{ color: "red" }}>
                On average, SimpleStudying will save you 738 hours of reading
                time per year compared to textbooks. That's 2213 LESS pages to
                read per year! All this for just £10/month.
              </p>
              <p>
                You still need to read your lecture materials and follow your
                professor’s advice. Your lectures + our materials & tutoring
                should be your key to the First Class law degree.
              </p>
            </div>
          </div>
          <div className="item">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="icon"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
            <p className="text" style={{ fontWeight: "bold" }}>
              4. Do I need more tutoring than what is included?
            </p>

            <div className="hidden-box">
              <p>
                Annual membership only has 1-hour free tutoring session included
                worth £40. Other memberships do not include any tutoring time.
                So you will need to book it separately. Tutoring is a one-to-one
                personalised support from our tutor to help you achieve your
                full potential. However, most of the students need at least 6
                tutoring sessions to learn how to get high grades. Most of the
                students who got a First Class had at least 6 tutoring sessions.
                On tutoring, you’ll learn how to do legal research, how to make
                arguments, how to do IRAC structure etc.
              </p>
              <p>
                You will be able to book tutoring separately from your
                SimpleStudying account.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className=" bottom-subscription  bg-subscription-bottom bg-no-repeat px-4 md:px-6 custom2:px-60 lg:px-24">
        <div className="mt-8 md:mt:50  pt-4 md:pt-8 lg:pt-16 w-full md:flex md:justify-center">
          {getApiKey() ? (
            <div className="upgrateBtnBox md:w-56 xl:w-64 ">
              <Button
                text="Upgrade subscription"
                filled
                disabled={
                  (state.user?.subscription_name === "Basic Subscription" &&
                    state.slug === undefined &&
                    getApiKey()) ||
                  state.slug === undefined
                }
                onClick={() => {
                  upgrade();
                }}
              />
            </div>
          ) : (
            <div className="upgrateBtnBox md:w-56 xl:w-64 ">
              <Button
                text="Login"
                filled
                onClick={() => {
                  setState((prevState) => ({
                    ...prevState,
                    loginModal: true,
                  }));
                }}
              />
            </div>
          )}
        </div>
        <div className="instrSec mt-10 md:mt-50 lg:mt-70 text-center text-15">
          <p>
            Experiencing any issues or need some help with upgrading? Please{" "}
            <span
              className="text-primary underline cursor-pointer"
              onClick={() => {
                history.push("/contact-us");
              }}
            >
              contact us
            </span>
          </p>
        </div>
        <Modal
          open={state.loginModal}
          onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="flex gap-6">
            <div className="w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">20K savvy students</span>{" "}
                already empowered - and you're next
              </p>

              <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                <div className=" text-center">
                  <div className="w-116 h-100">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="  fw-bold text-42 text-yellowish ">35+</p>
                  <p>
                    Study Modules
                    <br />
                    All main areas of law
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">500K+</p>
                  <p>
                    Users
                    <br />
                    Every Year
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-150 h-150">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">1430+</p>
                  <p>
                    Hours Tutoring <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <Formik
                initialValues={{
                  password: "",
                  username: "",
                }}
                validationSchema={validateSchema}
                onSubmit={(values) => handleUserLogin(values)}
              >
                {({ isValid, handleSubmit, values }) => (
                  <Form>
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <div className="loginInputMain flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign in to{" "}
                          <span className="text-yellowish">SimpleStudying</span>
                        </p>
                        <div className="w-full mb-3 custom2::mb-9 ">
                          <p className="mb-8">Username or Email</p>
                          <Field
                            name="username"
                            as={Input}
                            autoComplete="off"
                            placeholder="Enter your username or email"
                            type="text"
                          />
                        </div>
                        <div className="w-full  mb-3 custom2:mb-9">
                          <p className="mb-8 ">Password</p>
                          <Field
                            name="password"
                            as={Input}
                            placeholder="Enter your password"
                            type={state.showPassword ? "text" : "password"}
                            setShowPassword={setState}
                            showPassword={state.showPassword}
                            inputType="password"
                            autoComplete="off"
                          />
                        </div>
                        <Link
                          className="mb-8 forgetText "
                          onClick={() => {
                            setState((prev) => ({
                              ...prev,
                              loginModal: false,
                            }));
                            history.push("/forget-password");
                          }}
                        >
                          Forget Password?
                        </Link>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h:34 md:h-42 lg:h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Log in"
                            filled
                            className="buttonClass"
                            disabled={
                              values.username === "" ||
                              values.password === "" ||
                              !isValid
                            }
                            loading={state.loader}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
              <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                You don't have an account yet?{" "}
                <a
                  className="font-bold text-primary cursor-pointer"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      loginModal: false,
                      signUpModal: true,
                    }));
                  }}
                >
                  {"  "}Sign up
                </a>
              </p>
            </div>
          </div>
        </Modal>

        {/* Sign Up Modal */}

        <Modal
          open={state.signUpModal}
          onClose={() =>
            setState((prev) => ({
              ...prev,
              signUpModal: false,
            }))
          }
          focusTrapped={false}
          center
          classNames={{
            overlay: "custom-overlay-class",
            modal: "react-modal-class1",
          }}
        >
          <div className="flex gap-6">
            <div className="w-full hidden md:block">
              <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                Join the movement of{" "}
                <span className="text-yellowish">20K savvy students</span>{" "}
                already empowered - and you're next
              </p>

              <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                Discover the secret to success as you become part of our growing
                community.
              </p>
              <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                <div className=" text-center">
                  <div className="w-116 h-100 mb-6">
                    <img src={img1} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="  fw-bold text-42 text-yellowish ">35+</p>
                  <p>
                    Study Modules
                    <br />
                    All main areas of law
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-100 h-100">
                    <img src={img2} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">500K+</p>
                  <p>
                    Users
                    <br />
                    Every Year
                  </p>
                </div>
                <div className=" text-center">
                  <div className="w-100 h-100">
                    <img src={img3} className="m-auto mt-3 w-full h-full" />
                  </div>
                  <p className="fw-bold text-42  text-yellowish">1430+</p>
                  <p>
                    Hours Tutoring <br />
                    Delivered
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full">
              <Formik
                initialValues={{
                  password1: "",
                  username: "",
                  email: "",
                  password2: "",
                }}
                validationSchema={validateSchemaSignup}
                onSubmit={(values) => onSignUp(values)}
              >
                {({ isValid, handleSubmit, values }) => (
                  <Form>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="flex flex-col text-darkest items-center">
                        <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                          Sign up to{" "}
                          <span className="text-yellowish">SimpleStudying</span>
                        </p>
                        <div className="w-full mb-3 md:mb-9 ">
                          <p className="mb-8">Email</p>
                          <Field
                            name="email"
                            as={Input}
                            placeholder="Enter your email"
                            type="text"
                          />
                        </div>

                        <div className="w-full mb-3 md:mb-9 ">
                          <p className="mb-8">Username</p>
                          <Field
                            name="username"
                            as={Input}
                            placeholder="Enter your username"
                            type="text"
                          />
                        </div>

                        <div className="w-full ">
                          <p className="mb-8">Password</p>
                          <Field
                            name="password1"
                            as={Input}
                            placeholder="Enter your password"
                            type={state.showPassword ? "text" : "password"}
                            setShowPassword={setState}
                            showPassword={state.showPassword}
                            inputType="password"
                          />
                          <p className="text-small text-darkGrey2 my-8">
                            6+ characters, 1 capital letter{" "}
                          </p>
                        </div>
                        <div className="w-full mb-10 md:mb-14">
                          <p className="mb-8">Confirm password</p>
                          <Field
                            name="password2"
                            as={Input}
                            placeholder="Enter your confirm password"
                            type={
                              state.showConfirmPassword ? "text" : "password"
                            }
                            setShowPassword={setState}
                            showPassword={state.showConfirmPassword}
                            inputType="password"
                          />
                        </div>
                        <div
                          className="w-full mb-10 md:mb-14"
                          style={{ display: "flex" }}
                        >
                          <label className="condContainer">
                            {" "}
                            I agree to{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/terms-conditions");
                              }}
                            >
                              Terms and Conditions
                            </a>
                            ,{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/privacy-policy");
                              }}
                            >
                              Privacy Policy
                            </a>{" "}
                            and{" "}
                            <a
                              style={{ color: "#cc6828", cursor: "pointer" }}
                              onClick={() => {
                                history.push("/cookies-policy");
                              }}
                            >
                              Cookies Policy
                            </a>{" "}
                            of Simple Studying{" "}
                            <input
                              id="condBox"
                              onClick={(e) => checked(e.target.checked)}
                              type="checkbox"
                              className="default:ring-2 text-primary mr-3 checkBoxOne"
                            />
                            <span className="checkmark"></span>
                          </label>
                        </div>
                      </div>
                      <div className="pt-4 flex justify-center mb-10">
                        <div className="w-full h-51">
                          <Button
                            onClick={handleSubmit}
                            text="Sign up for free"
                            filled
                            className="buttonClass"
                            disabled={
                              values.username === "" ||
                              values.password1 === "" ||
                              values.password2 === "" ||
                              values.email === "" ||
                              !isValid ||
                              values.checked === "false"
                            }
                            loading={state.loader}
                          />
                        </div>
                      </div>
                    </form>
                  </Form>
                )}
              </Formik>
              <p className="font-normal text-darkest font-body-large text-center">
                Already have an account?{" "}
                <a
                  className="font-bold text-primary cursor-pointer"
                  onClick={() => {
                    setState((prev) => ({
                      ...prev,
                      loginModal: true,
                      signUpModal: false,
                    }));
                  }}
                >
                  {"  "}Log in
                </a>
              </p>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

const styles = {
  cardStyle: {
    boxShadow: "4px 8px 20px rgba(2, 23, 54, 0.05)",
  },
  playButton: {
    borderRadius: "50%",
  },
};

AnswerCard.propTypes = {
  text: PropTypes.string,
};

const mapStateToProps = (state) => ({
  loading: subscriptionLoading(state),
  error: subscriptionError(state),
  data: subscriptionData(state),
  loadingUser: updatedUserLoader(state),
  userInfo: userData(state),
  subscribePackageData: subscribedPackageData(state),
  couponVerifyData: couponVerifyData(state),
  couponError: couponVerifyError(state),
});
const mapDispatchToProps = (dispatch) => ({
  updateUser: (body) => dispatch(updateUser(body)),
  getUserInfo: () => dispatch(getUserInfo()),
  getSubscriptions: () => dispatch(getSubscriptions()),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  subscribePackage: (body) => dispatch(subscribedPackageAction(body)),
  couponVerify: (body) => dispatch(couponVerifyAction(body)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPage);
