import React, { useEffect, useState } from "react";
import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import { parseISO, formatDistanceToNow } from "date-fns";
import checkmark from "../../assets/images/Checkmark.svg";
import pkgImg from "../../assets/images/packageImg.png";
import img1 from "../../assets/newImages/Group 1399.png"
import img3 from "../../assets/newImages/Group 1403.png"
import img2 from "../../assets/newImages/Illustration.png"
import { useHistory } from "react-router-dom";
import {
  cancelAppointmentData,
  confirmAppointmentData,
  tutoringData,
  tutoringError,
  tutoringLoading,
} from "../../redux/selectors";
import {
  confirmAppointmentAction,
  getTutoring,
  userLogin,
  userSignUp,
} from "../../redux/actions";
import { connect, useDispatch } from "react-redux";
import { Helmet } from "react-helmet";
import { apiRequest, getApiKey } from "../../helpers";
import { Modal } from "react-responsive-modal";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Inputs from "../../components/Input/InputComponent";
import { Link } from "react-router-dom";
import { createErrorAlert } from "../../redux/actions";
import { cancelAppointmentAction } from "../../redux/actions/cancelAppointmentAction";
import FullLoader from "../../components/Loader/FullLoader";

const TutoringServices = (props) => {
  const {
    tutoringAction,
    data,
    userLogin,
    userSignUp,
    cancelAppointment,
    confirmAppointment,
  } = props;

  const history = useHistory();
  const dispatch = useDispatch();

  const [state, setState] = useState({
    disable: true,
    datas: data,
    appointmentModal: false,
    unpaid: [],
    loginModal: false,
    signupModal: false,
    checkBox: false,
    loading: false,
    showPassword: false,
    showConfirmPassword: false,
  });

  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });

  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });

  useEffect(() => {
    tutoringAction();
  }, []);
  useEffect(() => {
    if (data.length) {
    }
  }, [data]);

  const clickFunction = async (item) => {
    if (!getApiKey()) {
      setState((prevState) => ({
        ...prevState,
        loginModal: true,
      }));
      return;
    }
    let best = [...data];
    best.map((res) => {
      if (res.title === item.title) {
        res.selected = true;
      } else {
        res.selected = false;
      }
    });
    setState((prevState) => ({
      ...prevState,
      disable: false,
      datas: best,
    }));
    await bookNow(item.slug, item.price);
  };

  const forgetPass = () => {
    setState((prevState) => ({
      ...prevState,
      loginModal: false,
    }));
    history.push("/forget-password");
  };

  const handleUserLogin = async (body) => {
    setState((prevState) => ({
      ...prevState,
      loginModal: true,
    }));
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    setState((prevState) => ({
      ...prevState,
      signupModal: false,
    }));
    if (state.checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };
  const checked = (e) => {
    if (e === true) {
      setState((prevState) => ({
        ...prevState,
        checkBox: true,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        checkBox: false,
      }));
    }
  };

  const confirmAppoint = async (status) => {
    if (status === "CONFIRM") {
      let arr = [];
      for (let i = 0; i < state.unpaid.length; i++) {
        arr.push({
          title: state.unpaid[i].title,
          appointment_obj_id: state.unpaid[i].id,
          package_price: state.unpaid[i].package_price,
        });
      }
      confirmAppointment({ appointment_data: arr });
    } else if (status === "CANCEL") {
      let arr = [];
      for (let i = 0; i < state.unpaid.length; i++) {
        arr.push({
          title: state.unpaid[i].title,
          appointment_obj_id: state.unpaid[i].id,
          package_price: state.unpaid[i].package_price,
        });
      }
      cancelAppointment({ appointment_data: arr }).then((res) => {
        dispatch(createErrorAlert(200, "Success", res.data.data, 200));
        setState((prevState) => ({
          ...prevState,
          appointmentModal: false,
        }));
        window.location.reload();
      });
    }
  };

  const packageRoute = (slug, price) => {
    if (slug === "one-2-one") {
      history.push({
        pathname: `/tutoring/tutor-select/${slug}`,
        state: { price },
      });
    } else if (slug === "group") {
      history.push({
        pathname: `/book-tutorials/group`,
      });
    } else if (slug.includes('review')) {
      history.push("/review-and-feed");
    }
  };

  const bookNow = async (slug, price) => {
    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));
    await apiRequest({
      method: "get",
      url: `content/user/session/`,
    }).then((res) => {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
      for (let i = 0; i < res.data.length; i++) {
        if (
          res.data[i].paid === false &&
          res.data[i].deactivate === false &&
          res.data[i].title !== "Free Appointment"
        ) {
          state.unpaid.push(res.data[i]);
          var countDownDate =
            new Date(parseISO(state.unpaid[0]?.created)).getTime() +
            20 * 60 * 1000;
          var x = setInterval(function () {
            let now = new Date().getTime();

            let distance = countDownDate - now;

            let minutes = Math.floor(
              (distance % (1000 * 60 * 60)) / (1000 * 60)
            );
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            document.getElementById("time").innerHTML =
              "Remaining Time to Cancel: " + minutes + "m " + seconds + "s ";

            if (minutes === 0 && seconds === 0) {
              confirmAppoint("CANCEL");
              clearInterval(x);
              document.getElementById("time").innerHTML = "EXPIRED";
            }
          }, 1000);
        }
      }
    });
    if (state.unpaid.length === 0) {
      packageRoute(slug, price);
    } else {
      setState((prevState) => ({
        ...prevState,
        appointmentModal: true,
      }));
    }
  };

  const extractAndReplaceCustomBullet = (htmlString, item) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");

    const ulElements = doc.querySelectorAll("ul");

    ulElements.forEach((ulElement) => {
      ulElement.style.listStyle = "none";

      const liElements = ulElement.querySelectorAll("li");
      liElements.forEach((liElement) => {
        const bulletImage = document.createElement("span");
        bulletImage.classList.add("custom-bullet");
        liElement.classList.add("packageSpan");
        liElement.insertBefore(bulletImage, liElement.firstChild);

        const liTextSpan = document.createElement("span");
        liTextSpan.textContent = liElement.textContent;

        liElement.innerHTML = "";

        liElement.appendChild(bulletImage);
        liElement.appendChild(liTextSpan);
        liElement.style.marginBottom = "10px";
      });
    });

    const modifiedHtmlString = new XMLSerializer().serializeToString(doc);

    return modifiedHtmlString;
  };

  return (
    <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
      {state.loading && <FullLoader />}
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Tutoring Services</title>
        <meta name="description" content="Tutoring Services Description" />
        <link
          rel="canonical"
          href="https://simplestudying.com/boost-your-grades"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Simple Studying | Tutoring Services"
        />
        <meta
          property="og:description"
          content="Tutoring Services Description"
        />
        <meta
          property="og:url"
          content="https://simplestudying.com/boost-your-grades"
        />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <div className="xl:px-56">
        <Container>
          <div className="pt-6 md:pt-10 lg:pt-14 flex">
            <div className="text-darkest boostGradMain">
              <p className="tutorialVidText text-36 custom2:text-36 font-bold mb-6 lg:mb-12">
                Tutoring
              </p>
              <p className="text-h5 md:text-h4 lg:text-h3 mb-6"></p>
              <p className="mb-4 text-body-small md:text-body-large font-normal">
                One premium subscription is essential to be equipped with the
                right study materials for getting high grades. However most of
                our students do better with some extra help focusing on their
                legal writing provided by our one to one tutoring.
              </p>
              <p className="mb-4 text-body-small md:text-body-large text-red-200 font-normal">
                We highly recommend getting at least 6 hours of one to one
                tutoring with the premium subscription. This will really boost
                your chances of getting a First Class.
              </p>
              <p className="mb-4 text-body-small md:text-body-large  font-normal">
                We also have free tutoring in a group for revision and practice
                but it cannot replace one to one tutoring which has proven to be
                significant for a First Class.
              </p>
            </div>
          </div>

          <div>
            <b>
              <p
                className="text-body-small md:text-body-large"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  marginTop: "40px",
                }}
              >
                Please choose a Tutoring Service
              </p>
            </b>
            <div className="pt-10 flex flex-col justify-center items-center lg:flex-row lg:justify-between lg:space-between">
              {data.length
                ? data?.map((item, index) => {
                    return (
                      <div
                        className={`p-2 md:p-4  lg:w-1/3 forBoxBorder   h-1150 mb-9 rounded-lg ${
                          item.selected === true &&
                          "border-4 border-borderGreen"
                        }`}
                        onClick={() => clickFunction(item)}
                      >
                        <div className={index===1?"relative  h-full bg-gray-100 bg-opacity-75 flex flex-col  items-center  rounded-lg overflow-hidden text-center":"relative h-full bg-gray-100 bg-opacity-75 flex flex-col  items-center  rounded-lg overflow-hidden text-center "}>
                          <div
                            className={
                              index === 0
                                ? "flex justify-center my-3 w-80% h-auto"
                                : index === 1
                                ? "flex justify-center my-3 w-80% h-200"
                                : "flex justify-center my-3 w-80% h-auto"
                            }
                          >
                            <img src={item.image} />
                          </div>

                          <p className=" relative p-3 pt-8">
                            <span className="pckgPriceText linear-color mt-2 themeClr bookApHeadText text-17  md:text-16 lg:text-20 text-gray-400 mb-1 text-center font-bold text-bolder">
                              {item?.price === "Free"
                                ? "Free"
                                : item?.title.includes('REVIEW')
                                ? ''
                                : `Price : £ ${item?.price}/hr`}
                            </span>
                          </p>

                          <h1
                            className={
                              index === 2
                                ? "text-black text-center text-17 min-h-70 max-h-70  md:text-16 lg:text-20  font-bold pb-4"
                                : "text-black text-center text-17 min-h-70 max-h-70  md:text-16 lg:text-20  font-bold pb-5"
                            }
                          >
                            {item?.title}
                          </h1>
                          <div>
                            <p
                              className="mb-3 text-left mb-20 mt-4"
                              dangerouslySetInnerHTML={{
                                __html: extractAndReplaceCustomBullet(
                                  item?.content,
                                  item
                                ),
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : null}
            </div>
          </div>

          <div className="py-8 md:py-12 lg:py-20">
            <div className="flex flex-col items-center md:flex-row justify-center md:px-20">
              <div className="w-56 bookNowSec">
                {!getApiKey() ? (
                  <Button
                    text="Login"
                    filled
                    onClick={() => {
                      setState((prevState) => ({
                        ...prevState,
                        loginModal: true,
                      }));
                    }}
                  >
                    <p className="text-center text-14 xl:text-body-large">
                      Login
                    </p>
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
          <Modal
            open={state.appointmentModal}
            onClose={() =>
              setState((prevState) => ({
                ...prevState,
                appointmentModal: false,
              }))
            }
            focusTrapped={false}
            center
            classNames={{
              modal: "react-modal-class2",
            }}
          >
            <div>
              <p className="text-darkest md:text-h3 font-bold mb-5">
                You have an unpaid appointment. Kindly confirm or cancel to
                create another appointment
              </p>
              <br />
              <p id="time"></p>

              <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
                <div className="w-56">
                  <Button
                    simple
                    onClick={() => {
                      confirmAppoint("CANCEL");
                    }}
                  >
                    <p className="text-center text-14 xl:text-body-large">
                      Cancel
                    </p>
                  </Button>
                </div>
                <div className="w-56 mt-5 md:mt-0">
                  <Button
                    filled
                    onClick={() => {
                      confirmAppoint("CONFIRM");
                    }}
                  >
                    <p className="text-center text-14 xl:text-body-large">
                      Confirm
                    </p>
                  </Button>
                </div>
              </div>
            </div>
          </Modal>

          <Modal
            open={state.loginModal}
            onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
            focusTrapped={false}
            center
            classNames={{
              overlay: 'custom-overlay-class',
             modal: 'react-modal-class1', 
            }}
          >
            <div className="flex gap-6">
              {/* <div className="w-full hidden md:block">
                <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                  Join the movement of{" "}
                  <span className="text-yellowish">20K savvy students</span>{" "}
                  already empowered - and you're next
                </p>

                <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                  Discover the secret to success as you become part of our
                  growing community.
                </p>
                <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                  <div className=" text-center">
                    <div className="w-116 h-100">
                      <img src={img1} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="  fw-bold text-42 text-yellowish ">35+</p>
                    <p>
                      Study Modules
                      <br />
                      All main areas of law
                    </p>
                  </div>
                  <div className=" text-center">
                    <div className="w-100 h-100">
                      <img src={img2} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="fw-bold text-42  text-yellowish">500K+</p>
                    <p>
                      Users
                      <br />
                      Every Year
                    </p>
                  </div>
                  <div className=" text-center">
                    <div className="w-150 h-150">
                      <img src={img3} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="fw-bold text-42  text-yellowish">1430+</p>
                    <p>
                      Hours Tutoring <br />
                      Delivered
                    </p>
                  </div>
                </div>
              </div> */}
              <div className="  flex-basis-60 w-full hidden md:block">
            <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
              Join the movement of{" "}
              <span className="text-yellowish">20K savvy students</span> already
              empowered - and you're next
            </p>

            <p className=" text-body-small text-left pt-6 md:text-h5 xl:text-36 ">
              Discover the secret to success as you become part of our growing
              community.
            </p>
            <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
              <div className=" flex-1 flex flex-col justify-center items-center text-center">
                <div className="h-120">
                <div className="w-116 h-100">
                  <img src={img1} className="m-auto mt-3 w-full h-full" />
                </div>
                </div>
               
                <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                <p className="h-51">
                  Study Modules
                  <br />
                  All main areas of law
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
              <div className="h-120">
              <div className="w-100 h-100">
                  <img src={img2} className="m-auto mt-3 w-full h-full" />
                </div>
              </div>
               
                <p className="fw-bold text-42  text-yellowish mt-4">500K+</p>
                <p>
                  Users
                  <br />
                  Every Year
                </p>
              </div>
              <div className="flex-1 flex flex-col justify-center items-center text-center">
               <div className="h-120">
               <div className="w-100 h-100">
                  <img src={img3} className="m-auto mt-3 w-full h-full" />
                </div>
               </div>
               
                <p className="fw-bold text-42  text-yellowish mt-4">1430+</p>
                <p className="h-51">
                  Hours Tutoring <br />
                  Delivered
                </p>
              </div>
            </div>
          </div>
              <div className="flex-basis-40">
                <Formik
                  initialValues={{
                    password: "",
                    username: "",
                  }}
                  validationSchema={validateSchema}
                  onSubmit={(values) => handleUserLogin(values)}
                >
                  {({ isValid, handleSubmit, values }) => (
                    <Form>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="loginInputMain flex flex-col text-darkest items-center">
                          <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                            Sign in to{" "}
                            <span className="text-yellowish">
                              SimpleStudying
                            </span>
                          </p>
                          <div className="w-full mb-3 custom2::mb-9 ">
                            <p className="mb-8">Username or Email</p>
                            <Field
                              name="username"
                              as={Inputs}
                              autoComplete="off"
                              placeholder="Enter your username or email"
                              type="text"
                            />
                          </div>
                          <div className="w-full  mb-3 custom2:mb-9">
                            <p className="mb-8 ">Password</p>
                            <Field
                              name="password"
                              as={Inputs}
                              placeholder="Enter your password"
                              type={state.showPassword ? "text" : "password"}
                              setShowPassword={setState}
                              showPassword={state.showPassword}
                              inputType="password"
                              autoComplete="off"
                            />
                          </div>
                          <Link
                            className="mb-8 forgetText "
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                loginModal: false,
                              }));
                              history.push("/forget-password");
                            }}
                          >
                            Forget Password?
                          </Link>
                        </div>
                        <div className="pt-4 flex justify-center mb-10">
                          <div className="w-full h:34 md:h-42 lg:h-51">
                            <Button
                              onClick={handleSubmit}
                              text="Log in"
                              filled
                              className="buttonClass"
                              disabled={
                                values.username === "" ||
                                values.password === "" ||
                                !isValid
                              }
                              loading={state.loader}
                            />
                          </div>
                        </div>
                      </form>
                    </Form>
                  )}
                </Formik>
                <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                  You don't have an account yet?{" "}
                  <a
                    className="font-bold text-primary cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        loginModal: false,
                        signupModal: true,
                      }));
                    }}
                  >
                    {"  "}Sign up
                  </a>
                </p>
              </div>
            </div>
          </Modal>

          {/* Sign UP Modal */}

          <Modal
            open={state.signupModal}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                signupModal: false,
              }))
            }
            focusTrapped={false}
            center
            classNames={{
              overlay: 'custom-overlay-class',
             modal: 'react-modal-class1', 
            }}
          >
            <div className="flex gap-6">
              <div className="w-full hidden md:block">
                <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                  Join the movement of{" "}
                  <span className="text-yellowish">20K savvy students</span>{" "}
                  already empowered - and you're next
                </p>

                <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                  Discover the secret to success as you become part of our
                  growing community.
                </p>
                <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                  <div className=" text-center">
                    <div className="w-116 h-100 mb-6">
                      <img src={img1} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="  fw-bold text-42 text-yellowish ">35+</p>
                    <p>
                      Study Modules
                      <br />
                      All main areas of law
                    </p>
                  </div>
                  <div className=" text-center">
                    <div className="w-100 h-100">
                      <img src={img2} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="fw-bold text-42  text-yellowish">500K+</p>
                    <p>
                      Users
                      <br />
                      Every Year
                    </p>
                  </div>
                  <div className=" text-center">
                    <div className="w-100 h-100">
                      <img src={img3} className="m-auto mt-3 w-full h-full" />
                    </div>
                    <p className="fw-bold text-42  text-yellowish">1430+</p>
                    <p>
                      Hours Tutoring <br />
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <Formik
                  initialValues={{
                    password1: "",
                    username: "",
                    email: "",
                    password2: "",
                  }}
                  validationSchema={validateSchemaSignup}
                  onSubmit={(values) => onSignUp(values)}
                >
                  {({ isValid, handleSubmit, values }) => (
                    <Form>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="flex flex-col text-darkest items-center">
                          <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                            Sign up to{" "}
                            <span className="text-yellowish">
                              SimpleStudying
                            </span>
                          </p>
                          <div className="w-full mb-3 md:mb-9 ">
                            <p className="mb-8">Email</p>
                            <Field
                              name="email"
                              as={Inputs}
                              placeholder="Enter your email"
                              type="text"
                            />
                          </div>

                          <div className="w-full mb-3 md:mb-9 ">
                            <p className="mb-8">Username</p>
                            <Field
                              name="username"
                              as={Inputs}
                              placeholder="Enter your username"
                              type="text"
                            />
                          </div>

                          <div className="w-full ">
                            <p className="mb-8">Password</p>
                            <Field
                              name="password1"
                              as={Inputs}
                              placeholder="Enter your password"
                              type={state.showPassword ? "text" : "password"}
                              setShowPassword={setState}
                              showPassword={state.showPassword}
                              inputType="password"
                            />
                            <p className="text-small text-darkGrey2 my-8">
                              6+ characters, 1 capital letter{" "}
                            </p>
                          </div>
                          <div className="w-full mb-10 md:mb-14">
                            <p className="mb-8">Confirm password</p>
                            <Field
                              name="password2"
                              as={Inputs}
                              placeholder="Enter your confirm password"
                              type={
                                state.showConfirmPassword ? "text" : "password"
                              }
                              setShowPassword={setState}
                              showPassword={state.showConfirmPassword}
                              inputType="password"
                            />
                          </div>
                          <div
                            className="w-full mb-10 md:mb-14"
                            style={{ display: "flex" }}
                          >
                            <label className="condContainer">
                              {" "}
                              I agree to{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/terms-conditions");
                                }}
                              >
                                Terms and Conditions
                              </a>
                              ,{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/privacy-policy");
                                }}
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/cookies-policy");
                                }}
                              >
                                Cookies Policy
                              </a>{" "}
                              of Simple Studying{" "}
                              <input
                                id="condBox"
                                onClick={(e) => checked(e.target.checked)}
                                type="checkbox"
                                className="default:ring-2 text-primary mr-3 checkBoxOne"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="pt-4 flex justify-center mb-10">
                          <div className="w-full h-51">
                            <Button
                              onClick={handleSubmit}
                              text="Sign up for free"
                              filled
                              className="buttonClass"
                              disabled={
                                values.username === "" ||
                                values.password1 === "" ||
                                values.password2 === "" ||
                                values.email === "" ||
                                !isValid ||
                                values.checked === "false"
                              }
                              loading={state.loader}
                            />
                          </div>
                        </div>
                      </form>
                    </Form>
                  )}
                </Formik>
                <p className="font-normal text-darkest font-body-large text-center">
                  Already have an account?{" "}
                  <a
                    className="font-bold text-primary cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        loginModal: true,
                        signupModal: false,
                      }));
                    }}
                  >
                    {"  "}Log in
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        </Container>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: tutoringLoading(state),
  error: tutoringError(state),
  data: tutoringData(state),
  cancelAppointmentData: cancelAppointmentData(state),
  confirmAppointmentData: confirmAppointmentData(state),
});
const mapDispatchToProps = (dispatch) => ({
  tutoringAction: () => dispatch(getTutoring()),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  cancelAppointment: (obj) => dispatch(cancelAppointmentAction(obj)),
  confirmAppointment: (obj) => dispatch(confirmAppointmentAction(obj)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TutoringServices);
