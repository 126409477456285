import React, {useEffect, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';
import './index.css';
import Container from '../../components/container';
import TutorSessionStudents from '../../components/TutorSiteComponents/tutorSessionsStudents';
import TutorSessionModal from '../../components/TutorSessionModal/tutorSessionModal';
import addIcon from '../../assets/images/addIcon.webp';
import {
    createGroupSession,
    createOneToOneSession,
    getGroupParticipants,
    getGroupSession,
    getTutorGroupSession,
    getTutorOne2One,
} from '../../redux/actions';
import {
    getGroupSessionData,
    groupParticipantsData,
    tutorGroupSessionError,
    tutorGroupSessionLoading,
    tutorOne2OneData,
} from '../../redux/selectors';
import FullLoader from '../../components/Loader/FullLoader';
import {Helmet} from "react-helmet";

const Sessions = (props) => {
    let consts = useSelector(({store}) => store.tutorGroupSessionReducer.data);
    const {
        createOneToOneSession,
        createGroupSession,
        getTutorOne2One,
        one2one,
        getGroupParticipants,
        participants,
        tutorGroupSessions,
        getGroupSession,
        sessionLoading,
        location,
    } = props;
    const [modal, setModal] = useState(false);
    const [status, setStatus] = useState('group');
    const [opens, setOpens] = useState();
    const dispatch = useDispatch();
    const [subStatus, setSubStatus] = useState('upcoming')
    const [schedules, setSchedules] = useState([
        {
            id: 3,
            title: 'Law Enforcement Discussion',
            start_time: '09:00 am',
            end_time: '10:00 am',
            day: '18',
            price: '$22',
            month: 'July',
            capacity: '120',
            persons: 69,
            open: true,
            status: 'open',
        },
        {
            id: 4,
            title: 'Money Laundering Case Discussion',
            start_time: '10:00 am',
            end_time: '11:00 am',
            day: '20',
            price: '$57.7',
            month: 'August',
            capacity: '100',
            persons: 88,
            open: false,
            status: 'canceled',
        },
        {
            id: 5,
            title: 'Session Related to Criminal Law',
            start_time: '11:00 am',
            end_time: '12:00 pm',
            day: '20',
            month: 'August',
            price: '$ 109.90',
            capacity: '60',
            persons: 19,
            open: false,
            status: 'completed',
        },
    ]);

    useEffect(() => {
        dispatch(getTutorGroupSession());
        getTutorOne2One('umar-session');
        getGroupSession();
        getGroupParticipants('law', '2022-06-30');
    }, []);


    let sortedAppointments, oneToOneSorted
    if(consts.length > 0) {
        sortedAppointments = consts?.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
    }

    if(one2one.length > 0) {
        oneToOneSorted = one2one?.sort((a, b) => {
            const dateA = new Date(a.date);
            const dateB = new Date(b.date);
            return dateB - dateA;
          });
    }

    const openScheduleOnetoOne = (id) => {
        const schedulesCopy = [...one2one];
        schedulesCopy.forEach((elem) => {
            if (elem.title === id.title) {
                elem.is_open = false;
            }
        });
        setSchedules([...schedulesCopy]);
    };

    const openSchedule = (id) => {
        const schedulesCopy = [...consts];
        schedulesCopy.forEach((elem) => {
            if (elem.title === id) {
                elem.is_open = true;
            }
        });
        setSchedules(schedulesCopy);
    };

    const closeScheduleOneToOne = (id) => {
        const schedulesCopy = [...one2one];
        schedulesCopy.forEach((elem) => {
            if (elem.title === id.title) {
                elem.is_open = false;
            }
        });
        setSchedules([...schedulesCopy]);
    };

    const closeSchedule = (id) => {
        const schedulesCopy = [...consts];
        schedulesCopy.forEach((elem) => {
            if (elem.title === id) {
                elem.is_open = false;
            }
        });
        setSchedules(schedulesCopy);
    };

    const togglePersons = (id, type) => {
        let schedulesCopy = [...schedules];
        const schedule = schedulesCopy.find((value) => value.slug === id);

        if (type === 'enlarge') {
            schedule.persons = schedule.persons + 1;
        } else {
            if (!(schedule.persons === 0)) {
                schedule.persons = schedule.persons - 1;
            }
        }
        setSchedules(schedulesCopy);
    };

    const createSession = (title2, capacity, date, time, packages, serviceType, sessionStatus) => {

        let endTime
        if(time.includes('AM')){
            let split = time.split('AM')[0]
            if(split.split(':')[0] === '00'){
                endTime = '00:30AM'
                if(split.split(':')[1] === '30 '){
                    endTime = '01:00 AM'
                }
            }if(split.split(':')[0] === '11') {
                endTime = '11:30AM'
                if(split.split(':')[1] === '30 '){
                    endTime = '12:00 PM'
                }
            }else{
                endTime = parseInt(split) + ':30 AM'
                if(split.split(':')[1] === '30 '){
                    endTime = parseInt(split) + 1 +':00 AM'
                }
            } 
        } else if(time.includes('PM')){
            let split = time.split('PM')[0]
            if(split.split(':')[0] === '12'){
                endTime = '12:30 PM'
                if(split.split(':')[1] === '30 '){
                    endTime = '01:00 PM'
                }
            }if(split.split(':')[0] === '23'){
                endTime = '23:30 PM'
                if(split.split(':')[1] === '30 '){
                    endTime = '00:00 AM'
                }
            } else{
                endTime = parseInt(split) + ':30 PM'
                if(split.split(':')[1] === '30 '){
                    endTime = parseInt(split) + 1 + ':00 PM'
                }
            }
        }
        if (serviceType === 'Group') {

            let obj = {
                'title': title2,
                'tutor': JSON.parse(localStorage.getItem('app_user')).username,
                'date': date,
                'start_time': time,
                'end_time': endTime,
                'min_capacity': 1,
                'max_capacity': capacity,
                'status': 'OPEN',
            };
            createGroupSession(obj);
        } else {
            let obj = {
                'title': title2,
                'tutor': JSON.parse(localStorage.getItem('app_user')).username,
                'date': date,
                'start_time': time,
                'end_time': endTime,
                'packages': packages,
                'status': 'OPEN',
                'is_paid': true,
            };
            createOneToOneSession(obj);
        }
        // window.location.reload();
    };

    const getMonthFromString = (monthString) => {
        return new Date(Date.parse(monthString + " 1, 2000")).getMonth();
    }

    function convertTo24HourFormat(time12Hour) {
        const [time, ampm] = time12Hour.split(' ');
        const [hours, minutes] = time.split(':');
        let hours24;
      
        if (ampm === 'AM') {
          hours24 = hours === '12' ? '00' : hours;
        } else {
          hours24 = hours === '12' ? '12' : (parseInt(hours, 10) + 12).toString();
        }
      
        return `${hours24}:${minutes}`;
      }
    
      const time24HrSplit = (timeGet) => {
        const [hours, minutes] = timeGet.split(':');
    
        const hoursInt = parseInt(hours, 10);
        const minutesInt = parseInt(minutes, 10);
        const newMinutesInt = (minutesInt + 30) % 60;
        const newHoursInt = hoursInt + Math.floor((minutesInt + 30) / 60);
    
        if (newHoursInt >= 24) {
          newHoursInt -= 24;
        }
    
        const newTime = `${newHoursInt.toString().padStart(2, '0')}:${newMinutesInt.toString().padStart(2, '0')}`;
    
        return newTime
    
      }

    const pastDateCheck = (item) => {
        let one2oneMonths, one2oneDay;
        one2oneMonths = new Date(item?.date).toLocaleString(
        "default",
        { month: "short" }
        );
        one2oneDay = new Date(item?.date).getDate();
        let providedYear = new Date(item?.date).getFullYear();
        const now = new Date();
        const providedDate = new Date(
        providedYear,
        getMonthFromString(one2oneMonths),
        parseInt(one2oneDay)
        );
        let monthProvided = providedDate.getMonth() + 1;
        let monthNow = now.getMonth() + 1;
        const currentHours = now.getHours();
        const currentMinutes = now.getMinutes();
        let providedHrs = time24HrSplit(convertTo24HourFormat(item.start_time)).split(':')[0]
        let providedMinutes = time24HrSplit(convertTo24HourFormat(item.start_time)).split(':')[1]
        providedDate.setHours(providedHrs)
        providedDate.setMinutes(providedMinutes)

        if(item?.status !== "CANCEL"){
            if ((providedYear < now.getFullYear() || providedYear === now.getFullYear())) {
                if(monthProvided < monthNow){
                    if(providedDate.getDate() < now.getDate()){
                        return "Past";
                    } if(providedDate.getDate() > now.getDate()){
                        return 'Past'
                    } if(providedDate.getDate() === now.getDate()){
                        return 'Past'
                    }
                }
                if(monthProvided > monthNow){
                    if(providedDate.getDate() < now.getDate()){
                        return 'Up Coming'
                    } if(providedDate.getDate() > now.getDate()){
                        return 'Up Coming'
                    } if(providedDate.getDate() === now.getDate()){
                        return 'Up Coming'
                    }
                }
                if(monthProvided === monthNow){
                    if(providedDate.getDate() < now.getDate()){
                        return 'Past'
                    } if(providedDate.getDate() > now.getDate()){
                        return 'Up Coming'
                    } if(providedDate.getDate() === now.getDate()){
                        if (currentHours > providedHrs || (currentHours === providedHrs && currentMinutes > providedMinutes)) {
                            return 'Past';
                          } else {
                            return 'Up Coming';
                          }
                    }
                }
            }
            else {
                return "Up Coming";
            }
        }
    }

    const pastDateCheckOne = (item) => {
        let one2oneMonths, one2oneDay;
    one2oneMonths = new Date(item?.date).toLocaleString("default", {
      month: "short",
    });
    one2oneDay = new Date(item?.date).getDate();
    let providedYear = new Date(item?.date).getFullYear();
    const now = new Date();
    const providedDate = new Date(
      providedYear,
      getMonthFromString(one2oneMonths),
      parseInt(one2oneDay)
    );
    let monthProvided = providedDate.getMonth() + 1;
    let monthNow = now.getMonth() + 1;
    if ((providedDate.getDate() > now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Up Coming";
    }
    if ((providedDate.getDate() < now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Up Coming";
    }
    if ((providedDate.getDate() === now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Up Coming";
    }
    if ((providedDate.getDate === now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Up Coming";
    }
    if ((providedDate.getDate() > now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Up Coming";
    }
    if ((providedDate.getDate() < now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === true) {
      return "Past";
    } else {
      if(item.deactivate === false && item?.paid === true){
        return "Past";
      } else if(item.deactivate === false && item.paid === false){
        if ((providedDate.getDate() > now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Up Coming";
          }
          if ((providedDate.getDate() < now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Up Coming";
          }
          if ((providedDate.getDate() === now.getDate() && monthProvided > monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Up Coming";
          }
          if ((providedDate.getDate === now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Up Coming";
          }
          if ((providedDate.getDate() > now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Up Coming";
          }
          if ((providedDate.getDate() < now.getDate() && monthProvided === monthNow) && (providedYear > now.getFullYear() || providedYear === now.getFullYear()) && item?.deactivate === false && item?.paid === false) {
            return "Past";
          } 
      }
    }
    }

    const cancelCheckSession = (item) => {
        if(item.deactivate === true && item.paid === true){
            return 'Cancel'
        }
    }

    return (
        <>
            {sessionLoading ? <FullLoader/> :

                <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat custom2:pb-20">
                    <Helmet>
                        <meta charSet="utf-8"/>
                        <title>Simple Studying | Tutor Sessions</title>
                        <meta name="description" content="Tutor Sessions Description"/>
                        <link rel="canonical" href="https://simplestudying.com/sessions"/>
                        <meta name="robots" content="index, follow"/>
                        <meta property="og:locale" content="en_US"/>
                        <meta property="og:locale:alternate" content="en_gb"/>
                        <meta property="og:type" content="website"/>
                        <meta property="og:title" content="Simple Studying | Tutor Sessions"/>
                        <meta property="og:description" content="Tutor Sessions Description"/>
                        <meta property="og:url" content="https://simplestudying.com/sessions"/>
                        <meta property="og:site_name" content="Simple Studying"/>
                    </Helmet>
                    <TutorSessionModal
                        isOpen={modal}
                        buttonOneText="Confirm"
                        buttonTwoText="Cancel"
                        createSession={createSession}
                        onClickFirstButton={() => setModal(false)}
                        onClickSecondButton={() => setModal(false)}
                        closeModal={() => setModal(false)}
                        title="Add Session"
                    />
                    <div className="">
                        <Container>
                            <div className="pt-10 lg:pt-14 flex mb-10">
                                <div className="text-darkest flex justify-between w-full">
                                    <div>
                                        <p className="text-h3  font-bold mb-10 custom2:mb-8 ">
                                            Your Sessions
                                        </p>
                                    </div>
                                    {status === 'group' &&
                                        <div className="text-center">
                                            <button onClick={() => setModal(true)} className="addSessBtn">
                                                <img className="inline-block mr-2" src={addIcon}/>
                                                Add Session
                                            </button>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="tutorTabs">
                                <div className="mb-4 border-b " style={{borderColor: '#cc6828'}}>
                                    <ul
                                        className="flex flex-wrap -mb-px text-sm font-medium text-center"
                                        id="myTab"
                                        data-tabs-toggle="#myTabContent"
                                        role="tablist"
                                    >
                                        <li className="mr-2" role="presentation">
                                            <button
                                                className={`rounded-none inline-block px-4 py-2 ${status === 'group' && ' bg-primary text-white'}`}
                                                id="profile-tab"
                                                data-tabs-target="#profile"
                                                type="button"
                                                role="tab"
                                                aria-controls="profile"
                                                aria-selected="false"
                                                onClick={() => {
                                                    setStatus('group')
                                                    setSubStatus('upcoming')
                                                }}
                                            >
                                                Group
                                            </button>
                                        </li>
                                        <li className="mr-2" role="presentation">
                                            <button
                                                className={`rounded-none inline-block px-4 py-2 ${status === 'oneToOne' && ' bg-primary text-white'}`}
                                                id="dashboard-tab"
                                                data-tabs-target="#dashboard"
                                                type="button"
                                                role="tab"
                                                onClick={() => {
                                                    setStatus('oneToOne')
                                                    setSubStatus('upcoming')
                                                }}
                                                aria-controls="dashboard"
                                                aria-selected="false"
                                            >
                                                One-to-One
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div id="myTabContent">
                                <div className="mb-4 border-b " style={{borderColor: '#fff', position: "relative" }}>
                                <ul
                                    className="tagsRow flex flex-wrap -mb-px text-sm font-medium text-center"
                                    id="myTab"
                                    data-tabs-toggle="#myTabContent"
                                    role="tablist"
                                >
                                    <li className="mr-2" role="presentation">
                                        <button
                                            className={`rounded-none inline-block px-4 py-2 ${subStatus === 'upcoming' ? ' bg-primary text-white' : ' bg-gray text-black'}`}
                                            id="Past"
                                            data-tabs-target="#Past"
                                            type="button"
                                            role="tab"
                                            onClick={() => {
                                                setSubStatus('upcoming')
                                            }}
                                            aria-controls="Past"
                                            aria-selected="false"
                                          
                                        >
                                            Upcoming
                                        </button>
                                    </li>
                                    <li className="mr-2" role="presentation">
                                        <button
                                            className={`rounded-none inline-block px-4 py-2 ${subStatus === 'past' ? ' bg-primary text-white' : ' bg-gray text-black'}`}
                                            id="dashboard-tab"
                                            data-tabs-target="#dashboard"
                                            type="button"
                                            role="tab"
                                            onClick={() => {
                                                setSubStatus('past')
                                            }}
                                            aria-controls="dashboard"
                                            aria-selected="false"
                                        >
                                            Past
                                        </button>
                                    </li>
                                    
                                </ul>
                              
                            </div>
                            {status === 'group' ? <div id="myTabContent" className='userContSec'>
                                {subStatus === 'past' ? <div
                                    className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                                    id="Past"
                                    role="tabpanel"
                                    aria-labelledby="Past-tab"
                                >
                                    <div
                                        className="space-y-3.5 max-h-925 px-3.5 "
                                        id="session-container"
                                    >
                                       <div
                                    className={`${status === 'oneToOne' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <div
                                        className="space-y-3.5 max-h-925 px-3.5 "
                                        id="session-container"
                                    >
                                        {consts.length
                                            ? sortedAppointments?.map((elem) => {
                                                if(pastDateCheck(elem) === 'Past') {
                                                    return(
                                                        <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        open={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        data={elem}
                                                        participants={participants}
                                                        date={props?.location?.state?.calendarDate}
                                                        openSchedule={() => openSchedule(elem.title)}
                                                        closeSchedule={() => closeSchedule(elem.title)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                       )
                                                }
                                                })
                                            : 'You do not have any tutoring session scheduled'}
                                    </div>
                                </div>
                                    </div>
                                </div> : subStatus === 'upcoming' ? <div
                                    className= "py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                                    id="upComing"
                                    role="tabpanel"
                                    aria-labelledby="upComing-tab"
                                >

                                    <div
                                        className="space-y-3.5 max-h-925 px-3.5 "
                                        id="session-container"
                                    >
                                       <div
                                    className={`${status === 'oneToOne' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                    id="profile"
                                    role="tabpanel"
                                    aria-labelledby="profile-tab"
                                >
                                    <div
                                        className="space-y-3.5 max-h-925 px-3.5 "
                                        id="session-container"
                                    >
                                        {consts.length
                                            ? sortedAppointments?.map((elem) => {
                                                if(pastDateCheck(elem) === 'Up Coming') {
                                                    return(
                                                        <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        open={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        data={elem}
                                                        participants={participants}
                                                        date={props?.location?.state?.calendarDate}
                                                        openSchedule={() => openSchedule(elem.title)}
                                                        closeSchedule={() => closeSchedule(elem.title)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                       )
                                                }
                                                })
                                            : 'You do not have any tutoring session scheduled'}
                                    </div>
                                </div>
                                    </div>
                                </div> : null}
                                
                                
                                
                            </div> : status === 'oneToOne' ? <div id="myTabContent" className='userContSec'>
                                {subStatus === 'past' ? <div
                                    className="py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                                    id="Past"
                                    role="tabpanel"
                                    aria-labelledby="Past-tab"
                                >
                                    <div
                                        className="space-y-3.5 max-h-925 px-3.5 "
                                        id="session-container"
                                    >
                                       <div
                                    className={`${status === 'group' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                    id="dashboard"
                                    role="tabpanel"
                                    aria-labelledby="dashboard-tab"
                                >

                                    {one2one?.length
                                        ? one2one?.map((elem) => {
                                            if(pastDateCheckOne(elem) === 'Past') {
                                                return(
                                                    <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        openOne={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        oneToOne={elem}
                                                        date={props?.location?.state?.calendarDate}
                                                        openScheduleOnetoOne={() => openScheduleOnetoOne(elem)}
                                                        closeScheduleOnetoOne={() => closeScheduleOneToOne(elem)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                )
                                            }
                                            })
                                        : 'You do not have any tutoring session scheduled'}
                                </div>
                                    </div>
                                </div> : subStatus === 'upcoming' ? <div
                                    className= "py-4 bg-gray-50 rounded-lg dark:bg-gray-800"
                                    id="upComing"
                                    role="tabpanel"
                                    aria-labelledby="upComing-tab"
                                >

                                    <div
                                    className={`${status === 'group' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                    id="dashboard"
                                    role="tabpanel"
                                    aria-labelledby="dashboard-tab"
                                >

                                    {one2one?.length
                                        ? one2one?.map((elem) => {
                                            if(pastDateCheckOne(elem) === 'Up Coming') {
                                                return(
                                                    <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        openOne={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        oneToOne={elem}
                                                        date={props?.location?.state?.calendarDate}
                                                        openScheduleOnetoOne={() => openScheduleOnetoOne(elem)}
                                                        closeScheduleOnetoOne={() => closeScheduleOneToOne(elem)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                )
                                            }
                                            })
                                        : 'You do not have any tutoring session scheduled'}

                                </div>
                                </div> : null}
                            </div> : null}
                                    {/* <div
                                        className={`${status === 'oneToOne' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                        id="profile"
                                        role="tabpanel"
                                        aria-labelledby="profile-tab"
                                    >
                                        <div
                                            className="space-y-3.5 max-h-925 overflow-y-auto px-3.5"
                                            id="session-container"
                                        >
                                            {consts.length
                                                ? sortedAppointments?.map((elem) => (
                                                    <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        open={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        data={elem}
                                                        participants={participants}
                                                        date={props?.location?.state?.calendarDate}
                                                        openSchedule={() => openSchedule(elem.title)}
                                                        closeSchedule={() => closeSchedule(elem.title)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                ))
                                                : null}
                                        </div> */}
                                    {/* </div> */}
                                    {/* <div
                                        className={`${status === 'group' && 'hidden '} py-4 bg-gray-50 rounded-lg dark:bg-gray-800`}
                                        id="dashboard"
                                        role="tabpanel"
                                        aria-labelledby="dashboard-tab"
                                    >
                                        <div
                                            className="space-y-3.5 max-h-925 overflow-y-auto px-3.5"
                                            id="session-container"
                                        >
                                            {one2one?.length
                                                ? oneToOneSorted?.map((elem) => (
                                                    <TutorSessionStudents
                                                        status={elem.status}
                                                        type={status}
                                                        openOne={elem.is_open}
                                                        numOfPersons={elem.persons}
                                                        oneToOne={elem}
                                                        date={props?.location?.state?.calendarDate}
                                                        openScheduleOnetoOne={() => openScheduleOnetoOne(elem)}
                                                        closeScheduleOnetoOne={() => closeScheduleOneToOne(elem)}
                                                        enlargePersons={() =>
                                                            togglePersons(elem.id, 'enlarge')
                                                        }
                                                        reducePersons={() => togglePersons(elem.id, 'reduce')}
                                                        book={() => setModal(true)}
                                                    />
                                                ))
                                                : null}
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                        </Container>
                    </div>
                </div>
            }
        </>
    );
};

const mapStateToProps = state => ({
    sessionLoading: tutorGroupSessionLoading(state),
    sessionError: tutorGroupSessionError(state),
    one2one: tutorOne2OneData(state),
    participants: groupParticipantsData(state),
    tutorGroupSessions: getGroupSessionData(state),
});

const mapDispatchToProps = dispatch => ({
    createGroupSession: (body) => dispatch(createGroupSession(body)),
    createOneToOneSession: (body) => dispatch(createOneToOneSession(body)),
    getTutorOne2One: (slug) => dispatch(getTutorOne2One(slug)),
    getGroupParticipants: (slug, date) => dispatch(getGroupParticipants(slug, date)),
    getGroupSession: () => dispatch(getGroupSession()),

});
export default connect(mapStateToProps, mapDispatchToProps)(Sessions);
