import React from "react";

import Container from "../../components/container";
import StudyCard from "../../components/StudyCard";
import libraryImg from "../../assets/newImages/library-img.svg";
import BooksImage from "../../assets/images/books-image.webp";
import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  catalogueData,
  catalogueError,
  catalogueLoading,
} from "../../redux/selectors";
import Button from "../../components/EmptyButton";
import { getCatalogue } from "../../redux/actions";

const YourLibraryPage = (props) => {
  const history = useHistory();

  const { data, loading, getCatalogue } = props;
  const [showCatalogue, setCatalogue] = useState([]);

  useEffect(() => {
    getCatalogue(null);
  }, []);
  useEffect(() => {
    if (data?.data?.length) {
      setCatalogue(data?.data);
    }
  }, [data]);

  return (
    <div className="bg-white bg-subscription-bottom bg-no-repeat bg-contain bg-bottom pt-10 xl:pt-20">
      <Container>
        <div className="flex flex-col-reverse lg:grid lg:grid-cols-12 lg:gap-8 xl:gap-14">
          <div className="lg:col-span-8">
            <h2 className="font-bold text-h5 md:text-h4 custom:mt-14 custom:text-36 leading-5 md:leading-6 xl:leading-12 md:mb-7 lg:mb-10 xl:mb-12">
              Your library
            </h2>
            <p className="text-black text-16 font-500 leading-8 xl:leading-10 xl:text-18">
              On your library page, you'll discover all the study modules you
              have purchased.
            </p>
            <p className="text-black text-16 font-500 leading-8 xl:leading-10 xl:text-18">
              The distinction between purchasing a subscription or a study
              module individually:
            </p>
            <ul className="mt-5 xl:mt-30" style={{ listStyle: "none" }}>
              <li className="flex gap-3" style={{ marginBottom: "20px" }}>
                <span className="flex-shrink-0 custom-bullet"></span>
                <span className="text-black text-16 font-500 leading-5 xl:leading-6 xl:text-18">
                  Buying study modules individually means a one-time payment.
                  Afterward, the purchased study module gets added to your
                  library forever.
                </span>
              </li>
              <li className="flex gap-3" style={{ marginBottom: "20px" }}>
                <span className="flex-shrink-0 custom-bullet"></span>
                <span className="text-black text-16 font-500 leading-5 xl:leading-6 xl:text-18">
                  Opting for a subscription grants access to all our study
                  materials for English law degree, but only for a limited time.
                  Subscription renews automatically through recurring payments
                  until cancellation.
                </span>
              </li>
            </ul>
          </div>
          <div className="lg:col-span-4 flex items-end justify-center">
            <img src={libraryImg} alt="library-img" />
          </div>
        </div>
        <div className="flex flex-col">
          <h3 className="font-bold text-h5 md:text-h4 custom:mt-14 custom:text-36 leading-5 md:leading-6 xl:leading-12 mt-4">
            Study Materials
          </h3>
          <div className="flex flex-col md:grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 md:gap-6 xl:gap-8 custom:grid custom:grid-cols-3 xl:flex xl:flex-row xl:justify-center w-full">
            {showCatalogue.length > 0
              ? showCatalogue?.map((item, index) => {
                  if (item.is_paid === true) {
                    return (
                      <>
                        <div className="mt-10 xl:mt-0">
                          <StudyCard
                            image={item.study_module.image_url}
                            alt="Statue"
                            item={item}
                            cardTitle={item?.study_module.title}
                            cardDescription={item?.study_module.university}
                          />
                        </div>
                      </>
                    );
                  }
                })
              : "No Item in Library"}
          </div>
        </div>
        <div className="pt-20 xl:pt-28 flex flex-col items-center gap-5 md:gap-10 md:flex-row custom:justify-center max-w-1160 mx-auto">
            {/* <Button filled onClick={() => history.push("/subscription")}>
              <p className="text-center text-14 xl:text-body-large">
                Get All Contract Law <br /> Materials FOR ONLY £20
              </p>
            </Button>
          </div>
          <div>
            <p className="my-8 font-bold text-17 md:mx-5 xl:mx-7">OR</p>
          </div>
          <div className="w-11/12 custom:w-1/3 xl:w-80">
            <Button filled onClick={() => history.push("/subscription")}>
              <p className="text-center text-14 xl:text-body-large">
                Get all the Study Modules <br />
                for £7.5/month
              </p>
            </Button> */}
            <Button onClick={() => history.push("/")}>
              <p className="text-center text-14 xl:text-body-large">
              Unlock access to all study materials with a subscription
              </p>
            </Button>
            <Button onClick={() => history.push("/")}>
              <p className="text-center text-14 xl:text-body-large">
              Buy study modules individually to access them forever
              </p>
            </Button>
        </div>
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: catalogueLoading(state),
  error: catalogueError(state),
  data: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(YourLibraryPage);
