import React, { useState } from "react";
import { apiRequest, getApiKey, getUser, slugToText } from "../../helpers";
import { useEffect } from "react";
import Button from "../../components/EmptyButton";
import { Modal } from "react-responsive-modal";
import { useHistory, Link } from "react-router-dom";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import Input from "../../components/Input/InputComponent";
import { connect, useDispatch } from "react-redux";
import information from "../../assets/icons/information.svg";
import { createErrorAlert, userLogin, userSignUp } from "../../redux/actions";
import convertTextToSlug from "../../helpers/textToSlug";
import img1 from "../../assets/newImages/Group 1399.png";
import img3 from "../../assets/newImages/Group 1403.png";
import img2 from "../../assets/newImages/Illustration.png";

const Cases = (props) => {
  const {
    title,
    text,
    underline,
    location,
    userLogin,
    userSignUp,
    paidModules,
    currentModule,
  } = props;
  
  const history = useHistory();
  const dispatch = useDispatch();
  const user = JSON.parse(getUser())

  const [state, setState] = useState({
    isTextSelected: false,
    signupModal: false,
    showPassword: false,
    checkBox: false,
    showConfirmPassword: false,
    loginModal: false,
    loader: false,
    shouldBlur: false,
    scroll: false,
    shouldText: text,
    textSlug: convertTextToSlug(currentModule),
    currentLocation: window.location.pathname
  })

  const validateSchemaSignup = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    username: Yup.string()
      .required("Username is required")
      .min(5, "Minimum 5 characters")
      .max(26, "Maximum 26 characters"),
    password2: Yup.string().oneOf(
      [Yup.ref("password1"), null],
      "Passwords must match"
    ),
    password1: Yup.string()
      .required("Password is required.")
      .min(7, "Password is too short - should be 6+ chars minimum.")
      .matches(
        /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/,
        "Password should have at least one capital letter & one digit."
      ),
  });
  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });

  useEffect(() => { 
    setState((prev) => ({
      ...prev,
      shouldText: text
    }))
  },[text])


  // useEffect(() => {
  //   const handleResize = async () => {
  //     if (
  //       !getApiKey() ||
  //       (user?.subscription_name === "Basic Subscription" &&
  //         user?.free_trial === false &&
  //         user?.user_type !== "TEACHER" &&
  //         !paidModules?.includes(state.textSlug))
  //     ) {
  //       let pTags =
  //         document.getElementById("content") || document.getElementById("blur");
  //       let childPTags = pTags?.querySelectorAll("p");
  //       let pLength = childPTags?.length;
  //       let blurCount = Math.floor(pLength * 0.5);
  //       let paraHalf;
  //       if (window.innerWidth <= 768) {
  //         if (childPTags?.length > 0) {
  //           if (childPTags?.length > 1) {
  //             const pLast60Percent = Array.from(childPTags).slice(blurCount);
  //             pLast60Percent.forEach((p) => (p.style.filter = "blur(5px)"));
  //           } else if (childPTags?.length === 1) {
  //             for (let i = 0; i < childPTags?.length; i++) {
  //               const midpoint = Math.ceil(childPTags[i]?.innerHTML.length / 2);
  //               const firstHalf = childPTags[i]?.innerHTML.slice(0, midpoint);

  //               paraHalf = document.createElement("p");
  //               paraHalf.innerHTML = firstHalf;
  //               if (text.includes(firstHalf)) {
  //                 let result = text.replace(firstHalf, " ");
  //                 childPTags[i].innerHTML = result;
  //               }
  //             }
  //             childPTags[0]?.parentNode?.insertBefore(paraHalf, childPTags[0]);
  //             childPTags[0].style.filter = "blur(5px)";
  //           }
  //         }
  //       } else {
  //         if (childPTags?.length > 0) {
  //           if (childPTags?.length > 1) {
  //             const pLast60Percent = Array.from(childPTags).slice(blurCount);
  //             setState((prev) => ({
  //               ...prev, 
  //               shouldBlur: true
  //             }))
  //             pLast60Percent.forEach((p) => (p.style.filter = "blur(5px)"));
  //           } else if (childPTags?.length === 1) {
  //             for (let i = 0; i < childPTags?.length; i++) {
  //               const midpoint = Math.ceil(childPTags[i]?.innerHTML.length / 2);
  //               const firstHalf = childPTags[i]?.innerHTML.slice(0, midpoint);

  //               paraHalf = document.createElement("p");
  //               paraHalf.innerHTML = firstHalf;
  //               if (text.includes(firstHalf)) {
  //                 let result = text.replace(firstHalf, " ");
  //                 childPTags[i].innerHTML = result;
  //               }
  //             }
  //             childPTags[0]?.parentNode?.insertBefore(paraHalf, childPTags[0]);
  //             setState((prev) => ({
  //               ...prev, 
  //               shouldBlur: true
  //             }))
  //             childPTags[0].style.filter = "blur(5px)";
  //           }
  //         }
  //       }
  //     } else {
  //       await apiRequest({
  //         method: "get",
  //         url: "studies/studymodules",
  //       }).then((res) => {
  //         for(let i=0; i<res.data.length; i++){
  //           if(res.data[i].slug === currentModule){
  //             if(res.data[i].author !== 1){
  //               if(paidModules.includes(res.data[i].slug)){
  //                 setState((prev) => ({
  //                   ...prev,
  //                   shouldBlur: false
  //                 }))
  //               } else{
  //                 setState((prev) => ({
  //                   ...prev,
  //                   shouldBlur: true
  //                 }))
  //               }
  //             }
  //           }
  //         }
  //       });
  //     }
  //   };
  //   window.addEventListener("resize", handleResize);

  //   handleResize();

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //   };
  // }, [text, user]);

  const userDetail = () => {
    history.push("/subscription");
  };

  const handleTextSelection = () => {
    if (state.scroll === false) {
      setState((prev) => ({
        ...prev,
        isTextSelected: true
      }))
    }
  };

  const forgetPass = () => {
    setState((prev) => ({
      ...prev, 
      loginModal: false
    }))
    history.push("/forget-password");
  };

  const handleUserLogin = async (body) => {
    setState((prev) => ({
      ...prev, 
      loader: true
    }))
    userLogin(body, history);
  };

  const onSignUp = async (values) => {
    setState((prev) => ({
      ...prev, 
      loader: true
    }))
    if (state.checkBox === true) {
      userSignUp(values, history);
    } else {
      dispatch(
        createErrorAlert(
          201,
          "Error",
          "Please agree to the terms and Conditions",
          201
        )
      );
    }
  };
  const checked = (e) => {
    if (e === true) {
      setState((prev) => ({
        ...prev, 
        checkBox: true
      }))
    } else {
      setState((prev) => ({
        ...prev, 
        checkBox: false
      }))
    }
  };

  return (
    <>
      {state.currentLocation === `/${location.slug}/` ||
      state.currentLocation === location ? (
        <div className="pt-2 md:pt-4 lg:pt-8" style={{ userSelect: "none" }}>
          <p className="custom:text-h5 xl:text-h3 font-bold pb-1 md:pb-3 lg:pb-5">
            {title}
          </p>
          <p
            id={`content`}
            className={`${
              underline && "font-bold underline"
            } custom:text-14 xl:text-body-large alink`}
            dangerouslySetInnerHTML={{ __html: state.shouldText }}
            onMouseUp={!getApiKey() ? handleTextSelection : handleTextSelection}
            onScroll={() =>  setState((prev) => ({
              ...prev, 
              scroll: true
            }))}
          ></p>
          {/* {user?.subscription_name === "Basic Subscription" &&
          user?.free_trial === false &&
          user?.user_type !== "TEACHER" &&
          !paidModules?.includes(state.textSlug) ? (
            <div className="px-4 md:px-0 w-full md:w-full py-4 lg:ml-10">
              <div className="border-2 rounded-lg bg-white py-4 w-full px-4">
                <p className="text-16 md:text-body-large font-normal mb-3 text-center">
                  You currently have a free subscription that only gives you
                  access to limited content. You can unlock for just £1.99/month
                  to improve your grades and save thousands of hours in reading
                </p>
                <Button filled onClick={() => userDetail()}>
                  <p
                    className="text-16 md:text-body-large font-normal"
                    style={{ textAlign: "center" }}
                  >
                    Upgrade your membership to view the full Case Summary
                  </p>
                </Button>
              </div>
            </div>
          ) : null} */}
          <Modal
            open={state.isTextSelected}
            onClose={() => setState((prev) => ({
              ...prev,
              isTextSelected: false
            }))}
            focusTrapped={false}
            center
            className
            classNames={{
              overlay: "custom-overlay-class",
              modal: "react-modal-class2 errorModalMain",
            }}
          >
            <div className="flex flex-col justify-center items-center w-full ">
              <div className="flex flex-row w-full items-center justify-center my-8 absolute -top-10 left-0 ">
                <img src={information} alt="" className="w-14 h-auto" />
              </div>
              <div className="w-10/12 ">
                {user?.user_type === "STUDENT" || !getApiKey() ? (
                  <p>
                    We do not allow selecting and copying of our content. You
                    can get our subscription to have unlimited access to our
                    study materials and maximise your chances of getting a First
                    Class.
                  </p>
                ) : (
                  <p>We do not allow selecting and copying of our content.</p>
                )}
              </div>
              {!getApiKey() && (
                <div className="flex flex-col justify-center items-center md:justify-between md:flex-row md:space-x-5 w-full mt-4 md:mt-10">
                  <div className="w-56">
                    <Button
                      text={"Login"}
                      simple
                      onClick={() => setState((prev) => ({
                        ...prev,
                        loginModal: true
                      }))}
                    ></Button>
                  </div>
                  <div className="w-56 mt-5 md:mt-0">
                    <Button
                      text={"Sign Up"}
                      filled
                      onClick={() => setState((prev) => ({
                        ...prev,
                        signupModal: true
                      }))}
                    ></Button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
         
          <Modal
            open={state.loginModal}
            onClose={() => setState((prev) => ({ ...prev, loginModal: false }))}
            focusTrapped={false}
            center
            classNames={{
              overlay: "custom-overlay-class",
              modal: "react-modal-class1",
            }}
          >
            <div className="flex gap-6">
              <div className="  flex-basis-60 w-full hidden md:block">
                <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                  Join the movement of{" "}
                  <span className="text-yellowish">20K savvy students</span>{" "}
                  already empowered - and you're next
                </p>

                <p className="pr-2 text-body-small text-left pt-6  md:text-h5 xl:text-36 ">
                  Discover the secret to success as you become part of our
                  growing community.
                </p>
                <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                  <div className=" flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-116 h-100">
                        <img src={img1} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                    <p className="h-51">
                      Study Modules
                      <br />
                      All main areas of law
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-100 h-100">
                        <img src={img2} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="fw-bold text-42  text-yellowish mt-4">
                      500K+
                    </p>
                    <p>
                      Users
                      <br />
                      Every Year
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-100 h-100">
                        <img src={img3} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="fw-bold text-42  text-yellowish mt-4">
                      1430+
                    </p>
                    <p className="h-51">
                      Hours Tutoring <br />
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-basis-40">
                <Formik
                  initialValues={{
                    password: "",
                    username: "",
                  }}
                  validationSchema={validateSchema}
                  onSubmit={(values) => handleUserLogin(values)}
                >
                  {({ isValid, handleSubmit, values }) => (
                    <Form>
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <div className="loginInputMain flex flex-col text-darkest items-center">
                          <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                            Sign in to{" "}
                            <span className="text-yellowish">
                              SimpleStudying
                            </span>
                          </p>
                          <div className="w-full mb-3 custom2::mb-9 ">
                            <p className="mb-8">Username or Email</p>
                            <Field
                              name="username"
                              as={Input}
                              autoComplete="off"
                              placeholder="Enter your username or email"
                              type="text"
                            />
                          </div>
                          <div className="w-full  mb-3 custom2:mb-9">
                            <p className="mb-8 ">Password</p>
                            <Field
                              name="password"
                              as={Input}
                              placeholder="Enter your password"
                              type={state.showPassword ? "text" : "password"}
                              setShowPassword={setState}
                              showPassword={state.showPassword}
                              inputType="password"
                              autoComplete="off"
                            />
                          </div>
                          <Link
                            className="mb-8 forgetText "
                            onClick={() => {
                              setState((prev) => ({
                                ...prev,
                                loginModal: false,
                              }));
                              history.push("/forget-password");
                            }}
                          >
                            Forget Password?
                          </Link>
                        </div>
                        <div className="pt-4 flex justify-center mb-10">
                          <div className="w-full h:34 md:h-42 lg:h-51">
                            <Button
                              onClick={handleSubmit}
                              text="Log in"
                              filled
                              className="buttonClass"
                              disabled={
                                values.username === "" ||
                                values.password === "" ||
                                !isValid
                              }
                              loading={state.loader}
                            />
                          </div>
                        </div>
                      </form>
                    </Form>
                  )}
                </Formik>
                <p className="loginBottomText font-normal text-darkest font-body-large text-center">
                  You don't have an account yet?{" "}
                  <a
                    className="font-bold text-primary cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        loginModal: false,
                        signupModal: true,
                      }));
                    }}
                  >
                    {"  "}Sign up
                  </a>
                </p>
              </div>
            </div>
          </Modal>

          {/* Sign Up Modal */}
          <Modal
            open={state.signupModal}
            onClose={() =>
              setState((prev) => ({
                ...prev,
                signupModal: false,
              }))
            }
            focusTrapped={false}
            center
            classNames={{
              overlay: "custom-overlay-class",
              modal: "react-modal-class1",
            }}
          >
            <div className="flex gap-6">
              <div className="  flex-basis-60 w-full hidden md:block">
                <p className="text-body-large  font-bold text-left md:text-h3 xl:text-36  mr-1">
                  Join the movement of{" "}
                  <span className="text-yellowish">20K savvy students</span>{" "}
                  already empowered - and you're next
                </p>

                <p className=" text-body-small pr-2 text-left pt-6  md:text-h5 xl:text-36 ">
                  Discover the secret to success as you become part of our
                  growing community.
                </p>
                <div className="flex justify-around mt-10 items-center flex-col lg:flex-row">
                  <div className=" flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-116 h-100">
                        <img src={img1} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="  fw-bold text-42 text-yellowish mt-4">35+</p>
                    <p className="h-51">
                      Study Modules
                      <br />
                      All main areas of law
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-100 h-100">
                        <img src={img2} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="fw-bold text-42  text-yellowish mt-4">
                      500K+
                    </p>
                    <p>
                      Users
                      <br />
                      Every Year
                    </p>
                  </div>
                  <div className="flex-1 flex flex-col justify-center items-center text-center">
                    <div className="h-120">
                      <div className="w-100 h-100">
                        <img src={img3} className="m-auto mt-3 w-full h-full" />
                      </div>
                    </div>

                    <p className="fw-bold text-42  text-yellowish mt-4">
                      1430+
                    </p>
                    <p className="h-51">
                      Hours Tutoring <br />
                      Delivered
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex-basis-40">
                <Formik
                  initialValues={{
                    password1: "",
                    username: "",
                    email: "",
                    password2: "",
                  }}
                  validationSchema={validateSchemaSignup}
                  onSubmit={(values) => onSignUp(values)}
                >
                  {({ isValid, handleSubmit, values }) => (
                    <Form>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="flex flex-col text-darkest items-center">
                          <p className="loginHeadText text-darkest font-bold text-body-large mb-10 md:text-h3 xl:text-36 text-center">
                            Sign up to{" "}
                            <span className="text-yellowish">
                              SimpleStudying
                            </span>
                          </p>
                          <div className="w-full mb-3 md:mb-9 ">
                            <p className="mb-8">Email</p>
                            <Field
                              name="email"
                              as={Input}
                              placeholder="Enter your email"
                              type="text"
                            />
                          </div>

                          <div className="w-full mb-3 md:mb-9 ">
                            <p className="mb-8">Username</p>
                            <Field
                              name="username"
                              as={Input}
                              placeholder="Enter your username"
                              type="text"
                            />
                          </div>

                          <div className="w-full ">
                            <p className="mb-8">Password</p>
                            <Field
                              name="password1"
                              as={Input}
                              placeholder="Enter your password"
                              type={state.showPassword ? "text" : "password"}
                              setShowPassword={setState}
                              showPassword={state.showPassword}
                              inputType="password"
                            />
                            <p className="text-small text-darkGrey2 my-8">
                              6+ characters, 1 capital letter{" "}
                            </p>
                          </div>
                          <div className="w-full mb-10 md:mb-14">
                            <p className="mb-8">Confirm password</p>
                            <Field
                              name="password2"
                              as={Input}
                              placeholder="Enter your confirm password"
                              type={
                                state.showConfirmPassword ? "text" : "password"
                              }
                              setShowPassword={setState}
                              showPassword={state.showConfirmPassword}
                              inputType="password"
                            />
                          </div>
                          <div
                            className="w-full mb-10 md:mb-14"
                            style={{ display: "flex" }}
                          >
                            <label className="condContainer">
                              {" "}
                              I agree to{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/terms-conditions");
                                }}
                              >
                                Terms and Conditions
                              </a>
                              ,{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/privacy-policy");
                                }}
                              >
                                Privacy Policy
                              </a>{" "}
                              and{" "}
                              <a
                                style={{ color: "#cc6828", cursor: "pointer" }}
                                onClick={() => {
                                  history.push("/cookies-policy");
                                }}
                              >
                                Cookies Policy
                              </a>{" "}
                              of Simple Studying{" "}
                              <input
                                id="condBox"
                                onClick={(e) => checked(e.target.checked)}
                                type="checkbox"
                                className="default:ring-2 text-primary mr-3 checkBoxOne"
                              />
                              <span className="checkmark"></span>
                            </label>
                          </div>
                        </div>
                        <div className="pt-4 flex justify-center mb-10">
                          <div className="w-full h-51">
                            <Button
                              onClick={handleSubmit}
                              text="Sign up for free"
                              filled
                              className="buttonClass"
                              disabled={
                                values.username === "" ||
                                values.password1 === "" ||
                                values.password2 === "" ||
                                values.email === "" ||
                                !isValid ||
                                values.checked === "false"
                              }
                              loading={state.loader}
                            />
                          </div>
                        </div>
                      </form>
                    </Form>
                  )}
                </Formik>
                <p className="font-normal text-darkest font-body-large text-center">
                  Already have an account?{" "}
                  <a
                    className="font-bold text-primary cursor-pointer"
                    onClick={() => {
                      setState((prev) => ({
                        ...prev,
                        loginModal: true,
                        signupModal: false,
                      }));
                    }}
                  >
                    {"  "}Log in
                  </a>
                </p>
              </div>
            </div>
          </Modal>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

// const mapStateToProps = (state) => ({

// });
const mapDispatchToProps = (dispatch) => ({
  userLogin: (body, history) => dispatch(userLogin(body, history)),
  userSignUp: (body, history) => dispatch(userSignUp(body, history)),
});
export default connect(mapDispatchToProps)(Cases);
