import React, { useState } from "react";
import logo from "../../assets/brand/small_logo.png";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Input from "../../components/Input/InputComponent";
import Button from "../../components/EmptyButton";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import { loginData, loginLoader } from "../../redux/selectors";
import { userLogin } from "../../redux/actions";
import GoogleAuthentication from "../../components/SocialAuthentication/GoogleSocialAuth";
import FacebookAuthentication from "../../components/SocialAuthentication/FacebookSocialAuth";
import { Helmet } from "react-helmet";
import CookieConsent from "react-cookie-consent";
import img1 from "../../assets/newImages/Group 1399.png"
import img3 from "../../assets/newImages/Group 1403.png"
import img2 from "../../assets/newImages/Illustration.png"
import fb from "../../assets/icons/Facebook.png"
import google from "../../assets/icons/Google.png"


const LoginPage = (props) => {
  const { userLogin, loader } = props;
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();
  const validateSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    username: Yup.string().required("Username is required"),
  });
  const login = (values) => {
    userLogin(values, history);
  };
  const forgetPass = () => {
    history.push("/forget-password");
  };

  return (
    <div className="bg-white bg-main-page-back flex justify-center items-center bg-no-repeat bg-contain overflow-hidden" >
      <Helmet>
        <meta charSet="utf-8" />
        <title>Simple Studying | Login</title>
        <meta name="description" content="Login Description" />
        <link rel="canonical" href="https://simplestudying.com/login" />
        <meta name="robots" content="index, follow" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:locale:alternate" content="en_gb" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Simple Studying | Login" />
        <meta property="og:description" content="Login Description" />
        <meta property="og:url" content="https://simplestudying.com/login" />
        <meta property="og:site_name" content="Simple Studying" />
      </Helmet>
      <div className="signup-info " >
        <div className="flex flex-col md:flex-row justify-center  " style={{ minHeight: "95vh" }}>
          <div className="w-2/4 hidden md:block custom2:w-2/4 custom2:pl-220 pl-6 pt-9 ">
            <div className="mb-7 custom2:mb-14 ">
              <div
                className="w-44 h-10 custom2:w-auto custom2:h-auto block "
                onClick={() => history.push("/")}
              >
                <img src={logo} className="img-fluid bigscreen" alt="logo" />
              </div>
            </div>
            {/* <img
              className="hidden md:block overflow-hidden custom2:w-auto custom2:h-auto object-contain" style={{height:"100vh"}}
              src={'https://simplestudying.com/static/media/signupImage.e2b35106.webp'}
              alt="logo"
            /> */}

            <div >

              <p className='text-body-large  font-bold text-left md:text-h3 xl:text-36 mr-1'>
                Join the movement of <span className="text-yellowish">20K savvy students</span> already empowered - and you're next
              </p>

              <p className=" text-body-small text-left pt-6 md:text-h5 xl:text-36 "
               >
                Discover the secret to success as you become part of our growing community.
              </p>
              <div className='flex justify-around mt-10 items-center flex-col lg:flex-row'>
                <div className=' text-center'>
                  <div className='w-150 h-150'>
                    <img src={img1} className='m-auto mt-3 w-full h-full' />
                  </div>
                  <p className='  fw-bold text-42 text-yellowish '>35+</p>
                  <p>Study Modules<br />All main areas of law</p>
                </div>
                <div className=' text-center'>
                  <div className='w-150 h-150'>
                    <img src={img2} className='m-auto mt-3 w-full h-full' />
                  </div>
                  <p className='fw-bold text-42  text-yellowish'>500K+</p>
                  <p>Users<br />Every Year</p>
                </div>
                <div className=' text-center'>
                  <div className='w-150 h-150'>
                    <img src={img3} className='m-auto mt-3 w-full h-full' />
                  </div>
                  <p className='fw-bold text-42  text-yellowish'>1430+</p>
                  <p>Hours Tutoring <br />Delivered</p>
                </div>
              </div>

            </div>
          </div>
          <div className="md:bg-white flex flex-col w-full md:w-2/4 custom2:w-2/4 py-4 md:py-12 lg:py-20 px-4 md:px-7 custom2:pr-40 mac:pr-80 custom2:pl-40 overflow-hidden">
          <div className="w-fit h-10 custom2:w-auto my-14 m-auto md:hidden custom2:h-auto block"
                                onClick={() => history.push('/')}>
                                <img
                                    src={logo}
                                    className="img-fluid bigscreen"
                                    alt="logo"
                                />
                            </div>

            <p className="loginHeadText text-darkest font-bold text-large-medium custom2:text-36 text-center">
              Sign in to SimpleStudying
            </p>
            <div className="flex w-80% rounded-lg p-1 border-slate-300 mx-auto mb-1">
            <div className="flex m-auto border-2 border-ligthBorderGrey rounded-xl p-1 w-full justify-center">
                <img src={google} className="mr-2" />

                <button className="border-1">Continue with Google</button>
              </div>


            </div>
            <div className="flex  w-80% rounded-lg p-1 border-slate-300 mx-auto mt-0">
            <div className="flex m-auto border-2 border-ligthBorderGrey rounded-xl p-1 w-full justify-center">

                <img src={fb} className="mr-2" />
                <button className="border-1">Continue with Facebook</button>
              </div>

            </div>
            {/* <GoogleAuthentication />
            <FacebookAuthentication /> */}
            <div className="flex flex-row justify-center items-center px-2 lg:px-6 my-2 lg:my-7">
              {/* <div className="border border-ligthBorderGrey w-2/5"></div>
              <p className="px-3.5 text-darkest font-body-large ">or</p>
              <div className="border border-ligthBorderGrey w-2/5"></div> */}
            </div>
            <Formik
              initialValues={{
                password: "",
                username: "",
              }}
              validationSchema={validateSchema}
              onSubmit={(values) => login(values)}
            >
              {({ isValid, handleSubmit, values }) => (
                <Form>
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div className="loginInputMain flex flex-col text-darkest items-center">
                      <div className="w-full mb-3 custom2::mb-9 ">
                        <p className="mb-8">Username or Email</p>
                        <Field
                          name="username"
                          as={Input}
                          autoComplete="off"
                          placeholder="Enter your username or email"
                          type="text"
                        />
                      </div>
                      <div className="w-full  mb-3 custom2:mb-9">
                        <p className="mb-8 ">Password</p>
                        <Field
                          name="password"
                          as={Input}
                          placeholder="Enter your password"
                          type={showPassword ? "text" : "password"}
                          setShowPassword={setShowPassword}
                          showPassword={showPassword}
                          inputType="password"
                          autoComplete="off"
                        />
                      </div>
                      <Link className="mb-8 forgetText " onClick={forgetPass}>
                        Forgot Password?
                      </Link>
                    </div>
                    <div className="pt-4 flex justify-center mb-10">
                      <div className="w-full h:34 md:h-42 lg:h-51">
                        <Button
                          onClick={handleSubmit}
                          text="Log in"
                          filled
                          className="buttonClass"
                          disabled={
                            values.username === "" ||
                            values.password === "" ||
                            !isValid
                          }
                          loading={loader}
                        />
                      </div>
                    </div>
                  </form>
                </Form>
              )}
            </Formik>
            <p className="loginBottomText font-normal text-darkest font-body-large text-center">
              You don't have an account yet?{" "}
              <a
                className="font-bold text-primary cursor-pointer"
                onClick={() => history.push("/signup")}
              >
                {"  "}Sign up
              </a>
            </p>
            <CookieConsent
              enableDeclineButton
              location="bottom"
              declineButtonText="Privacy Policy"
              declineButtonStyle={{ color: "red", fontSize: "18px", marginBottom: '20px', backgroundColor: '#fff' }}
              buttonText="OK"
              cookieName="CookiesPolicy"
              style={{ background: "#2B373B", zIndex: "9999" }}
              buttonStyle={{ color: "#fff", fontSize: "18px", marginBottom: '-20px', backgroundColor: '#cc6828' }}
              expires={150}
              onDecline={() => {
                history.push('/privacy-policy')
              }}
            >
              <span style={{ fontWeight: 'bold', fontSize: '20px' }}>We value your privacy</span> <br />
              We use cookies to enhance your browsing experience, serve personalised ads or content, and analyse our traffic. If you continue to use this site we will assume that you are happy with it.{" "}
            </CookieConsent>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  loader: loginLoader(state),
  data: loginData(state),
});
const mapDispatchToProps = (dispatch) => ({
  userLogin: (body, history) => dispatch(userLogin(body, history)),
});
export default connect(mapStateToProps, mapDispatchToProps)(LoginPage);
