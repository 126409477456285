import React, { useState, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Container from "../../components/container";
import Button from "../../components/EmptyButton";
import Input from "../../components/Input/InputForm";
import ShoppingCard from "../../components/ShoppingCard";
import { getCatalogue } from "../../redux/actions";
import {
  catalogueData,
  catalogueError,
  catalogueLoading,
} from "../../redux/selectors";
import FullLoader from "../../components/Loader/FullLoader";
import { apiRequest } from "../../helpers";

const ShoppingBasket = (props) => {
  const { data, loading, getCatalogue } = props;

  const history = useHistory();

  const [state, setState] = useState({
    discountCode: '',
    showCatalogue: [],
    price: 0,
    priceId: []
  })

  useEffect(() => {
    getCatalogue(null);
  }, []);
  useEffect(() => {
    if (data?.data?.length) {
      setState((prev) => ({
        ...prev,
        showCatalogue: data?.data
      }))
    } else {
      setState((prev) => ({
        ...prev,
        showCatalogue: data?.data,
        price: 0
      }))
    }
    for (let i = 0; i < data?.data?.length; i++) {
      if (
        !state.priceId.includes(data.data[i].study_module.price_id) &&
        data.data[i].is_paid === false
      ) {
        state.priceId.push(data.data[i].study_module.price_id);
      }
      let newTotalPrice = 0;

      for (let i = 0; i < data?.data.length; i++) {
        if (data?.data[i].is_paid === false) {
          const priceModule = data?.data[i]?.study_module?.price;
          newTotalPrice += parseInt(priceModule);
        }
      }

      setState((prev) => ({
        ...prev,
        price: newTotalPrice
      }))
    }
  }, [data]);

  const deleteItem = async (id) => {
    const cards = [...state.showCatalogue];

    for (let index = 0; index < cards.length; index++) {
      const elem = cards[index];

      if (elem.id === id) {
        let findElement = state.priceId.find(
          (item) => item === elem.study_module.price_id
        );

        if (findElement !== undefined) {
          const indexToDelete = state.priceId.indexOf(findElement);

          if (indexToDelete !== -1) {
            state.priceId.splice(indexToDelete, 1);
            setState((prev) => ({
              ...prev,
              priceId: [...state.priceId]
            }))
          }
        }

        const res = await getCatalogue(id);

        if (res.status === 200) {
          getCatalogue(null);
        }

        break;
      }
    }
  };

  const checkout = async () => {
    if (state.priceId.length > 0) {
      await apiRequest({
        method: "post",
        url: "content/module-payment/",
        data: { price_id: state.priceId },
      }).then((res) => {
        window.location.href = res.data.checkout_url;
      });
    }
  };

  return (
    <>
      {loading ? <FullLoader></FullLoader> : null}
      <div className="custom2:pb-24 bg-basket-bg bg-white">
        <div className="">
          <Container>
            <div className="pt-10 lg:pt-20">
              <p className="tutorialVidText text-h3 md:text-36 font-bold text-darkest">
                Shopping Basket
              </p>
              {state.showCatalogue?.length > 0 && (
                <p className="md:text-body-large text-darkest mt-5 custom:mt-12">
                  You selected the following content:
                </p>
              )}
            </div>
            <div className="lg:flex mt-9 space-y-10 lg:space-y-0 lg:space-x-7">
              <div className="flex-1 space-y-5">
                {state.showCatalogue?.length > 0
                  ? state.showCatalogue?.map((elem, index) => {
                      if (elem.is_paid === false) {
                        return (
                          <ShoppingCard
                            key={index}
                            image={elem.study_module.image}
                            title={elem.study_module.title}
                            image={elem.study_module.image_url}
                            university={elem.study_module.university}
                            classNumber={elem.grade ? elem.grade : "1st Class"}
                            price={
                              elem.study_module.price !== null
                                ? elem.study_module.price
                                : "99.99"
                            }
                            currency={"£"}
                            onDelete={() => deleteItem(elem.id)}
                          />
                        );
                      }
                    })
                  : "No Data to Display"}
              </div>
              <div className="w-full lg:w-300 mac:w-472">
                <div
                  className="p-8 mac:p-12  mb-10 bg-white"
                  style={{
                    borderRadius: "14px",
                    boxShadow: " 4px 8px 20px rgba(2, 23, 54, 0.03)",
                  }}
                >
                  <div>
                    <p className="text-h5 mac:text-h3 text-darkest  font-bold ">
                      Summary
                    </p>
                  </div>
                  <div className="lg:mt-7 flex flex-col space-y-3 mac:space-y-0 mac:flex-row mac:items-end mac:space-x-5">
                    <div>
                      <label className="mb-8 text-13  mac:text-body-large">
                        Do you have any discount code?
                      </label>
                      <Input
                        placeholder=""
                        value={state.discountCode}
                        setValue={(value) => {
                          setState((prevState) => ({
                            ...prevState,
                            discountCode: value,
                          }));
                        }}
                      />
                    </div>
                    <div className="flex-1">
                      <Button filled disabled={isNaN(state.price) ? true : false}>
                        <p className="text-center text-small-text xl:text-body-large">
                          Apply
                        </p>
                      </Button>
                    </div>
                  </div>
                  <div className="flex flex-row justify-between items-center text-darkGrey text-13 mac:text-15 mb-4 mac:mb-6 mt-7 mac:mt-12">
                    <div className="flex justify-start w-2/4 ">
                      <p>Subtotal:</p>
                    </div>
                    <div className="flex justify-end w-2/4">
                      <p>£{isNaN(state.price) ? "0" : state.price}</p>
                    </div>
                  </div>

                  <div className="flex flex-row justify-between items-center text-darkest text-15 mac:text-body-large font-bold mb-11">
                    <div className="flex justify-start w-2/4 ">
                      <p>Total price:</p>
                    </div>
                    <div>
                      <p>£{isNaN(state.price) ? "0" : state.price}</p>
                    </div>
                  </div>

                  <div className="flex flex-col">
                    <div className="mb-7 w-full">
                      <Button
                        filled
                        disabled={state.priceId.length === 0 ? true : false}
                        onClick={checkout}
                      >
                        <p className="text-center text-small-text xl:text-body-large">
                          Make payment
                        </p>
                      </Button>
                    </div>
                  </div>
                  <p
                    onClick={() => history.push("/")}
                    className="text-primary text-15 cursor-pointer mac:text-body-large text-14 font-bold text-center"
                  >
                    Add more study materials
                  </p>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: catalogueLoading(state),
  error: catalogueError(state),
  data: catalogueData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getCatalogue: (id) => dispatch(getCatalogue(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ShoppingBasket);
