import React, { useEffect, useState } from 'react';

import LazyImage from '../../components/LazyImage';
import Button from '../../components/EmptyButton';
import Container from '../../components/container';
import StudyMaterial from '../../components/StudyMaterials';
import StudyMaterialCard from '../../components/StudyMaterialCard';
import VideoPlayer from '../../components/VideoPlayer';
import Slider from '../../components/MainPageSlider';

import Trophy from '../../assets/icons/trophy.svg';
import Essays from '../../assets/images/main-page-materials/essays.webp';
import notes from '../../assets/images/main-page-materials/notes.webp';
import platform from '../../assets/images/main-page-materials/platform.svg';
import quiz from '../../assets/images/main-page-materials/quiz.webp';
import tips from '../../assets/images/main-page-materials/tips.svg';
import tutorials from '../../assets/images/main-page-materials/tutorials.webp';
import HandImage9 from '../../assets/images/hand-image-9.webp';
import HandImage8 from '../../assets/images/hand-image-8.webp';
import Underline from '../../assets/icons/underline.svg';
import FistClassUnderline from '../../assets/icons/fistClassUnderline.svg';
import SimpleUnderline from '../../assets/icons/simple-underline.svg';
import SpUnderline from '../../assets/icons/sp-underline.svg';
import {useHistory} from 'react-router-dom';
import {Helmet} from "react-helmet";
import { connect } from 'react-redux';
import { getMainPdf } from '../../redux/actions';
import { mainPdfData } from '../../redux/selectors';
import img1 from "../../assets/newImages/Group 1399.png"
import img3 from "../../assets/newImages/Group 1403.png"
import img2 from "../../assets/newImages/Illustration.png"

const SliderCard = ({name, message, timePosted, img}) => {
    return (
        <div className="h-full ml-4 pl-4 xl:pl-10 xl:w-680 xl:h-80 bg-white bg-slider-card bg-no-repeat bg-center flex"
             style={{borderRadius: '25px', boxShadow: '0px 7px 25px rgba(0, 0, 0, 0.12)'}}>
            <div className="w-2/4 sm:w-3/4 custom:w-3/4">
                <p className="mt-6 text-16 custom:mt-20 custom:text-body-large"><span
                    className="text-primary">— </span>{name}</p>
                <p className="mt-2.5 h-32 text-small-text custom:mt-5 custom:text-h5 font-bold">{message}</p>
                <p className="mt-12 sm:mt-4 mb-4 text-13 custom:text-15 custom:mt-6 opacity-30">{timePosted}</p>
            </div>
            <div className="mt-11 custom:w-1/4 flex justify-center">
                <LazyImage src={img}/>
            </div>
        </div>
    );
};

const MainPage = (props) => {

    const {getPdfs, pdfData} = props
    const history = useHistory();
    const [badge, setBadge] = useState(true)
    useEffect(()=>{
        getPdfs()
        if(window.location.pathname === '/'){
            new window.carouselInlineWidget('reviewsio-carousel-widget',{
                /*Your REVIEWS.io account ID:*/
                store: 'www.simplestudying.com',
                sku: '',
                lang: 'en',
                carousel_type: 'default',
                styles_carousel: 'CarouselWidget--sideHeader',
          
                /*Widget settings:*/
                options:{
                  general:{
                    /*What reviews should the widget display? Available options: company, product, third_party. You can choose one type or multiple separated by comma.*/
                    review_type: 'company, product',
                    /*Minimum number of reviews required for widget to be displayed*/
                    min_reviews: '1',
                    /*Maximum number of reviews to include in the carousel widget.*/
                    max_reviews: '20',
                    address_format: 'CITY, COUNTRY',
                    /*Carousel auto-scrolling speed. 3000 = 3 seconds. If you want to disable auto-scroll, set this value to false.*/
                    enable_auto_scroll: 10000,
                  },
                  header:{
                    /*Show overall rating stars*/
                    enable_overall_stars: true,
                    rating_decimal_places: 2,
                  },
                  reviews: {
                    /*Show customer name*/
                    enable_customer_name: true,
                    /*Show customer location*/
                    enable_customer_location: true,
                    /*Show "verified review" badge*/
                    enable_verified_badge: true,
                    /*Show "verified subscriber" badge*/
                    enable_subscriber_badge: true,
                    /*Show "I recommend this product" badge (Only for product reviews)*/
                    enable_recommends_badge: true,
                    /*Show photos attached to reviews*/
                    enable_photos: true,
                    /*Show videos attached to reviews*/
                    enable_videos: true,
                    /*Show when review was written*/
                    enable_review_date: true,
                    /*Hide reviews written by the same customer (This may occur when customer reviews multiple products)*/
                    disable_same_customer: true,
                    /*Minimum star rating*/
                    min_review_percent: 4,
                    /*Show 3rd party review source*/
                    third_party_source: true,
                    /*Hide reviews without comments (still shows if review has a photo)*/
                    hide_empty_reviews: true,
                    /*Show product name*/
                    enable_product_name: true,
                    /*Show only reviews which have specific tags (multiple semicolon separated tags allowed i.e tag1;tag2)*/
                    tags: "",
                    /*Show branch, only one input*/
                    branch: "",
                    enable_branch_name: false,
                  },
                  popups: {
                    /*Make review items clickable (When they are clicked, a popup appears with more information about a customer and review)*/
                    enable_review_popups:  true,
                    /*Show "was this review helpful" buttons*/
                    enable_helpful_buttons: true,
                    /*Show how many times review was upvoted as helpful*/
                    enable_helpful_count: true,
                    /*Show share buttons*/
                    enable_share_buttons: true,
                  },
              },
              translations: {
                verified_customer:  "Verified Customer",
              },
              styles:{
                /*Base font size is a reference size for all text elements. When base value gets changed, all TextHeading and TexBody elements get proportionally adjusted.*/
                '--base-font-size': '16px',
                '--base-maxwidth':'100%',
          
                /*Logo styles:*/
                '--reviewsio-logo-style':'var(--logo-normal)',
          
                /*Star styles:*/
                '--common-star-color':' #0E1311',
                '--common-star-disabled-color':' rgba(0,0,0,0.25)',
                '--medium-star-size':' 22px',
                '--small-star-size':'19px', /*Modal*/
                '--x-small-star-size':'16px',
                '--x-small-star-display':'inline-flex',
          
                /*Header styles:*/
                '--header-order':'1',
                '--header-width':'160px',
                '--header-bg-start-color':'transparent',
                '--header-bg-end-color':'transparent',
                '--header-gradient-direction':'135deg',
                '--header-padding':'0.5em',
                '--header-border-width':'0px',
                '--header-border-color':'rgba(0,0,0,0.1)',
                '--header-border-radius':'0px',
                '--header-shadow-size':'0px',
                '--header-shadow-color':'rgba(0, 0, 0, 0.1)',
          
                /*Header content styles:*/
                '--header-star-color':'inherit',
                '--header-disabled-star-color':'inherit',
                '--header-heading-text-color':'inherit',
                '--header-heading-font-size':'inherit',
                '--header-heading-font-weight':'inherit',
                '--header-heading-line-height':'inherit',
                '--header-heading-text-transform':'inherit',
                '--header-subheading-text-color':'inherit',
                '--header-subheading-font-size':'inherit',
                '--header-subheading-font-weight':'inherit',
                '--header-subheading-line-height':'inherit',
                '--header-subheading-text-transform':'inherit',
          
                /*Review item styles:*/
                '--item-maximum-columns':'5',/*Must be 3 or larger*/
                '--item-background-start-color':'transparent',
                '--item-background-end-color':'transparent',
                '--item-gradient-direction':'135deg',
                '--item-padding':'0.5em',
                '--item-border-width':'0px',
                '--item-border-color':'rgba(0,0,0,0.1)',
                '--item-border-radius':'0px',
                '--item-shadow-size':'0px',
                '--item-shadow-color':'rgba(0,0,0,0.1)',
          
                /*Heading styles:*/
                '--heading-text-color':' #0E1311',
                '--heading-text-font-weight':' 600',
                '--heading-text-font-family':' inherit',
                '--heading-text-line-height':' 1.4',
                '--heading-text-letter-spacing':'0',
                '--heading-text-transform':'none',
          
                /*Body text styles:*/
                '--body-text-color':' #0E1311',
                '--body-text-font-weight':'400',
                '--body-text-font-family':' inherit',
                '--body-text-line-height':' 1.4',
                '--body-text-letter-spacing':'0',
                '--body-text-transform':'none',
          
                /*Scroll button styles:*/
                '--scroll-button-icon-color':'#0E1311',
                '--scroll-button-icon-size':'24px',
                '--scroll-button-bg-color':'transparent',
          
                '--scroll-button-border-width':'0px',
                '--scroll-button-border-color':'rgba(0,0,0,0.1)',
          
                '--scroll-button-border-radius':'60px',
                '--scroll-button-shadow-size':'0px',
                '--scroll-button-shadow-color':'rgba(0,0,0,0.1)',
                '--scroll-button-horizontal-position':'0px',
                '--scroll-button-vertical-position':'0px',
          
                /*Badge styles:*/
                '--badge-icon-color':'#0E1311',
                '--badge-icon-font-size':'15px',
                '--badge-text-color':'#0E1311',
                '--badge-text-font-size':'inherit',
                '--badge-text-letter-spacing':'inherit',
                '--badge-text-transform':'inherit',
          
                /*Author styles:*/
                '--author-font-size':'inherit',
                '--author-font-weight':'inherit',
                '--author-text-transform':'inherit',
          
                /*Product photo or review photo styles:*/
                '--photo-video-thumbnail-size':'60px',
                '--photo-video-thumbnail-border-radius':'0px',
          
                /*Popup styles:*/
                '--popup-backdrop-color':'rgba(0,0,0,0.75)',
                '--popup-color':'#ffffff',
                '--popup-star-color':'inherit',
                '--popup-disabled-star-color':'inherit',
                '--popup-heading-text-color':'inherit',
                '--popup-body-text-color':'inherit',
                '--popup-badge-icon-color':'inherit',
                '--popup-badge-icon-font-size':'19px',
                '--popup-badge-text-color':'inherit',
                '--popup-badge-text-font-size':'14px',
                '--popup-border-width':'0px',
                '--popup-border-color':'rgba(0,0,0,0.1)',
                '--popup-border-radius':'0px',
                '--popup-shadow-size':'0px',
                '--popup-shadow-color':'rgba(0,0,0,0.1)',
                '--popup-icon-color':'#0E1311',
          
                /*Tooltip styles:*/
                '--tooltip-bg-color':'#0E1311',
                '--tooltip-text-color':'#ffffff',
              },
          });
          
        }
    },[])

    useEffect(() => {
    },[pdfData])

    localStorage.clear()
    const materials = [
        {
            id: 1,
            name: <p
                className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Revision <br
                className="hidden xl:block"/> Notes</p>,
            image: notes,
        },
        {
            id: 2,
            name: <p
                className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Model <br
                className="hidden xl:block"/> Essays</p>,
            image: Essays,
        },
        {
            id: 3,
            name: <p
                className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Tutorial <br
                className="hidden xl:block"/> Videos</p>,
            image: tutorials,
        },
        {
            id: 4,
            name: <p
                className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Quizzes &
                Flashcards</p>,
            image: quiz,
        },
        {
            id: 5,
            name: <p className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Case
                Summaries</p>,
            image: tips,
        },
        {
            id: 6,
            name: <p
                className="text-small-text mt-2.5 mb-12 text-center custom:text-body-large custom:font-bold">Interactive
                Learning Platform </p>,
            image: platform,
        },
    ];


    return (
        <>
            <div>
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>Sign Up to Maximise Your Chances to get a First Class Law Degree</title>
                    <meta name="description" content="Maximise your chances of Getting a First Class Law Degree! Save time and Study more efficiently. We have simple study resources created by top students and graduates"/>
                    <link rel="canonical" href="https://simplestudying.com"/>
                    <meta name="robots" content="index, follow"/>
                    <meta property="og:locale" content="en_US"/>
                    <meta property="og:locale:alternate" content="en_gb"/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:title" content="Sign Up to Maximise Your Chances to get a First Class Law Degree"/>
                    <meta property="og:description" content="Maximise your chances of Getting a First Class Law Degree! Save time and Study more efficiently. We have simple study resources created by top students and graduates"/>
                    <meta property="og:url" content="https://simplestudying.com"/>
                    <meta property="og:site_name" content="Simple Studying"/>
                </Helmet>
                <Container>
                    <div className="flex flex-col-reverse md:flex-row">
                        <div style={{zIndex: -1}} className="w-full h-full mt-8 md:w-2/3">
                            <p className="text-28 font-bold md:text-36 custom:text-h1 xl:leading-tight">Maximise
                                Your <span
                                    className="text-28 md:text-36 custom:text-h1 xl:leading-tight font-indieFlower font-normal relative">chances of <img
                                    src={Underline} alt="Underline"
                                    className="absolute right-0 top-8 md:top-10 custom:top-20 xl:top-20 xl:w-96"/></span>
                            </p>
                            <p className="text-28 font-bold md:text-36 custom:text-h1 xl:leading-tight">Getting a</p>
                            <p className="text-28 md:text-36 custom:text-h1 xl:leading-tight font-indieFlower font-normal relative">
                                First Class Law Degree <img src={FistClassUnderline} alt="Underline"
                                                            className="absolute left-0 top-8 w-60 md:top-10 md:w-80 custom:top-20 custom:w-auto xl:top-20 xl:w-auto"/>
                            </p>
                            <div className="w-full mt-30 xl:mt-7">
                                <p className="text-center text-13 font-bold md:text-left xl:text-h4">Save time and Study
                                    more efficiently</p>
                                <div className="mt-30 md:w-1/3 xl:mt-10 xl:h-16">
                                    {/*<Button filled onClick={() => history.push('/auth/signup')}><p onClick={() => history.push('/auth/signup')}*/}
                                    {/*    className="font-normal md:text-small-text xl:text-body-large">Sign up now <span*/}
                                    {/*    onClick={() => history.push('/auth/signup')} className="font-bold">FOR FREE</span></p></Button>*/}
                                </div>
                            </div>
                            <div className="hidden md:mt-30 xl:mt-24 md:flex pb-14 xl:pb-32 ">
                                <div className="md:h-12 md:w-12 md:mt-1">
                                    <LazyImage src={Trophy} alt="trophy"/>
                                </div>
                                <p className="md:ml-3 xl:ml-5 xl:text-body-large md:font-bold">— </p>
                                <p className="md:ml-1 md:text-16 xl:text-body-large md:font-bold">We have simple study
                                    resources <br className="hidden md:block"/>created by top students and graduates</p>
                            </div>
                        </div>
                        <div className="md:w-2/5 xl:w-2/4">
                            <div className="md:w-full flex justify-center">
                                <LazyImage src={'https://simplestudying.com/static/media/law-student%20(2).88b745a9.webp'}/>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="bg-white">
                <Container>
                    <p className='text-body-large font-bold text-center md:pt-24 lg:pt-32 md:text-h3 xl:text-36 xl:pt-32 w-62 m-auto'>
                        Join the movement of <span className="text-yellowish">20K savvy students</span> already empowered - and you're next
                    </p>

                    <p className="my-7 text-body-small  text-center  md:text-h5 xl:text-36"
                      >
                    Discover the secret to success as you become part of our growing community.
                        </p>
                        <div className='flex flex-col sm-md:flex-row justify-center  sm-md:justify-around'>
                            <div className=' text-center'>
                                <div className='w-150 h-150 m-auto '>
                                    <img src={img1}  className='m-auto mt-3 w-full h-full'/>
                                </div>
                                <p className='  fw-bold text-42 text-yellowish '>35+</p>
                                <p>Study Modules<br/>All main areas of law</p>
                            </div>
                            <div className=' text-center'>
                                <div className='w-150 h-150 m-auto'>
                                    <img src={img2}  className='m-auto mt-3 w-full h-full'/>
                                </div>
                                <p className='fw-bold text-42  text-yellowish'>500K+</p>
                                <p>Users<br/>Every Year</p>
                            </div>
                            <div className=' text-center'>
                                <div className='w-150 h-150 m-auto'>
                                    <img src={img3}  className='m-auto mt-3 w-full h-full'/>
                                </div>
                                <p className='fw-bold text-42  text-yellowish'>1430+</p>
                                <p>Hours Tutoring <br/>Delivered</p>
                            </div>
                        </div>
                </Container>
            </div>
            <div className="bg-white">
                <Container>
                    <p className="tutorialVidText text-body-large font-bold text-center md:pt-24 lg:pt-32 md:text-h3 xl:text-36 xl:pt-32 pt-12">
                        Get Wide Variety of Simple Study Materials
                    </p>
                    <p className="allSpecLText text-body-small text-center md:text-h5 xl:text-36 xl:pt-12 lg:pt-8 md:pt-4 "
                       >All
                        specifically designed for you to get a First Class in the simplest way possible</p>
                        <p className="allSpecLText text-body-small text-center md:text-h5 xl:text-36 xl:pt-12 lg:pt-8 md:pt-4 "
                       >Please click on the respective content types below for a preview</p>
                    <div
                        className="grid grid-cols-2 md:grid-cols-3 justify-items-center mt-14 lg:flex lg:justify-between">
                        {materials.map(elem => (
                            <div className="w-116 xl:w-44">
                                <StudyMaterial img={elem.image} alt={elem.name} data={pdfData.data}>
                                    {elem.name}
                                </StudyMaterial>
                            </div>

                        ))}
                    </div>
                </Container>
            </div>
            <div className="bg-offWhite md:bg-white bg-main-page-back2  bg-no-repeat md:bg-cover bg-contain">
                <Container>
                    <div className="pt-12 custom2:pt-10 xl:pt-28">
                        {badge ? 
                        <div>
                            {/* <p style={{maxWidth:'230px',position: 'fixed',top: '46%',right: '5px',zIndex: '999', fontWeight: 'bold', cursor: 'pointer'}} onClick={()=>{
                                setBadge(false)
                            }}>X</p> */}
                            <div id="badge-230" style={{maxWidth:'230px',position: 'fixed',top: '50%',right: '5px',zIndex: '999'}}></div>
                        </div> : null}

                        <p className="text-center text-body-large md:text-h3 xl:text-36 font-bold">We Make Studying
                            Law <span className="relative">Simple <img src={SimpleUnderline} alt="Underline"
                                                                       className="absolute right-0"/></span></p>
                    </div>
                    <div
                        className="flex flex-col items-center mt-11 custom2:pt-12 xl:pt-28 w-full md:grid md:grid-cols-2 custom:items-center lg:gap-x-7 lg:px-24 xl:px-40 relative md:justify-items-center mac:px-72"
                        style={{zIndex: 1}}>
                        <div
                            className="mac:left-40 hidden lg:block absolute lg:left-5 custom2:left-2 custom2:top-20 xl:left-5 xl:top-20 bg-back-screws bg-no-repeat bg-contain">
                            <div className="lg:w-3/5 xl:w-full">
                                <LazyImage src={'https://simplestudying.com/static/media/screw.c125716f.webp'}/>
                            </div>
                        </div>
                        <div className="w-72 sm:w-340 lg-:w:72 lg:mr-20 custom:w-96 custom:mr-20 xl:w-full h-full mb-6"
                             style={{zIndex: 2}}>
                            <StudyMaterialCard
                                img={'https://simplestudying.com/static/media/purple-card-image.733abc6c.webp'}
                                alt="Purple card image"
                                color="bg-purple"
                                text={<p className="text-center text-17 xl:text-h3 xl:text-bold text-white">Sell your
                                    Study Materials <br/>to Generate Value from your <br
                                        className="lg:hidden"/> Knowledge</p>}
                                description={<p className="text-13 xl:text-body-large text-white text-center">Publish
                                    your notes and essays and <br className="xl:hidden"/> get <br
                                        className="hidden xl:block"/> recurring monthly revenues!</p>}/>

                        </div>
                        <div className="w-72 sm:w-340 lg:w:72 custom:w-96 xl:w-full h-full mb-6" style={{zIndex: 2}}>
                            <StudyMaterialCard
                                img={'https://simplestudying.com/static/media/green-card-image.5f3b47f3.webp'}
                                alt="Green card image"
                                color="bg-offGreen"
                                text={<p className="text-center text-17 xl:text-h3 xl:text-bold text-white">Get Study
                                    Materials and Tutoring<br/> to Improve your<br
                                        className="hidden md:block custom:hidden"/> Grades
                                </p>}
                                description={<p className="text-13 xl:text-body-large text-white text-center">Simple
                                    study materials and pre-tested <br/> tools helping you to get high grades!</p>}/>
                        </div>
                        <div
                            className="mac:right-20 hidden lg:block absolute lg:-right-24 lg:bottom-8 custom2:-right-20 custom:-right-30 xl:right-1 xl:bottom-8 bg-back-screws bg-no-repeat bg-contain"
                            style={{zIndex: 1}}>
                            <div className="lg:w-3/5 xl:w-full">
                                <LazyImage src={'https://simplestudying.com/static/media/leaf.cbe8fcd9.webp'}/>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div
                className="bg-white bg-video-back bg-no-repeat lg:bg-contain custom2:bottom-subscription custom2:mt-36 ">
                <Container>
                    <div
                        className="flex-col md:flex-row flex justify-around pb-12 custom2:pb-56 mt-14 custom2:flex xl:justify-start custom2:px-28 lg:px-28 xl:px-40 relative">
                        <div
                            className="hidden custom2:block absolute custom2:-left-2 custom2:top-10 custom2:w-40 xl:left-1 xl:w-full  z-10"
                            style={{zIndex: 1}}>
                            <LazyImage src={'https://simplestudying.com/static/media/leaf.cbe8fcd9.webp'} alt="Leafs"/>
                        </div>
                        <div
                            className="w-full custom2:w-3/5 xl:w-3/5 h-40 custom2:h-80 md:h-96 xl:h-96 bg-offGreen relative z-20 xl:mt-1"
                            style={{borderRadius: '25px', zIndex: 3}}>
                            <div
                                className="w-full h-40 md:h-96 xl:h-96  custom2:h-80 absolute left-2 top-2 md:left-5 md:top-5 overflow-hidden"
                                style={{borderRadius: '25px', zIndex: 1}}>
                                <VideoPlayer url={'https://www.youtube.com/watch?v=sIy7mQHG2Zs&feature=youtu.be'}/>
                            </div>
                        </div>
                        <div className="mt-12 custom2:w-2/5 custom2:h-full">
                            <div className="flex flex-col items-center custom2:ml-40">
                                <p className="for72Font text-72 text-primary font-bold custom2:text-72 text-center mac:text-80 xl:text-170"
                                   style={{margin: 0, padding: 0}}>90%</p>
                                <p className="text-body-large font-bold text-center  xl:text-h4 xl:-mt-10 ">of Our
                                    Regular Users <br className="hidden xl:block"/> Achieve a First Class</p>
                                <div
                                    className="w-full mt-6 md:w-1/2 custom2:w-full custom2:mt-8 md:mt-5 xl:mt-8 xl:w-10/12">
                                    <Button filled onClick={() => history.push('/signup')}><p
                                        className="font-normal md:text-small-text custom:text-body-small mac:text-18 xl:text-body-large text-center">Sign
                                        up now <span className="font-bold">FOR FREE</span></p></Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
            <div className="bg-white" id='see-why-div'>
                <Container>
                    <div>
                        <p className="text-body-large font-bold text-center md:text-h3 xl:text-36">See why our users<br
                            className="md:hidden"/> <span className="relative">love SimpleStudying..<img
                            className="absolute left-1 w-full" src={SpUnderline} alt="Underline"/></span></p>
                    </div>
                </Container>
                <div className='mt-20' id="reviewsio-carousel-widget"></div>
            </div>

            {/* <div className="bg-white pt-8 md:pt-10 lg:pt-12 custom2:pt-24">
                <div className="">
        
                     <Slider>
                        <SliderCard
                            name="Stacy Anderson"
                            message="Amazing service. I really struggling to understand banking law but the team have arranged such helpful notes and offer tutoring to explain the content. I achieved a solid 2:1 in banking law!"
                            timePosted="posted 3 months ago"
                            img={HandImage9}/>
                        <SliderCard
                            name="Kannya Glow"
                            message="SimpleStudying has summarized all the facts I need to fully grasp the subjects I’m currently studying. Thank you!"
                            timePosted="posted 3 months ago"
                            img={HandImage8}/>
                        <SliderCard
                            name="Kannya Glow"
                            message="SimpleStudying has summarized all the facts I need to fully grasp the subjects I’m currently studying. Thank you!"
                            timePosted="posted 3 months ago"
                            img={HandImage8}/>
                    </Slider>
                </div>

            </div> */}
            <div className="bg-white bg-head-back pb-6 md:pb-32 lg:pb-36 bg-no-repeat bg-bottom ">
                <Container>
                    <div
                        className="flex flex-col-reverse items-center lg:flex-row lg:justify-between lg:px-36 md:pt-36">
                        <div className="text-center flex flex-col items-center lg:items-start ">
                            <p className="text-body-large lg:text-h4 font-bold mt-4 md:mt-8 lg:mt-12 xl:text-36 ">Our story</p>
                            <p className="mt-2 md:mt-5 text-14 w-64 lg:text-16 xl:text-body-large xl:w-96 lg:text-left">We
                                created SimpleStudying because we noticed a lot of students struggling to achieve high
                                grades, spending a lot of money on tuition fees and dropping out of their courses. We
                                were determined to fix this!</p>
                            <p className="text-body-large  mt-4 underline text-primary cursor-pointer xl:mt-7"
                               onClick={() => {
                                   history.push('/about-us')
                               }}>Read our
                                story</p>
                        </div>
                        <div className="w-80 xl:w-550 margin-story">
                            <LazyImage src={'https://simplestudying.com/static/media/head-brain%20(1).bee044d2.webp'} alt="Head"/>
                        </div>
                    </div>
                </Container>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    pdfData: mainPdfData(state),
  });
  
  const mapDispatchToProps = (dispatch) => ({
    getPdfs: () => dispatch(getMainPdf()),
  });
  
  export default connect(mapStateToProps, mapDispatchToProps)(MainPage);
