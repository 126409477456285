import * as t from "../types";
import {GetTutorGroupSessions, TutorSessionPost} from "../../services";
import {
    GET_GROUP_SESSION_REQUEST,
    POST_TUTOR_SESSION_ONE_FAILED,
    POST_TUTOR_SESSION_ONE_REQUEST,
    POST_TUTOR_SESSION_ONE_SUCCESS,
} from "../types";
import {createErrorAlert} from "./errorAlertAction";

export const tutorSessionPostOne =
    (username, teacher, title, dates, packages, package_price) =>
        async (dispatch) => {
            dispatch({
                type: t.POST_TUTOR_SESSION_ONE_REQUEST,
            });

            return await TutorSessionPost(
                username,
                teacher,
                title,
                dates,
                packages,
                package_price
            )
                .then((data) => {
                    dispatch({
                        type: t.POST_TUTOR_SESSION_ONE_SUCCESS,
                        payload: data,
                    });
                    return data
                })
                .catch((err) => {
                    dispatch({
                        type: t.POST_TUTOR_SESSION_ONE_FAILED,
                        payload: err.response,
                    });
                    if(err.response.status === 409){
                        dispatch(
                            createErrorAlert(
                                400,
                                "Error",
                                `${err.response.data.message}`,
                                400
                            )
                        );
                    }
                    return err.response.status
                });
        };
