import React, {useContext, useEffect, useState} from "react";
import Container from "../../components/container";
import CalendarCard from "../../components/Calendar/Calendar";
import CustomModal from "../../components/Modal";
import {useHistory} from "react-router-dom";
import Stepper from "../../components/SignUpStepper"
import {tutorAvailabilityData, tutorAvailabilityError, tutorAvailabilityLoading,} from "../../redux/selectors";
import {createErrorAlert, getTutorAvailability} from "../../redux/actions";
import {connect, useDispatch} from "react-redux";
import Button from '../../components/EmptyButton';
import {Helmet} from "react-helmet";
import Context from "../../services/Context";

const OneToOne = ({loading, data, getTutorAvailability}) => {

    const history = useHistory();
    const dispatch = useDispatch();

    const {value, updateValue} = useContext(Context)

    const {selectedPackages, selectedTutor} = value

    let availablity_id;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dates, setDates] = useState(value.dates ? value.dates : [])
    const [calendarDate, setcalendarDate] = useState("");
    const [freeAppointments, setFreeAppointments] = useState([
        {
            startTime: "1:00PM",
            endTime: "2:00PM",
        },
        {startTime: "2:00PM", endTime: "3:00PM"},
        {startTime: "5:00PM", endTime: "6:00PM"},
        {
            startTime: "7:00PM",
            endTime: "8:00PM",
        },
    ]);

    useEffect(() => {
        const handleBeforeUnload = (e) => {
          e.preventDefault();
          e.returnValue = '';
    
          const confirmationMessage = 'Are you sure you want to leave this page? Your unsaved changes may be lost.';
          (e || window.event).returnValue = confirmationMessage;
    
          return confirmationMessage;
        };
    
        window.addEventListener('beforeunload', handleBeforeUnload);
    
        return () => {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
      }, []);

    useEffect(() => {
        let stripeRedirect = sessionStorage.getItem('stripRedirect')
        if(stripeRedirect === "true"){
            sessionStorage.removeItem('stripRedirect')
            history.push('/')
          }
        if (isModalOpen) {
            document.body.style.overflowY = "hidden";
            document.body.style.position = "fixed";
        } else {
            document.body.style.overflowY = "unset";
            document.body.style.position = "unset";
        }
    }, [isModalOpen]);

    function closeModal() {
        setIsModalOpen(false);
    }

    function okClick(start) {
        if(start.start === 0){
            dispatch(
                createErrorAlert(400, "", "Please Select any Time Slot to continue", 400)
            );
            return
        }
        if (data[0]?.availability_id) {
            availablity_id = data[0]?.availability_id
        }
        if (availablity_id !== undefined) {
            dates.push({date: calendarDate, availablity_id: availablity_id, startTime: start.start})
            localStorage.setItem('dates', JSON.stringify(dates))
            start.start = 0
        }
        setIsModalOpen(false);
    }

    const selectedDate = (date) => {
        let currentDate = new Date();
        // if(dates.length > 0){
        //         for(let i=0; i<dates.length; i++){
        //             let dateObj = new Date(dates[i].date)
        //             if(date.getDate() === dateObj.getDate() && date.getMonth() === dateObj.getMonth()){
        //                 setIsModalOpen(false)
        //                 dispatch(
        //                     createErrorAlert(400, "Error", "You cannot select same time slot for a single day", 400)
        //                 );
        //                 return
        //             }
        //         }
        //     }


        // if (date < currentDate - 1 && date.getDate() !== currentDate.getDate()) {
        //     setIsModalOpen(false);
        //     dispatch(
        //         createErrorAlert(400, "Error", "You cannot Select Previous Date", 400)
        //     );
        //     return
        // } 
        // else 
        if (dates.length >= selectedPackages?.package_recurence) {
            setIsModalOpen(false)
            dispatch(
                createErrorAlert(400, "Error", "Your Package doesn't allow to make further Appointments", 400)
            );
            return;
        } else {
            let month = parseInt(date.getMonth() + 1);
            let selectedDate;
            if (date.getMonth() + 1 < 10) {
                selectedDate =
                    date.getFullYear() + "-" + 0 + month + "-" + date.getDate();
            }
            if (date.getDate() < 10) {
                selectedDate =
                    date.getFullYear() + "-" + month + "-" + 0 + date.getDate();
            }
            if ((date.getDate() < 10) && ((date.getMonth() + 1) < 10)) {
                selectedDate =
                    date.getFullYear() + "-" + 0 + month + "-" + 0 + date.getDate();
            }
            if(date.getMonth() + 1 >= 10){
                selectedDate =
                    date.getFullYear() + "-" + month + "-" + date.getDate();
            }
            setcalendarDate(selectedDate);
            getTutorAvailability(selectedTutor.name, selectedDate).then(res => {
                if (res[0] === `Tutor has marked Holiday for this date: ${selectedDate}`) {
                    setIsModalOpen(false)
                    dispatch(
                        createErrorAlert(400, "Error", 'Tutor is not available on your selected date', 400)
                    );
                } else if(res.data[0].available_slot.length === 0){
                    setIsModalOpen(false)
                    dispatch(
                        createErrorAlert(400, "Error", 'No Time Slot Available', 400)
                    );
                }
            });
        }
    };

    const confirmClick = () => {
        if (dates.length < selectedPackages?.package_recurence) {
            if (selectedPackages?.package_recurence === "3") {
                dispatch(createErrorAlert(200, "Error", `Please select date and time for all the three hours of tutoring session. Remaining ${parseInt(selectedPackages?.package_recurence) - parseInt(dates.length)}`, 400))
                return;
            } else if (selectedPackages?.package_recurence === "6") {
                dispatch(createErrorAlert(200, "Error",`Please select date and time for all the six hours of tutoring session. Remaining ${parseInt(selectedPackages?.package_recurence) - parseInt(dates.length)}`, 400))
                return;
            } else if (selectedPackages?.package_recurence === "1") {
                dispatch(createErrorAlert(200, "Error", "Please select date and time for one hour of tutoring session", 400))
                return;
            } else if (dates.length === 0){
                dispatch(createErrorAlert(200, "Error", "Please Select any Date and Time", 400))
                return;
            }
        } else {
            updateValue({...value, dates: dates})
            history.push({
                pathname: `/book-tutorials/one-to-one-payment`,
            });
        }
    }

    const timeCheck = (ab, time) => {
        console.log('time')
    }



    return (
        <div className="bg-white bg-subscription-bottom bg-bottom bg-no-repeat">
            <Stepper step={2} exit={() => history.push('/profile')}/>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Simple Studying | Appointment Date</title>
                <meta name="description" content="One To One Description"/>
                <link rel="canonical" href="https://simplestudying.com/book-tutorials/appointment/one-to-one"/>
                <meta name="robots" content="index, follow"/>
                <meta property="og:locale" content="en_US"/>
                <meta property="og:locale:alternate" content="en_gb"/>
                <meta property="og:type" content="website"/>
                <meta property="og:title" content="Simple Studying | One To One"/>
                <meta property="og:description" content="One To One Description"/>
                <meta property="og:url" content="https://simplestudying.com/book-tutorials/appointment/one-to-one"/>
                <meta property="og:site_name" content="Simple Studying"/>
            </Helmet>
            <div className="xl:px-56">
                <CustomModal
                    content={freeAppointments}
                    isOpen={isModalOpen}
                    closeModal={closeModal}
                    onClickFirstButton={closeModal}
                    onClickSecondButton={okClick}
                    slug={selectedTutor.name}
                    data={data}
                    dates={timeCheck}
                    loading={loading}
                    datesArray={dates}
                    buttonOneText="Back to calendar"
                    buttonTwoText="OK"
                    packages={selectedPackages}
                    title="Please choose a suitable time for you:"
                />
                <Container>
                    <div className="pt-4 md:pt-10 lg:pt-14 flex">
                        <div className="text-darkest schduelSec">
                            <p className="text-36 font-bold mb-12 ">
                                Schedule a Session
                            </p>
                            {/* <p className="mb-4 text-body-large font-normal">
                                We are experiencing extremely high number of one-to-one tutorial
                                bookings. You should book a tutoring session as soon as possible
                                because we are expecting August available tutoring hours to be
                                sold out very soon. If you cannot find a time that suits you,
                                please contact us.{" "}
                            </p>
                            <p className="mb-4 text-body-large font-normal">
                                When you book a tutorial, please tell us how would you like us
                                to help you and send all the relevant materials by email at
                                info@simplestudying.com{" "}
                            </p>
                            <p className="mb-4 text-body-large font-normal">
                                The calendar below is for booking one-to-one tutoring sessions
                                only.
                            </p>
                            <p className="mb-4 text-body-large font-normal">
                                Any questions? Please{" "}
                                <p className="text-primary underline" onClick={() => {
                                    history.push('/contact-us')
                                }}>
                                    contact us
                                </p>
                                .
                            </p> */}
                        </div>
                    </div>
                    <div className={dates?.length > 0 ? 'selectBokMain flex w-full justify-between' : ''}>
                        {dates?.length > 0 ?
                            <div className={'justify-between'}>
                                <p className="selectSessText">Selected Session</p>
                                <div className={'selected-slots border-2 border-ligthBorderGrey w-full p-2 md:p-4 lg:p-8 mt-4'}>
                                    {dates?.length > 0 ? dates?.map((item) => {
                                        return (
                                            <p>{item?.date} - {item?.startTime}</p>
                                        )
                                    }) : null}
                                </div>
                            </div> : null}

                        <div className={dates?.length > 0 ? 'date-time' : ''}>
                            <div className="bulletSection">
                                <h5 className="holdBullet">Unavailable</h5>
                                <h5 className="bookBullet">Booked</h5>

                            </div>
                            <div className="mt-6">
                                <p className="text-center text-h5 font-bold">Please select any of the available dates on the calendar below and choose the time slot:</p>
                            </div>

                            <div className="bg-white h-565 mt-2 md:mt-6 lg:mt-9" style={{borderRadius: "14px"}}>
                                <CalendarCard
                                    setIsModalOpen={setIsModalOpen}
                                    calendarDate={calendarDate}
                                    onNavigate={selectedDate}
                                    setcalendarDate={setcalendarDate}
                                    tutor={selectedTutor.name}
                                />
                                
                            </div>
                        </div>
                    </div>
                    <div className={'buttonCnfrm'}>
                        <Button className='confrmBtn' filled onClick={confirmClick}>Confirm</Button>
                    </div>

                </Container>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    loading: tutorAvailabilityLoading(state),
    error: tutorAvailabilityError(state),
    data: tutorAvailabilityData(state),
});
const mapDispatchToProps = (dispatch) => ({
    getTutorAvailability: (slugs, date) =>
        dispatch(getTutorAvailability(slugs, date)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OneToOne);
